import {Component, computed, Input, OnInit, signal, Signal, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {IonSelect} from "@ionic/angular";
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {Labor} from "@modules/labor/Domain/labor";
import {getCrewsFromGmInputs} from "@modules/labor/Presentation/Service/crewNames";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-crew-selector',
  templateUrl: './crew-selector.component.html',
  styleUrls: ['./crew-selector.component.scss'],
})
export class CrewSelectorComponent implements OnInit {
  @ViewChild('select1') select1?: IonSelect;

  @Input({required: true}) public form?: FormGroup;

  @Input({required: true}) labor?: Labor;
  @Input({required: true}) gmInputs?: GmInputs;

  protected selectedCrew = signal<Crew[]>([]);
  protected inactiveCrews: Signal<Crew[]> = computed(() => {
    if (!this.gmInputs) {
      return [];
    }
    const selectedCrewIDs = this.selectedCrew().map(crew => crew.id);
    return this.gmInputs.getCrews().getInactiveCrewsByIDs(selectedCrewIDs);
  });
  
  protected activeAndInactiveCrews: Signal<Crew[]> = computed(() => {
    if (!this.gmInputs || !this.inactiveCrews()) {
      return [];
    }
    return [
      ...this.gmInputs.getCrews().getActiveCrews(),
      ...this.inactiveCrews()
    ]
  });

  ngOnInit(): void {
    if (!this.form) return;

    this.updateSelectedCrew();
    this.form.get('laborCrew')?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateSelectedCrew());
  }

  private updateSelectedCrew() {
    const crewInForm = this.form?.get('laborCrew')?.value as number[] || [];
    const crewIDs = crewInForm.map(id => new CrewID(id));

    this.selectedCrew.set(getCrewsFromGmInputs(this.gmInputs, crewIDs));
  }
}
