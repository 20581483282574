import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";

export abstract class CalculationAreaFactory<
  TArea extends CalculationArea = CalculationArea,
  TOptions extends CalculationAreaOptions = CalculationAreaOptions
> {
  abstract execute(data: Partial<TOptions>): TArea | Promise<TArea>;

  protected removeInvalidFields(data: Partial<TOptions>): Partial<TOptions> {
    const validEntries = Object.entries(data).filter(([, value]) => {
      return value !== undefined && (typeof value !== 'number' || !isNaN(value));
    });
    return Object.fromEntries(validEntries) as Partial<TOptions>;
  }
}
