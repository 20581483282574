import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {
  BattingCalculationStrategy
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/Calculation/batting-calculation-strategy";
import {
  BattingArea
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area";
import {
  BattingAreaFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-factory";
import {
  BattingAreaTotal
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total";
import {GetCalculationUseCase} from "@modules/calculation/Application/UseCase/Query/get-calculation-use-case.service";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {
  GetGmInputsByBusinessUnitIDUseCase
} from "@modules/gm-inputs/Application/UseCase/Query/get-gm-inputs-by-buid-use-case";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {Labor} from "@modules/labor/Domain/labor";
import {getLaborFormFields} from "@modules/labor/Presentation/FormFieldProvider/getLaborFormFields";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

export const FILTER_R_VALUE = 'R-Value';
export const FILTER_DEPTH = 'Depth';

@Component({
  selector: 'app-batting-calculator',
  templateUrl: './batting-area.component.html',
  styleUrls: ['./batting-area.component.scss'],
  standalone: false
})
export class BattingAreaComponent implements CalculatorAreaComponent<BattingArea>, OnInit, OnDestroy {
  @Input() inputModel: BattingArea | undefined;
  @Input() calculationID: string | undefined = '';
  @Output() inputModelChange: EventEmitter<BattingArea> = new EventEmitter<BattingArea>();
  protected form: FormGroup;
  protected calculationResult: BattingAreaTotal | undefined;
  private readonly destroy$ = new Subject<void>();
  private calculation: BattingCalculationStrategy | undefined;
  private gmInputs: GmInputs | undefined;
  private readonly requiredFieldLabels: { [key: string]: string } = {
    sqft: 'Area Square Footage',
    productID: 'Product',
    laborCrew: 'Labor Crew',
    projHours: 'Project Job Hours',
    battingServiceName: 'Batting Service',
  };

  constructor(
    private readonly inputModelFactory: BattingAreaFactory,
    private readonly getGmInputsByBusinessUnitID: GetGmInputsByBusinessUnitIDUseCase,
    private readonly getCalculationUseCase: GetCalculationUseCase
  ) {
    this.form = new FormGroup({
      sqft: new FormControl(null, [Validators.required, Validators.min(1)]),
      constructionName: new FormControl(),
      constructionType: new FormControl(),
      onCenter: new FormControl(),
      facingName: new FormControl(),
      filterType: new FormControl(FILTER_R_VALUE),
      rValue: new FormControl(),
      depth: new FormControl(),
      productID: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      miscellaneous: new FormControl(),
      battingServiceName: new FormControl(null, [Validators.required]),
      materialSource: new FormControl(),
      ...getLaborFormFields()
    });
  }

  async ngOnInit(): Promise<void> {
    this.initForm();

    if (!this.calculationID) return;
    const calculationID = new CalculationID(this.calculationID);
    const calculation = await this.getCalculationUseCase.execute<BattingCalculationStrategy>(calculationID);
    const gmInputs = await this.getGmInputsByBusinessUnitID.execute(calculation.businessUnitID);

    this.calculation = calculation.calculationStrategy
    this.gmInputs = gmInputs;

    if (!this.inputModel) return;
    await this.createCalculationResult(this.inputModel);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async processChanges(values: any): Promise<void> {
    if (!values) return;

    const inputModel = this.createInputModel(values);
    this.inputModelChange.emit(inputModel);

    await this.createCalculationResult(inputModel);
  }

  protected getFormErrors() {
    const errors: string[] = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors | null | undefined = this.form.get(key)?.errors;
      if (!controlErrors) return;
      const fieldLabel = this.requiredFieldLabels[key] || key;
      errors.push(fieldLabel);
    });
    return errors;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createInputModel(values: any): BattingArea {
    return this.inputModelFactory.execute({
      sqft: values.sqft,
      constructionName: values.constructionName,
      constructionType: values.constructionType,
      onCenter: values.onCenter,
      facingName: values.facingName,
      rValue: values.rValue,
      productID: values.productID,
      miscellaneous: values.miscellaneous,
      battingServiceName: values.battingServiceName,
      filterType: values.filterType,
      depth: values.depth,
      labor: Labor.fromFields(values),
    });
  }

  private initForm() {
    if (!this.inputModel) throw new Error('inputModel is required');

    const values = {
      sqft: this.inputModel.sqft,
      constructionName: this.inputModel.constructionName,
      constructionType: this.inputModel.constructionType,
      onCenter: this.inputModel.onCenter,
      facingName: this.inputModel.facingName,
      rValue: this.inputModel.rValue,
      productID: this.inputModel.productID,
      miscellaneous: this.inputModel.miscellaneous,
      battingServiceName: this.inputModel.battingServiceName,
      filterType: this.inputModel.filterType === FILTER_DEPTH ? FILTER_DEPTH : FILTER_R_VALUE,
      depth: this.inputModel.depth,
      ...this.inputModel.labor.toFields()
    };

    this.form.patchValue(values);
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => this.processChanges(values));
  }

  private async createCalculationResult(inputModel: BattingArea): Promise<void> {
    if (!this.form.valid || !this.calculation || !this.gmInputs) return;

    this.calculationResult = await this.calculation.calculateArea(inputModel, this.gmInputs);
  }
}
