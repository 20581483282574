import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaItem,
  ProductMiscAreaItem
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {
  MiscAreaItemOptions,
  MiscAreaOptions
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-options";
import {
  GetProductsQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-query.service";
import {
  GetProductsRequest
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-request";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";
import {MiscProducts} from "@modules/product/products/misc-product/Domain/Product/misc-products";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaFactory extends CalculationAreaFactory<MiscArea, MiscAreaOptions> {

  private readonly defaults: MiscAreaOptions = {
    id: '',
    name: 'Miscellaneous',
    items: [],
    businessUnitID: new BusinessUnitID('')
  }

  constructor(
    private readonly getProductsByCategoryNameQuery: GetProductsQuery
  ) {
    super();
  }

  async execute(data: MiscAreaOptions): Promise<MiscArea> {
    const dataItems = await this.createItems(data.items ?? []);
    return new MiscArea(
      data.id || this.defaults.id,
      data.name || this.defaults.name,
      dataItems,
      data.businessUnitID
    );
  }

  public async createItems(items: MiscAreaItemOptions[]): Promise<MiscAreaItem[]> {
    return await Promise.all(items.map(item => this.createItem(item)));
  }

  private createItem(item: MiscAreaItemOptions): Promise<MiscAreaItem> {
    if (item.productID && item.count) {
      return this.createProductItem(item.productID, item.count);
    }

    if (item.name && item.value !== undefined) {
      return Promise.resolve(new MiscAreaItem(item.name, item.value, item.count ?? 1));
    }

    throw new Error(`Invalid item: ${JSON.stringify(item)}`);
  }


  private async createProductItem(productID: string, count: number): Promise<ProductMiscAreaItem> {
    const request: GetProductsRequest = {categoryName: new MiscCategoryName()};
    const products = await this.getProductsByCategoryNameQuery.execute<MiscProducts>(request);
    const product = products.getByID(new ProductID(productID));
    if (!product) {
      throw new Error(`Product with ID ${productID} not found`);
    }
    return new ProductMiscAreaItem(product, count);
  }
}
