import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UserConfigProvider} from "@modules/_shared/Service/UserConfig/user-config-provider.service";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";
import {BlowInBlowProduct} from "@modules/product/products/blow-in-blow-product/Domain/Product/blow-in-blow-product";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";

@Component({
  selector: 'app-blow-in-universal-product-selector',
  templateUrl: './blow-in-universal-product-selector.component.html',
  styleUrls: ['./blow-in-universal-product-selector.component.scss'],
})
export class BlowInUniversalProductSelectorComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  public isTrainingTemplateMode?: boolean;
  public netEnabled = true;
  protected readonly BlowInAreaTypeEnum = BlowInAreaTypeEnum;
  protected areaType: BlowInAreaTypeEnum[] = [
    BlowInAreaTypeEnum.Attic,
    BlowInAreaTypeEnum.SubFloors,
    BlowInAreaTypeEnum.Walls
  ];
  private readonly blowInInsulationCategoryName = new BlowInInsulationCategoryName();
  public readonly blowInInsulationCategoryNames: CategoryName[] = [this.blowInInsulationCategoryName]
  private readonly blowInNetCategoryName = new BlowInNetCategoryName();
  public readonly netCategoryNames: CategoryName[] = [this.blowInNetCategoryName]

  constructor(
    private readonly userConfigProvider: UserConfigProvider
  ) {
  }

  ngOnInit() {
    this.userConfigProvider.userConfig$.subscribe(userConfig => {
      if (!userConfig) return;
      this.isTrainingTemplateMode = userConfig.isTrainingTemplateMode();
    })

    this.areTypeChanged(this.form.get('areaType')?.value);

    this.form.valueChanges.subscribe(form => {
      this.areTypeChanged(form.areaType);
    });
  }

  blowInProductSelected(product?: Product) {
    if (!product) return;

    const blowInProduct = product as BlowInBlowProduct;
    this.form.get('construction')?.setValue('N/A', {emitEvent: false});
    this.form.get('rValue')?.setValue(blowInProduct.rValue, {emitEvent: false});
    this.form.get('areaType')?.setValue(blowInProduct.areaType, {emitEvent: false});
    this.form.get('blowInType')?.setValue(blowInProduct.blowInType, {emitEvent: false});
  }

  netProductSelected(product?: Product) {
    if (!product) return;

    this.form.get('toggleNetService')?.setValue(true, {emitEvent: false});
  }

  private areTypeChanged(areaType: BlowInAreaTypeEnum) {
    this.netEnabled = areaType !== BlowInAreaTypeEnum.Attic;
  }
}
