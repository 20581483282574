import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalController} from "@ionic/angular";
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {
  FoamAreaFactory
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area-factory.service";
import {
  RoofPitchModalComponent
} from "@modules/calculation-impl/foam/roofPitch/Presentation/roof-pitch-modal/roof-pitch-modal.component";
import {ShowTabNameService} from "@modules/calculation-tabs/Presentation/Service/ShowTabName/show-tab-name.service";
import {Labor} from "@modules/labor/Domain/labor";
import {getLaborFormFields} from "@modules/labor/Presentation/FormFieldProvider/getLaborFormFields";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

import {FoamArea, FoamAreaOptions} from "../../Domain/Calculator/CalculationArea/foam-area";

@UntilDestroy()
@Component({
  selector: 'app-foam-area',
  templateUrl: './foam-area.component.html',
  styleUrls: ['./foam-area.component.scss'],
  standalone: false
})
export class FoamAreaComponent implements CalculatorAreaComponent<FoamArea>, OnInit {
  @Input() inputModel: FoamArea | undefined;
  @Input() calculatorID: string = '';
  @Output() inputModelChange: EventEmitter<FoamArea> = new EventEmitter<FoamArea>();

  protected form: FormGroup;
  protected displayPitchSelector: boolean = false;
  protected isPitchFactorEnabled: boolean = false;


  constructor(
    private readonly foamAreaFactory: FoamAreaFactory,
    private readonly showTabNameService: ShowTabNameService,
    private readonly modalCtrl: ModalController
  ) {
    this.form = new FormGroup({
      service: new FormControl(null, [Validators.required]),
      sqft: new FormControl(0, [Validators.required]),
      depth: new FormControl(1, [Validators.required]),
      miscellaneous: new FormControl(0, [Validators.required]),
      product: new FormControl(null, [Validators.required]),
      roofPitchName: new FormControl(null),
      roofPitchMethodName: new FormControl(null),
      ...getLaborFormFields()
    });
  }


  async ngOnInit(): Promise<void> {
    this.initForm();

    const roofRegex = /roofline/i;
    this.showTabNameService.tabName$
      .pipe(untilDestroyed(this))
      .subscribe((tabName) => {
        this.displayPitchSelector = roofRegex.test(tabName);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processChanges(values: any) {
    if (!values || !this.inputModel) return;

    const params: FoamAreaOptions = {
      id: this.inputModel.id,
      name: this.inputModel.name,
      service: values.service,
      sqft: this.inputModel.getRoofPitch() ? this.inputModel.getRawSqft() : values.sqft,
      depth: values.depth,
      miscellaneous: values.miscellaneous,
      product: values.product,
      roofPitchName: values.roofPitchName,
      roofPitchMethodName: values.roofPitchMethodName,
      businessUnitID: this.inputModel.businessUnitID,
      labor: Labor.fromFields(values)
    }

    const inputModel = this.foamAreaFactory.execute(params);
    this.inputModel = inputModel;

    this.inputModelChange.emit(inputModel);
  }


  async roofPitchChanged(event: CustomEvent) {
    const value = event.detail.checked;
    if (!value) {
      this.disablePitchFactor();
      return;
    }

    const modal = await this.modalCtrl.create({
      component: RoofPitchModalComponent,
      componentProps: {
        inputModel: this.inputModel
      }
    });

    await modal.present();

    const modalResult = await modal.onWillDismiss<FoamArea>();

    if (modalResult?.role !== 'confirm' || !modalResult.data) {
      this.disablePitchFactor();
      return;
    }

    this.enablePitchFactor(modalResult.data);
  }

  private initForm() {
    if (!this.inputModel) {
      throw new Error('inputModel is required');
    }
    this.form.patchValue({
      service: this.inputModel.service,
      sqft: this.inputModel.getSqft(),
      depth: this.inputModel.depth,
      miscellaneous: this.inputModel.miscellaneous,
      product: this.inputModel.product,
      roofPitchName: this.inputModel.roofPitchName,
      roofPitchMethodName: this.inputModel.roofPitchMethodName,
      ...this.inputModel.labor.toFields()
    });

    this.isPitchFactorEnabled = !!this.inputModel.getRoofPitch();

    if (this.inputModel.getRoofPitch()) {
      this.form.get('sqft')?.setValue(this.inputModel.getSqft(), {emitEvent: false});
      this.form.get('sqft')?.disable({emitEvent: false});
    } else {
      this.form.get('sqft')?.setValue(this.inputModel.getRawSqft(), {emitEvent: false});
      this.form.get('sqft')?.enable({emitEvent: false});
    }

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((values) => this.processChanges(values));
  }


  private disablePitchFactor() {
    this.form.patchValue({
      roofPitchName: null,
      roofPitchMethodName: null,
      sqft: this.inputModel?.getRawSqft(),
    }, {emitEvent: false});

    this.inputModel?.disassociateRoofPitch();
    this.isPitchFactorEnabled = false;
    this.form.get('sqft')?.enable({emitEvent: true});
    console.log(this.form.value);
  }

  private enablePitchFactor(model: FoamArea) {
    this.inputModel = model;

    this.form.patchValue({
      roofPitchName: model.getRoofPitch()?.pitch.name.getValue(),
      roofPitchMethodName: model.getRoofPitch()?.method.name.getValue(),
      sqft: this.inputModel?.getSqft(),
    }, {emitEvent: false});

    this.isPitchFactorEnabled = true;
    this.form.get('sqft')?.disable({emitEvent: true});
  }
}
