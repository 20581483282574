@if (isLoading()) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}

@if (displayCrews()) {
  <app-labor-crew-display
    (click)="openLaborModal($event)"
    [crews]="this.crews()"
    [laborHours]="this.labor()?.hours || 0"
  ></app-labor-crew-display>
}

@if (displayFlatRate()) {
  <ion-item (click)="openLaborModal($event)" [button]="true" class="">
    <ion-input
      label="Labor Flat Rate"
      label-placement="floating"
      placeholder="Labor"
      [value]="'$'+this.labor()?.flatRate"
      class="hover"
      readonly="readonly"
      [helperText]="'Task hours: ' + this.labor()?.hours"
    ></ion-input>
  </ion-item>
}

