import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {Labor} from "@modules/labor/Domain/labor";
import {LaborCrewMode} from "@modules/labor/Domain/labor-crew-mode";
import {computeCrewNamesFromGmInputs} from "@modules/labor/Presentation/Service/crewNames";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-labor-modal',
  templateUrl: './labor-modal.component.html',
  styleUrls: ['./labor-modal.component.scss'],
})
export class LaborModalComponent implements OnInit {
  @Input({required: true}) labor?: Labor;
  @Input({required: true}) gmInputs?: GmInputs;

  protected readonly LaborCrewMode = LaborCrewMode;
  protected readonly form: FormGroup = new FormGroup({
    ...Object.fromEntries(
      Labor.getFormFields().map((field) => [
        field,
        new FormControl()
      ])
    ),
  });

  protected isCrewMode: boolean = false;
  protected isFlatRateMode: boolean = false;
  protected crewNames: string = '';

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.setupFormSubscriptions();

    if (!this.labor) throw new Error('labor is required');
    const formFields = this.labor.toFields();
    if (formFields.labourHours === 0) formFields.labourHours = undefined;
    this.form.patchValue(formFields);
    this.setDefaultValidators();
  }

  async cancel() {
    await this.modalCtrl.dismiss();
  }

  async confirm() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      await this.notificationService.execute('Form is invalid');
      return;
    }

    const updatedLabor = Labor.fromFields(this.form.value);
    await this.modalCtrl.dismiss(updatedLabor, 'confirm');
  }

  private setupFormSubscriptions() {
    this.form
      .get('mode')!
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((mode) => {
        this.isCrewMode = mode === LaborCrewMode.crew().toString();
        this.isFlatRateMode = mode === LaborCrewMode.flat().toString();
        this.updateConditionalValidators(mode);
      });

    this.form
      .get('laborCrew')!
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((stringIDs: string[]) => {
        if (!stringIDs) return;
        const crewIDs = stringIDs.filter((id) => id).map((id) => new CrewID(Number(id)));
        this.crewNames = computeCrewNamesFromGmInputs(this.gmInputs, crewIDs);
      });
  }

  /**
   * Updates validators for laborCrew and flatRate based on the current mode value.
   * @param mode The current value of the mode field
   */
  private updateConditionalValidators(mode: string) {
    const laborCrewControl = this.form.get('laborCrew')!;
    const flatRateControl = this.form.get('flatRate')!;

    laborCrewControl.clearValidators();
    flatRateControl.clearValidators();

    if (mode === LaborCrewMode.crew().toString()) {
      laborCrewControl.setValidators(Validators.required);

    } else if (mode === LaborCrewMode.flat().toString()) {
      flatRateControl.setValidators(Validators.required);
    }

    laborCrewControl.updateValueAndValidity();
    flatRateControl.updateValueAndValidity();
  }

  private setDefaultValidators() {
    const labourHoursControl = this.form.get('labourHours')!;
    labourHoursControl.clearValidators();
    labourHoursControl.setValidators([
      Validators.required,
      Validators.min(1),
      (control) => {
        return control.value === 0 ? {'zeroNotAllowed': true} : null;
      }
    ]);
    labourHoursControl.updateValueAndValidity();
  }
}
