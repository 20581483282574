import {Component, computed, effect, input, signal} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule, ModalController} from "@ionic/angular";
import {ModalValueRole} from "@modules/_shared/Component/modal-value-edit/modal-value-edit/Component/modal-value-role";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";


@Component({
  selector: 'app-products-selector-modal',
  templateUrl: './products-selector-modal.component.html',
  styleUrls: ['./products-selector-modal.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule,
    FormsModule
  ],
  standalone: true
})
export class ProductsSelectorModalComponent<T extends Products> {
  protected selectedProductInput = input.required<Product | undefined>();
  protected products = input.required<T>();

  protected selectedProduct = signal<Product | undefined>(undefined);
  protected searchTerm = signal('');
  protected loading = signal(false);
  protected filteredProducts = computed(
    () => this.products().filterBySearchTerm(this.searchTerm()).toArray()
  );

  constructor(
    private readonly modalCtrl: ModalController,
  ) {
    effect(() => {
      this.selectedProduct.set(this.selectedProductInput());
    }, {allowSignalWrites: true});
  }


  async confirm(): Promise<void> {
    await this.modalCtrl.dismiss(this.selectedProduct(), ModalValueRole.Confirm);
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss(undefined, ModalValueRole.Cancel);
  }
}
