import {Injectable} from "@angular/core";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  CategoryProductCreationManager
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";


@Injectable({providedIn: 'root'})
export class GetProductQuery implements UseCase<string, Promise<Product | undefined>> {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly productFactory: CategoryProductCreationManager
  ) {
  }

  async execute<T extends Product>(productID: string | undefined): Promise<T | undefined> {
    if (!productID) return undefined;
    try {
      const productIDObject = new ProductID(productID);
      const product = await this.productRepository.getOne(productIDObject);
      return this.productFactory.createProduct<T>(product);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
