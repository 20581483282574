<ion-content>
  <app-header [explicitBackUrl]="['/']" [title]="title"></app-header>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  @if (filteredCategories) {
    <ion-list data-cy="categories-list">
      <ion-item class="ion-no-padding">
        <ion-searchbar
          (ngModelChange)="filterCategories($event)"
          [(ngModel)]="searchTerm"
          [debounce]="100"
          placeholder="Search Categories"
        ></ion-searchbar>
      </ion-item>

      @for (category of filteredCategories; track category.id.getValue(); let i = $index) {
        <ion-item [routerLink]="getCategoryLink(category)">
          <ion-note slot="start">{{ i + 1 }}</ion-note>
          <ion-label>
            <ion-text>{{ category.title }}</ion-text>
          </ion-label>
        </ion-item>
      }
    </ion-list>
  }
</ion-content>
