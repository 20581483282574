import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {DynamicFormField} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-field";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {
  DynamicFormFieldsToFormTransformer
} from "@modules/dynamic-form/Domain/DynamicFormField/Service/dynamic-form-fields-to-form-transformer";

@Injectable({
  providedIn: 'root'
})
export class DynamicFormFieldsToFormGroupTransformer extends DynamicFormFieldsToFormTransformer<FormGroup> {
  transform(fields: DynamicFormFields): FormGroup {
    const group: { [key: string]: FormControl } = {};

    fields.getFields().forEach(field => {
      group[field.key] = this.createFormControl(field);
    });

    return new FormGroup(group);
  }

  transformBack(form: FormGroup, fields: DynamicFormFields): DynamicFormFields {
    return fields.setValues(form.getRawValue());
  }

  private createFormControl(field: DynamicFormField<ControlTypes>): FormControl {
    return new FormControl({
        value: field.value ?? '',
        disabled: field.disabled,
      },
      field.required ? Validators.required : null
    );
  }

}
