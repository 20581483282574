import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  UserContextNotificationsInteractionCategory
} from "@modules/area-context-notifications/Application/Category/user-context-notifications-interaction-category";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {
  RecordUserInteractionCommand
} from "@modules/user-interactions/Application/UseCase/Command/RecordUserInteraction/record-user-interaction-command.service";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

@Injectable({
  providedIn: 'root'
})
export class RecordShownContextNotificationCommand implements UseCase<NotificationID, Promise<void>> {
  constructor(
    private readonly recordUserInteractionCommand: RecordUserInteractionCommand,
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileQuery,
  ) {
  }


  execute(notificationID: NotificationID): Promise<void> {
    const profile = this.getCurrentUserProfileUseCase.execute();

    const request = {
      userID: profile.profileID,
      category: UserContextNotificationsInteractionCategory,
      notificationID: notificationID
    };

    return this.recordUserInteractionCommand.execute(request);
  }
}

