@if (loading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}

<ion-item button="true" class="product-input-holder">
  <ion-input
    (click)="openProductsSelector($event)"
    [label]="label"
    [value]="selectedProduct?.name || ''"
    class="product-input"
    label-placement="floating"
    readonly
  >
  </ion-input>
</ion-item>
