import {Injectable} from '@angular/core';
import {
  ProductCreationStrategy
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-strategy";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {MiscProduct} from "@modules/product/products/misc-product/Domain/Product/misc-product";
import {MiscProducts} from "@modules/product/products/misc-product/Domain/Product/misc-products";

@Injectable({
  providedIn: 'root'
})
export class MiscProductCreationStrategy extends ProductCreationStrategy<MiscProducts, MiscProduct> {

  createProduct(product: Product): MiscProduct {
    return new MiscProduct(
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      product.price,
      product.ownerBusinessUnitID,
      product.category,
      product.getAllFields(),
      product.rootBusinessUnitID,
      product.overagePercentage,
      product.originalProduct
    );
  }

  createProducts(products: Products): MiscProducts {
    return new MiscProducts(
      products.toArray().map(product => this.createProduct(product))
    );
  }
}
