import {AfterViewInit, Component} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: false
})
export class ProfileComponent implements AfterViewInit {
  profile: Profile | undefined;
  loading: boolean = false;

  constructor(
    private readonly getUserProfile: GetCurrentUserProfileQuery,
    private readonly notificationService: NotificationService
  ) {
  }

  async ngAfterViewInit(): Promise<void> {
    this.loading = true;
    try {
      this.profile = await this.getUserProfile.execute();
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }
}
