import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {MiscAreaItem} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";

export class MiscArea extends CalculationArea {
  constructor(
    public override readonly id: string,
    public override readonly name: string,
    public readonly items: MiscAreaItem[],
    public override readonly businessUnitID: BusinessUnitID
  ) {
    super({id, name, businessUnitID});
  }

  getItem(id: number): MiscAreaItem {
    return this.items[id];
  }

  addItem(item: MiscAreaItem): MiscArea {
    const newItems = [...this.items, item];
    return new MiscArea(
      this.id,
      this.name,
      newItems,
      this.businessUnitID
    );
  }

  removeItem(id: number): MiscArea {
    const newItems = this.items.filter((_, index) => index !== id);
    return new MiscArea(
      this.id,
      this.name,
      newItems,
      this.businessUnitID
    );
  }

  updateItem(miscItemID: number, areaItem: MiscAreaItem): MiscArea {
    const newItems = this.items.map((item, index) => {
      if (index === miscItemID) {
        return areaItem;
      }
      return item;
    });
    return new MiscArea(
      this.id,
      this.name,
      newItems,
      this.businessUnitID
    );
  }
}
