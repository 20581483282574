import {ProfileID} from "@modules/profile/Domain/Profile/VA/profile-id";
import {IcalcTemplateMode} from "@modules/user-config/Domain/UserConfig/VO/icalc-template-mode";

export class UserConfig {
  constructor(
    public readonly userID: ProfileID,
    private templateMode: IcalcTemplateMode
  ) {
  }

  isTrainingTemplateMode(): boolean {
    return this.templateMode === IcalcTemplateMode.Training;
  }

  setTemplateMode(templateMode: IcalcTemplateMode): UserConfig {
    return new UserConfig(this.userID, templateMode);
  }
}
