import {Product} from "@modules/product/product/Domain/Product/product";


/**
 * The old product linking:
 * - netProduct: name
 * - pricePer: price
 */
export class BlowInNetProduct extends Product {
  readonly linearYieldFt: number;

  constructor(...params: ConstructorParameters<typeof Product>) {
    super(...params);

    this.linearYieldFt = this.getNumberField('linearYieldFt');
  }

  static fromProduct(product: Product): BlowInNetProduct {
    return new BlowInNetProduct(
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      product.price,
      product.ownerBusinessUnitID,
      product.category,
      product.getAllFields(),
      product.rootBusinessUnitID,
      product.overagePercentage,
      product.originalProduct
    )
  }
}
