import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnitTree} from "@modules/business-unit/Domain/BusinessUnit/Tree/business-unit-tree";
import {BusinessUnitTreeNode} from "@modules/business-unit/Domain/BusinessUnit/Tree/business-unit-tree-node";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {Profile} from "@modules/profile/Domain/Profile/profile";

export class BusinessUnits {
  private readonly businessUnitTree: BusinessUnitTree;

  constructor(
    private readonly businessUnits: BusinessUnit[],
    private readonly fullTree?: BusinessUnitTree,
    unitOnTop?: BusinessUnitID
  ) {
    this.businessUnits = this.businessUnits.filter((businessUnit, index, self) =>
      index === self.findIndex(t => t.id.equals(businessUnit.id))
    );
    this.businessUnits.sort((a, b) => {
      if (unitOnTop) {
        if (a.id.equals(unitOnTop)) {
          return -1;
        }
        if (b.id.equals(unitOnTop)) {
          return 1;
        }
      }
      return a.name.localeCompare(b.name);
    });
    this.businessUnitTree = fullTree || new BusinessUnitTree(this.businessUnits);
  }

  length(): number {
    return this.businessUnits.length;
  }

  filter(searchTerm: string): BusinessUnits {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filteredBusinessUnits = this.businessUnits.filter(businessUnit =>
      businessUnit.ifApplicableForSearch(lowerCaseSearchTerm)
    );
    return new BusinessUnits(filteredBusinessUnits, this.businessUnitTree);
  }

  getUserAvailableBusinessUnits(profile: Profile): BusinessUnits {
    const profileBusinessUnit = this.businessUnitTree.findNodeById(
      profile.businessUnitID
    );
    if (!profileBusinessUnit) {
      throw new Error('Business unit for profile: ' + profile.fullName + ' not found');
    }

    const businessUnits = [
      profileBusinessUnit.businessUnit,
      ...profileBusinessUnit.getAllChildren()
    ];

    return new BusinessUnits(
      businessUnits,
      this.businessUnitTree
    );
  }

  toArray(): BusinessUnit[] {
    return this.businessUnits;
  }

  getIDs(): BusinessUnitID[] {
    return this.businessUnits.map(businessUnit => businessUnit.id);
  }

  getById(id: BusinessUnitID): BusinessUnit | undefined {
    return this.businessUnits.find(businessUnit => businessUnit.id.equals(id));
  }

  checkIfSameOrAscendant(targetBusinessUnitID: BusinessUnitID, referenceBusinessUnitID: BusinessUnitID): boolean {
    const targetNode = this.businessUnitTree.findNodeById(targetBusinessUnitID);
    const referenceNode = this.businessUnitTree.findNodeById(referenceBusinessUnitID);

    if (!targetNode || !referenceNode) {
      return false;
    }

    let currentNode: BusinessUnitTreeNode | null = referenceNode;
    while (currentNode) {
      if (currentNode.businessUnit.id.equals(targetNode.businessUnit.id)) {
        return true;
      }
      if (!currentNode.businessUnit.parentBusinessUnitID) {
        break;
      }
      currentNode = this.businessUnitTree.findNodeById(currentNode.businessUnit.parentBusinessUnitID);
    }

    return false;
  }

  sortWithBusinessUnitAtTop(topBusinessUnitID: BusinessUnitID): BusinessUnits {
    const topBusinessUnit = this.getById(topBusinessUnitID);
    if (!topBusinessUnit) {
      return new BusinessUnits(this.businessUnits, this.businessUnitTree);
    }

    return new BusinessUnits(
      this.businessUnits,
      this.businessUnitTree,
      topBusinessUnit.id
    );
  }

  getOnlyChildrenOrDirectParents(businessUnitID: BusinessUnitID): BusinessUnits {
    const businessUnit = this.getById(businessUnitID);
    if (!businessUnit) {
      throw new Error('Business unit for ID: ' + businessUnitID + ' not found');
    }


    const businessUnitNode = this.businessUnitTree.findNodeById(businessUnitID);
    if (!businessUnitNode) {
      throw new Error('Business unit for ID: ' + businessUnitID + ' not found');
    }

    const children = businessUnitNode.getAllChildren();
    const parents = this.getDirectParents(businessUnitNode);

    return new BusinessUnits(
      [...children, businessUnit, ...parents],
      this.businessUnitTree
    );
  }

  getDirectChildren(nodeID: BusinessUnitID): BusinessUnits {
    if (!this.fullTree) throw new Error('Full tree not found');

    const children: BusinessUnit[] = [];
    const node = this.fullTree.findNodeById(nodeID);
    if (!node) {
      throw new Error('Node for ID: ' + nodeID + ' not found');
    }

    for (const child of node.children) {
      children.push(child.businessUnit);
    }

    return new BusinessUnits(children, this.fullTree);
  }

  isNotNamed(name: string): BusinessUnits {
    return new BusinessUnits(
      this.businessUnits.filter(businessUnit => businessUnit.name !== name),
      this.fullTree
    );
  }

  private getDirectParents(businessUnitNode: BusinessUnitTreeNode): BusinessUnit[] {
    const parents: BusinessUnit[] = [];
    let currentNode: BusinessUnitTreeNode | null = businessUnitNode;
    while (currentNode) {
      if (!currentNode.businessUnit.parentBusinessUnitID) {
        break;
      }

      const parent = this.getById(currentNode.businessUnit.parentBusinessUnitID);
      if (!parent) {
        throw new Error('Parent business unit for ID: ' + currentNode.businessUnit.parentBusinessUnitID.getValue() + ' not found');
      }
      parents.push(parent);
      currentNode = this.businessUnitTree.findNodeById(currentNode.businessUnit.parentBusinessUnitID);
    }

    return parents;
  }
}
