import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {
  IsCurrentUserCorporateQuery
} from "@modules/product/product/Application/Service/is-current-user-corporate.service";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";

@Injectable({
  providedIn: 'root'
})
export class GetUserAvailableBusinessUnitsQuery implements UseCase<void, Promise<BusinessUnits>> {
  private availableBusinessUnits?: BusinessUnits;

  constructor(
    private readonly getProfile: GetCurrentUserProfileQuery,
    private readonly getAllBusinessUnits: GetBusinessUnitsUseCase,
    private readonly isCurrentUserCorporate: IsCurrentUserCorporateQuery,
  ) {
  }

  async execute(): Promise<BusinessUnits> {
    if (!this.availableBusinessUnits) {
      this.availableBusinessUnits = await this.loadAvailableBusinessUnits();
    }

    return this.availableBusinessUnits;
  }

  private async loadAvailableBusinessUnits() {
    const profile = this.getProfile.execute();
    const allBusinessUnits = this.getAllBusinessUnits.execute();
    const isCurrentUserCorporate = this.isCurrentUserCorporate.execute();

    if (isCurrentUserCorporate) {
      return allBusinessUnits;
    }

    return allBusinessUnits
      .getUserAvailableBusinessUnits(profile)
      .sortWithBusinessUnitAtTop(profile.businessUnitID);
  }
}
