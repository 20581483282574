import {CommonModule, CurrencyPipe, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {AreaMiscInputComponent} from "@modules/_shared/Component/area-misc-input/area-misc-input.component";
import {WidthLengthComponent} from "@modules/_shared/Component/width-length/width-length.component";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {
  BlowInCalculationStrategyFactory
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Application/Factory/blow-in-calculation-strategy-factory.service";
import {
  BlowInAreaComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/area/blow-in-area.component";
import {
  BlowInBlowInComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/blow-in-blow-product/blow-in-blow-in.component";
import {
  BlowInNetProductComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/blow-in-net-product/blow-in-net-product.component";
import {
  BlowInTotalComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/blow-in-total/blow-in-total.component";
import {
  BlowInUniversalProductSelectorComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/blow-in-universal-product-selector/blow-in-universal-product-selector.component";
import {
  AddCalculationStrategyCommand
} from "@modules/calculation-strategy/Application/UseCase/Command/add-calculation-strategy.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {LaborModule} from "@modules/labor/labor.module";
import {
  ProductsSelectorComponent
} from "@modules/product/product/Presentation/Component/products-selector/products-selector.component";

export const BLOW_IN_CALCULATION_ID = new CalculationStrategyID('blowin');

@NgModule({
  declarations: [
    BlowInAreaComponent,
    BlowInTotalComponent,
    BlowInNetProductComponent,
    BlowInBlowInComponent,
    BlowInUniversalProductSelectorComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    CurrencyPipe,
    WidthLengthComponent,
    NgOptimizedImage,
    ClearZeroDirective,
    ProductsSelectorComponent,
    ClearZeroDirective,
    AreaMiscInputComponent,
    LaborModule
  ],
  providers: [],
})
export class BlowInCalculatorModule {

  constructor(
    addCalculationStrategyCommand: AddCalculationStrategyCommand,
    strategyFactory: BlowInCalculationStrategyFactory
  ) {
    const strategy = strategyFactory.execute();
    addCalculationStrategyCommand.execute(strategy).then(r => r);
  }
}
