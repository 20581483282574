<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" strong="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar
    [(ngModel)]="searchTerm"
    [debounce]="100"
    placeholder="Search Products"
  ></ion-searchbar>
  
  @if (filteredProducts().length > 0) {
    <ion-list class="ion-no-padding" data-cy="products-list">
      <ion-item-divider>
        <ion-note slot="start" class="ion-no-padding">Products found: {{ filteredProducts().length }}</ion-note>
        <ion-note slot="end" class="ion-no-padding note-link" (click)="selectedProduct.set(undefined)"
                  color="primary">
          Clear selection
          <ion-icon name="close-outline"></ion-icon>
        </ion-note>
      </ion-item-divider>
      @for (product of filteredProducts(); track product; let i = $index) {
        <ion-item button="true" (click)="selectedProduct.set(product)">
          <ion-note slot="start">{{ i + 1 }}</ion-note>
          <ion-label>
            @let selectedProductValue = selectedProduct();
            @if (selectedProductValue && selectedProductValue.id.equals(product.id)) {
              <ion-text color="primary">* {{ product.name }}</ion-text>
            } @else {
              <ion-text>{{ product.name }}</ion-text>
            }
            <br/>
            <ion-note>{{ product.manufacturer }}</ion-note>
          </ion-label>
        </ion-item>
      }
    </ion-list>
  } @else {
    <ion-item>
      <ion-label>No products found</ion-label>
    </ion-item>
  }

</ion-content>
