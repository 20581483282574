import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  UserContextNotificationsInteractionCategory
} from "@modules/area-context-notifications/Application/Category/user-context-notifications-interaction-category";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {
  RecordUserInteractionCommand
} from "@modules/user-interactions/Application/UseCase/Command/RecordUserInteraction/record-user-interaction-command.service";
import {Request} from "@modules/user-interactions/Application/UseCase/request";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

export interface RecordShownCalculationNotificationCommand {
  calculationID: CalculationID,
  notificationID: NotificationID
}

@Injectable({
  providedIn: 'root'
})
export class RecordShownCalculationNotificationCommandHandler implements UseCase<RecordShownCalculationNotificationCommand, Promise<void>> {

  constructor(
    private readonly recordUserInteractionCommand: RecordUserInteractionCommand,
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileQuery,
  ) {
  }

  execute(command: RecordShownCalculationNotificationCommand): Promise<void> {
    const profile = this.getCurrentUserProfileUseCase.execute();

    const request: Request = {
      userID: profile.profileID,
      category: UserContextNotificationsInteractionCategory,
      calculationID: command.calculationID,
      notificationID: command.notificationID
    };

    return this.recordUserInteractionCommand.execute(request);
  }
}
