<ion-item [formGroup]="form" lines="none">
  <ion-input
    (ionBlur)="onBlur()"
    (ionFocus)="onFocus()"
    [formControlName]="miscControlName"
    [helperText]="miscNote"
    appClearZero
    errorText="Miscellaneous is required"
    inputmode="numeric"
    label="Miscellaneous"
    label-placement="floating"
    placeholder="Miscellaneous"
    type="number"
  ></ion-input>
</ion-item>
