import {Injectable} from '@angular/core';
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsCountQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-count-query.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  UserInteractionEventRepository
} from "@modules/user-interactions/Domain/UserInteractionEvent/Repository/user-interaction-event-repository";
import {UserInteractionEvent} from "@modules/user-interactions/Domain/UserInteractionEvent/user-interaction-event";
import {
  NotificationCategoryID
} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-category-i-d";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";
import {
  DomainEventToDynamicsEventService
} from "@modules/user-interactions/Infrastructure/Repository/Mapper/domain-event-to-dynamics-event.service";
import {
  DynamicsInteractionEventModel
} from "@modules/user-interactions/Infrastructure/Repository/Model/dynamics-interaction-event-model";
import {CountAllRequest, CreateRequest, RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class DynamicsUserInteractionEventRepositoryService extends UserInteractionEventRepository {

  private readonly tableName = 'cr9b4_ifoamicalcuserinteractions';
  private domainEventToDynamicsEventService: DomainEventToDynamicsEventService = new DomainEventToDynamicsEventService();

  constructor(
    private readonly dynamicsCountQuery: DynamicsCountQuery,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand
  ) {
    super();
  }

  create(event: UserInteractionEvent): Promise<void> {
    const request: CreateRequest = {
      collection: this.tableName,
      data: this.domainEventToDynamicsEventService.map(event)
    };

    return this.dynamicsCreateCommand.execute(request);
  }

  async getCountByCategory(
    category: NotificationCategoryID,
    userID?: string,
    notificationID?: NotificationID,
    dateBefore?: Date,
    dateAfter?: Date
  ): Promise<number> {
    const filter = this.getFilter(category, userID, notificationID, undefined, dateBefore, dateAfter);
    const request: CountAllRequest = {
      collection: this.tableName,
      filter,
      select: ['cr9b4_notification_id'],
    };

    return this.dynamicsCountQuery.execute(request);
  }

  async getNewUsers(category: NotificationCategoryID, dateAfter: Date, dateBefore?: Date): Promise<number> {
    return this.getNewEntities(category, dateAfter, dateBefore, 'user');
  }

  async getNewZees(category: NotificationCategoryID, dateAfter: Date, dateBefore?: Date): Promise<number> {
    return this.getNewEntities(category, dateAfter, dateBefore, 'businessUnit');
  }

  async get(
    category: NotificationCategoryID,
    userID?: string,
    notificationID?: NotificationID,
    calculationID?: CalculationID,
    dateBefore?: Date,
    dateAfter?: Date
  ): Promise<DynamicsInteractionEventModel[]> {
    const filter = this.getFilter(category, userID, notificationID, calculationID, dateBefore, dateAfter);
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter,
      select: ['_createdby_value', 'cr9b4_notification_id', '_owningbusinessunit_value'],
    }

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsInteractionEventModel>(request);
    return response.value;
  }

  private async getNewEntities(
    category: NotificationCategoryID,
    dateAfter: Date,
    dateBefore?: Date,
    entityType: 'user' | 'businessUnit' = 'user'
  ): Promise<number> {
    const entityField = entityType === 'user' ? '_createdby_value' : '_owningbusinessunit_value';

    const filter = this.getFilter(category, undefined, undefined, undefined, dateBefore, dateAfter);
    const loggedInEntitiesRequest: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter,
      select: [entityField],
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsInteractionEventModel>(loggedInEntitiesRequest);
    const loggedInEntityIds = new Set(response.value.map(event => event[entityField]));
    const loggedInEntityIdsArray = Array.from(loggedInEntityIds);
    const loggedInEntityIdsFilter = loggedInEntityIdsArray.length > 0
      ? ' and (' + loggedInEntityIdsArray.map(id => `${entityField} eq '${id}'`).join(' or ') + ')'
      : '';

    const entitiesBeforeDate: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `cr9b4_notification_category eq '${category}' and createdon lt '${dateAfter.toISOString()}' ${loggedInEntityIdsFilter}`,
      select: [entityField]
    };

    const entitiesBeforeDateResponse = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsInteractionEventModel>(entitiesBeforeDate);
    const previousEntityIds = new Set(entitiesBeforeDateResponse.value.map(event => event[entityField]));

    const newEntities = loggedInEntityIdsArray.filter(id => !previousEntityIds.has(id));
    return newEntities.length;
  }

  private getFilter(
    category: NotificationCategoryID,
    userID?: string,
    notificationID?: NotificationID,
    calculationID?: CalculationID,
    dateBefore?: Date,
    dateAfter?: Date
  ): string {
    let filter = `cr9b4_notification_category eq '${category}'`;
    if (userID) {
      filter += ` and _createdby_value eq '${userID}'`;
    }
    if (notificationID) {
      filter += ` and cr9b4_notification_id eq '${notificationID}'`;
    }
    if (calculationID) {
      filter += ` and cr9b4_calculation_id eq '${calculationID}'`;
    }
    if (dateBefore) {
      filter += ` and createdon le '${dateBefore.toISOString()}'`;
    }
    if (dateAfter) {
      filter += ` and createdon ge '${dateAfter.toISOString()}'`;
    }

    return filter;
  }
}
