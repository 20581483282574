<div *ngIf="form" [formGroup]="form">
  <ion-item (click)="select1.open()" lines="full">
    <ion-label class="ion-no-padding ion-no-margin">
      <ion-label class="ion-no-padding" position="stacked">Labor Crew</ion-label>
      <p>
        <app-crew-chip-display [crews]="selectedCrew()"></app-crew-chip-display>
      </p>
    </ion-label>


    <ion-select
      #select1
      [multiple]="true"
      class="ion-page-hidden"
      formControlName="laborCrew"
      label="Select Labor Crew"
      label-placement="floating"
      placeholder="Select Labor Crew"
    >
      @for (crew of activeAndInactiveCrews(); track crew.id.toString()) {
        <ion-select-option [value]="crew.id.getValue()">
          {{ crew.name }}
        </ion-select-option>
      }
    </ion-select>

  </ion-item>

  @if (inactiveCrews() && inactiveCrews().length) {
    <div class="input-bottom sc-ion-input-md ion-padding-start">
      <div class="helper-text sc-ion-input-md">
        Please note that inactive crews are used in the form:
        @for (crew of inactiveCrews(); track crew) {
          <ion-text color="primary">
            {{ crew.name }}
          </ion-text>
        }
      </div>
    </div>
  }
</div>
