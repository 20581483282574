import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {Product} from "@modules/product/product/Domain/Product/product";


/**
 * The original product fields:
 * - price: costPerSetOrGallon
 * - name: name
 */
export class FoamProduct extends Product {
  readonly service: FoamService;
  readonly productYield: number;

  constructor(...params: ConstructorParameters<typeof Product>) {
    super(...params);

    this.service = new FoamService(this.getDropDownField('service'));
    this.productYield = this.getNumberField('productYield');
  }

  static fromProduct(product: Product): FoamProduct {
    return new FoamProduct(
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      product.price,
      product.ownerBusinessUnitID,
      product.category,
      product.getAllFields(),
      product.rootBusinessUnitID,
      product.overagePercentage,
      product.originalProduct
    );
  }

  isMatchingService(service: FoamService): boolean {
    return this.service.isEqual(service);
  }
}
