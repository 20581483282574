import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {UserInteractionEvent} from "@modules/user-interactions/Domain/UserInteractionEvent/user-interaction-event";
import {
  NotificationCategoryID
} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-category-i-d";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";
import {
  DynamicsInteractionEventModel
} from "@modules/user-interactions/Infrastructure/Repository/Model/dynamics-interaction-event-model";

export abstract class UserInteractionEventRepository {
  abstract create(event: UserInteractionEvent): Promise<void>;

  abstract getCountByCategory(
    category: NotificationCategoryID,
    userID?: string,
    notificationID?: NotificationID,
    dateBefore?: Date,
    dateAfter?: Date
  ): Promise<number>;

  abstract get(
    category: NotificationCategoryID,
    userID?: string,
    notificationID?: NotificationID,
    calculationID?: CalculationID,
    dateBefore?: Date,
    dateAfter?: Date,
    uniqueUsers?: boolean
  ): Promise<DynamicsInteractionEventModel[]>;

  abstract getNewUsers(
    category: NotificationCategoryID,
    dateAfter: Date,
    dateBefore?: Date
  ): Promise<number> ;

  abstract getNewZees(
    category: NotificationCategoryID,
    dateAfter: Date,
    dateBefore?: Date
  ): Promise<number> ;
}
