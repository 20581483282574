import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {BlowInArea} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area";
import {
  BlowInAreaOptions
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-options";
import {Labor} from "@modules/labor/Domain/labor";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-service-type-enum";

@Injectable({
  providedIn: 'root'
})
export class BlowInAreaFactory extends CalculationAreaFactory<BlowInArea, BlowInAreaOptions> {
  private readonly defaults: BlowInAreaOptions = {
    id: '',
    name: 'Blow In',
    blowInType: BlowInServiceTypeEnum.BlowInCellulose,
    sqft: 0,
    construction: '',
    areaType: BlowInAreaTypeEnum.Attic,
    toggleNetService: false,
    rValue: 0,
    linearFeet: 0,
    blowInProduct: '',
    miscellaneous: 0,
    netProduct: '',
    businessUnitID: new BusinessUnitID(''),
    labor: Labor.default()
  };

  execute(data: Partial<BlowInAreaOptions> = {}): BlowInArea {
    const validData = this.removeInvalidFields(data);
    const mergedData: BlowInAreaOptions = {...this.defaults, ...validData};
    return new BlowInArea(mergedData);
  }
}
