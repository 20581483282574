<ion-item [formGroup]="form">
  <ion-select
    formControlName="depth"
    label="Select Depth"
    label-placement="floating"
    placeholder="Select Depth"
  >
    @for (item of availableDepths; track item) {
      <ion-select-option [value]="item.value">
        {{ item.value }}
      </ion-select-option>
    }
  </ion-select>
  <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
</ion-item>
