import {Component, OnInit} from '@angular/core';
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";

@Component({
  selector: 'app-delete-test-products',
  templateUrl: './delete-test-products.component.html',
  styleUrls: ['./delete-test-products.component.scss'],
  standalone: false
})
export class DeleteTestProductsComponent implements OnInit {

  constructor(
    private readonly productRepository: ProductRepository,
    private readonly profile: GetCurrentUserProfileQuery
  ) {
  }

  async ngOnInit() {
    const profile = this.profile.execute();
    await this.productRepository.clearBusinessUnitProducts(profile.businessUnitID);
  }

}
