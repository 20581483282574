import {Injectable} from '@angular/core';
import {ManagerEmailsProvider} from "@modules/_shared/Service/UserIsManager/manager-emails.provider";

@Injectable({
  providedIn: 'root'
})
export class StaticManagerEmailsProvider implements ManagerEmailsProvider {
  private readonly managerEmailsList = [
    'dconway@horsepowerbrands.com',
  ];

  execute(): Promise<string[]> {
    return Promise.resolve(this.managerEmailsList);
  }
}
