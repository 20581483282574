import {Injectable} from "@angular/core";
import {Appointment} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/appointment";
import {
  AppointmentRepository
} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/Repository/appointment-repository";
import {Customer} from "@modules/service-titan/service-titan-customer/Domain/Customer/customer";
import {
  CustomerRepository
} from "@modules/service-titan/service-titan-customer/Domain/Customer/Repository/customer-repository";
import {
  SearchCustomerRequestParameters
} from "@modules/service-titan/service-titan-customer/Domain/Customer/Repository/search-customer-request-parameters";
import {Job} from "@modules/service-titan/service-titan-job/Domain/Job/job";
import {Location} from "@modules/service-titan/service-titan-location/Domain/Location/location";
import {
  LocationRepository
} from "@modules/service-titan/service-titan-location/Domain/Location/Repository/location-repository";
import {
  SearchLocationRequestParameters
} from "@modules/service-titan/service-titan-location/Domain/Location/Repository/search-location-request-parameters";

@Injectable({providedIn: 'root'})
export class JobDetailsAssigner {
  constructor(
    private readonly customerRepository: CustomerRepository,
    private readonly locationRepository: LocationRepository,
    private readonly appointmentRepository: AppointmentRepository
  ) {
  }

  async assign(jobs: Job[]): Promise<Job[]> {
    if (jobs.length === 0) {
      return jobs;
    }

    const [
      customers,
      locations,
      appointments
    ] = await Promise.all([
      this.getCustomers(jobs),
      this.getLocations(jobs),
      this.getAppointments(jobs)
    ]);

    jobs.forEach(job => {
      const customer = customers.find(customer => customer.id.equalsValue(job.customerID));
      if (!customer) {
        throw new Error(`Customer with ID ${job.customerID} not found for job ${job.id}`);
      }
      job.assignCustomer(customer);

      const location = locations.find(location => location.id.equalsValue(job.locationID));
      if (!location) {
        throw new Error(`Location with ID ${job.locationID} not found for job ${job.id}`);
      }
      job.assignLocation(location);

      const firstAppointment = appointments.find(appointment => appointment.id.equalsValue(job.firstAppointmentId));
      if (!firstAppointment) {
        throw new Error(`First appointment with ID ${job.firstAppointmentId} not found for job ${job.id}`);
      }
      job.assignFirstAppointment(firstAppointment);
    });

    return jobs;
  }

  private async getCustomers(jobs: Job[]): Promise<Customer[]> {
    const customerIDs = jobs.map(job => job.customerID);
    const customersSearchCriteria: SearchCustomerRequestParameters = {
      ids: customerIDs.join(',')
    };
    return this.customerRepository.searchCustomers(customersSearchCriteria);
  }

  private async getLocations(jobs: Job[]): Promise<Location[]> {
    const locationIDs = jobs.map(job => job.locationID);
    const locationsSearchCriteria: SearchLocationRequestParameters = {
      ids: locationIDs.join(',')
    };

    return this.locationRepository.searchLocations(locationsSearchCriteria);
  }

  private async getAppointments(jobs: Job[]): Promise<Appointment[]> {
    const appointmentIDs = jobs.map(job => job.firstAppointmentId);

    return this.appointmentRepository.searchAppointments({
      ids: appointmentIDs.join(',')
    });
  }
}
