import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {
  FoamArea,
  FoamAreaOptions,
  SelectedRoofPitch
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area";
import {
  FoamRoofPitchMethodsProvider
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-roof-pitch-methods-provider";
import {
  FoamRoofPitchesProvider
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-roof-pitches-provider";
import {
  RoofPitchCalculationMethodName
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/VO/roof-pitch-calculation-method-name";
import {RoofPitchName} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/ValueObject/roof-pitch-name";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {Labor} from "@modules/labor/Domain/labor";

@Injectable({
  providedIn: 'root'
})
export class FoamAreaFactory extends CalculationAreaFactory<FoamArea, FoamAreaOptions> {
  private readonly defaults: FoamAreaOptions = {
    id: '',
    name: 'Foam',
    sqft: 0,
    depth: 0,
    miscellaneous: 0,
    product: '',
    service: FoamService.openCellFoam,
    businessUnitID: new BusinessUnitID(''),
    labor: Labor.default()
  };

  constructor(
    private readonly roofPitchesProvider: FoamRoofPitchesProvider,
    private readonly roofPitchMethodsProvider: FoamRoofPitchMethodsProvider
  ) {
    super();
  }

  execute(data: Partial<FoamAreaOptions> = {}): FoamArea {
    const validData = this.removeInvalidFields(data);
    const mergedData: FoamAreaOptions = {...this.defaults, ...validData};
    const area = new FoamArea(mergedData);

    const selectedRoofPitch = this.getRoofPitch(
      mergedData.roofPitchName,
      mergedData.roofPitchMethodName
    );
    if (selectedRoofPitch) {
      area.setRoofPitch(selectedRoofPitch.pitch, selectedRoofPitch.method);
    }

    return area;
  }

  private getRoofPitch(roofPitchName?: string, roofPitchMethodName?: string): SelectedRoofPitch | undefined {
    if (!roofPitchName || !roofPitchMethodName) return;

    const pitch = this.roofPitchesProvider
      .getRoofPitches()
      .findByName(new RoofPitchName(roofPitchName))

    if (!pitch) throw new Error(`Roof pitch ${roofPitchName} not found`);

    const method = this.roofPitchMethodsProvider
      .getRoofPitchMethods()
      .findByName(new RoofPitchCalculationMethodName(roofPitchMethodName));
    if (!method) throw new Error(`Roof pitch method ${roofPitchMethodName} not found`);

    return {
      pitch,
      method
    };
  }
}
