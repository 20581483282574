import "reflect-metadata";
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableSentry} from "@modules/sentry/enable-sentry";
import {isSentryEnabled} from "@modules/sentry/is-sentry-enabled";

import {AppModule} from './_app/app.module';
import {environment} from './environments/environment';
import {EnvironmentType} from "./environments/environment-type";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister().then();
    });
  });
}

if (environment.environment === EnvironmentType.production) {
  enableProdMode();
}

if (isSentryEnabled) {
  enableSentry();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
