<ion-list lines="none">
  <app-width-length [form]="form"></app-width-length>

  @if (displayPitchSelector) {
    <ion-item>
      <ion-toggle
        (ionChange)="roofPitchChanged($event)"
        [(ngModel)]="isPitchFactorEnabled"
      >
        Apply Pitch Factor
      </ion-toggle>
    </ion-item>
  }

  <app-foam-universal-product-selector [form]="form"></app-foam-universal-product-selector>

  <form [formGroup]="form">
    <app-labor-form [form]="form"></app-labor-form>
    <app-area-misc-input [form]="form"></app-area-misc-input>
  </form>
</ion-list>


