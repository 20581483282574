@if (isTrainingTemplateMode) {
  <app-batting-area-product-selector [form]="form"></app-batting-area-product-selector>
} @else {
  <app-products-selector
    [form]="form"
    [categoryNames]="categoryNames"
    [formKey]="'productID'"
    (productSelected$)="productSelected($event)"
  ></app-products-selector>
}
