import {Injectable} from "@angular/core";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";
import {
  GetRequestNormalizer
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Normalizer/get-request-normalizer.service";
import {Appointment} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/appointment";
import {
  AppointmentRepository
} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/Repository/appointment-repository";
import {
  SearchAppointmentRequestParameters
} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/Repository/search-appointment-request-parameters";
import {
  AppointmentFactory
} from "@modules/service-titan/service-titan-appointment/Infrastructure/Repository/Appointment/Factory/appointment-factory";
import {
  ServiceTitanAppointmentModel
} from "@modules/service-titan/service-titan-appointment/Infrastructure/Repository/Appointment/Model/service-titan-appointment-model";

@Injectable({'providedIn': 'root'})
export class ServiceTitanProxyAppointmentRepository extends AppointmentRepository {
  private readonly endpoint = '/jpm/v2/tenant/{tenant}/appointments';

  constructor(
    private readonly proxyClient: ServiceTitanClient,
    private readonly getRequestNormalizer: GetRequestNormalizer,
    private readonly appointmentFactory: AppointmentFactory
  ) {
    super();
  }

  async searchAppointments(parameters: SearchAppointmentRequestParameters): Promise<Appointment[]> {
    const normalizedParameters = this.getRequestNormalizer.execute(parameters);
    const request = Request.createGetRequest(this.endpoint, normalizedParameters);

    return await this.proxyClient.callMultiple<ServiceTitanAppointmentModel, Appointment>(
      request,
      this.appointmentFactory
    );
  }
}
