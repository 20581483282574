import {FormControl} from "@angular/forms";
import {Labor} from "@modules/labor/Domain/labor";

export function getLaborFormFields(): { [p: string]: "mode" | FormControl<unknown> } {
  return Object.fromEntries(
    Labor.getFormFields().map(
      (field) => [field, new FormControl()]
    )
  )
}
