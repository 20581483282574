import {Component, OnInit} from '@angular/core';
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {
  AreaContextNotificationsInteractionCategory
} from "@modules/area-context-notifications/Application/Category/area-context-notifications-interaction-category";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {
  RecordUserInteractionCommand
} from "@modules/user-interactions/Application/UseCase/Command/RecordUserInteraction/record-user-interaction-command.service";
import {
  GetUserInteractionsCountQuery
} from "@modules/user-interactions/Application/UseCase/Query/GetInteractions/get-user-interactions-count-query.service";
import {Request} from "@modules/user-interactions/Application/UseCase/request";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-user-interaction-test-component',
  templateUrl: './user-interaction-test-component.component.html',
  styleUrls: ['./user-interaction-test-component.component.scss'],
  standalone: false
})
export class UserInteractionTestComponentComponent implements OnInit {
  protected loading: boolean = false;
  protected countInteractions?: number;
  private profile?: Profile;

  constructor(
    private readonly getUserInteractionsQuery: GetUserInteractionsCountQuery,
    private readonly recordUserInteractionCommand: RecordUserInteractionCommand,
    private readonly getProfileUseCase: GetCurrentUserProfileQuery,
    private readonly loadingService: LoadingService
  ) {
  }

  async ngOnInit() {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);

    await this.loadingService.execute({
      executeCb: async () => {
        this.profile = await this.getProfileUseCase.execute();
      }
    });
  }

  async addInteraction(): Promise<void> {
    await this.loadingService.execute({
      executeCb: async () => await this.recordUserInteractionCommand.execute(this.getRequest())
    });
  }

  protected async loadInteractionsCount() {
    await this.loadingService.execute({
      executeCb: async () => {
        this.countInteractions = await this.getUserInteractionsQuery.execute(this.getRequest());
      }
    });
  }

  private getRequest(): Request {
    if (!this.profile) throw new Error('Profile not found');

    return {
      userID: this.profile.profileID,
      category: AreaContextNotificationsInteractionCategory,
      notificationID: new NotificationID('attic-roofline-hot-pipes'),
    }
  }
}
