import {NotificationAction} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action";
import {
  NotificationTrigger
} from "@modules/area-context-notifications/Domain/Notification/Trigger/notification-trigger";
import {NotificationEvent} from "@modules/area-context-notifications/Domain/NotificationEvent/notification-event";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

export class Notification {
  constructor(
    readonly notificationID: NotificationID,
    private readonly trigger: NotificationTrigger,
    public readonly action: NotificationAction
  ) {
  }

  isApplicable(triggerEvent: NotificationEvent): boolean {
    return this.isApplicableToCalculation(triggerEvent.calculation) && this.isAreaEquals(triggerEvent.area);
  }

  public isApplicableToCalculation(calculation: CalculationStrategyID): boolean {
    if (!this.trigger.calculation) return false;
    return this.trigger.calculation.equals(calculation);
  }

  private isAreaEquals(eventArea: string): boolean {
    if (!this.trigger.area) return false;
    return this.trigger.area.test(eventArea);
  }
}
