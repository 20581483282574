import {Injectable} from '@angular/core';
import {
  BlowInCalculationStrategy
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/Calculation/blow-in-calculation-strategy";
import {
  BlowInAreaFactory
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-factory";
import {
  BlowInAreaTotalFactory
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationAreaTotal/blow-in-area-total-factory";
import {
  BlowInAreaComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/area/blow-in-area.component";
import {
  BlowInTotalComponent
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Presentation/blow-in-total/blow-in-total.component";
import {BLOW_IN_CALCULATION_ID} from "@modules/calculation-impl/blow-in-calculator/blow-in-calculator.module";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GetProductQuery} from "@modules/product/product/Application/UseCase/Query/GetProduct/get-product-query.service";
import {
  GetProductsQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-query.service";

@Injectable({
  providedIn: 'root'
})
export class BlowInCalculationStrategyFactory {
  constructor(
    private readonly areaFactory: BlowInAreaFactory,
    private readonly getProductsByCategoryQuery: GetProductsQuery,
    private readonly getProductQuery: GetProductQuery,
    private readonly calculationTotalFactory: CalculationTotalFactory,
    private readonly areaTotalFactory: BlowInAreaTotalFactory
  ) {
  }

  execute(): BlowInCalculationStrategy {
    return new BlowInCalculationStrategy(
      BLOW_IN_CALCULATION_ID,
      'Blow In Template',
      this.getProductsByCategoryQuery,
      this.getProductQuery,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      BlowInAreaComponent,
      BlowInTotalComponent,
      this.calculationTotalFactory,
      this.areaFactory,
      this.areaTotalFactory
    );
  }
}
