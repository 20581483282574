import {ValueObject} from "@modules/_shared/Domain/value-object";

export class LaborCrewMode extends ValueObject<string> {
  private static readonly _crew = 'crew';
  private static readonly _flat = 'flat';
  protected override _type!: void;

  constructor(protected override readonly value: string) {
    super(value);

    if (value !== LaborCrewMode._crew && value !== LaborCrewMode._flat) {
      console.error('Invalid Labor Crew Mode, must be "crew" or "flat", received: ' + value);
      this.value = LaborCrewMode._crew;
    }
  }

  static crew(): LaborCrewMode {
    return new LaborCrewMode(LaborCrewMode._crew);
  }

  static flat(): LaborCrewMode {
    return new LaborCrewMode(LaborCrewMode._flat);
  }
}
