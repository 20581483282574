import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";

@Component({
  selector: 'app-area-misc-input',
  templateUrl: './area-misc-input.component.html',
  styleUrls: ['./area-misc-input.component.scss'],
  imports: [
    IonicModule,
    ClearZeroDirective,
    ReactiveFormsModule
  ],
  standalone: true
})
export class AreaMiscInputComponent {
  @Input({required: true}) form: FormGroup = new FormGroup({});
  @Input({required: false}) miscControlName = 'miscellaneous';

  protected miscNote: string = '';

  onFocus() {
    this.miscNote = 'Use this Misc. section for items related to this portion of the job such as piece rate labor or sub contracted labor. ' +
      'Do NOT use it for Misc. Products. Please use the Misc. ' +
      'Template to add products not listed in other templates.';
  }

  onBlur() {
    this.miscNote = '';
  }

}
