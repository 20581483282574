import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {HeaderModule} from "@modules/header/header.module";
import {
  CategoryFieldsConfigProvider
} from "@modules/product/product-category/Domain/Category/Provider/category-fields-config-provider";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";
import {
  CodeCategoryFieldsConfigProvider
} from "@modules/product/product-category/Infrastructure/Provider/code-category-fields-config-provider.service";
import {
  DynamicsCategoryRepository
} from "@modules/product/product-category/Infrastructure/Repository/DynamicsCategoryRepository/category-repository.service";
import {
  ProductCategoriesListComponent
} from "@modules/product/product-category/Presentation/products-categories-list/product-categories-list.component";
import {ProductCategoryRoutingModule} from "@modules/product/product-category/product-category-routing.module";


@NgModule({
  declarations: [
    ProductCategoriesListComponent,
  ],
  imports: [
    CommonModule,
    ProductCategoryRoutingModule,
    IonicModule,
    HeaderModule,
    RouterLink,
    FormsModule
  ],
  providers: [
    {provide: CategoryRepository, useExisting: DynamicsCategoryRepository},
    {provide: CategoryFieldsConfigProvider, useClass: CodeCategoryFieldsConfigProvider}
  ],
})
export class ProductCategoryModule {
}
