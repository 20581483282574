import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {
  RoofPitchCalculationMethod
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/roof-pitch-calculation-method";
import {
  RoofPitchCalculationMethods
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/roof-pitch-calculation-methods";
import {RoofPitch} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/roof-pitch";

export interface SelectedRoofPitch {
  pitch: RoofPitch;
  method: RoofPitchCalculationMethod;
}

export type FoamAreaOptions = CalculationAreaOptions & {
  readonly service: string;
  readonly sqft: number;
  readonly depth: number;
  readonly miscellaneous: number;
  product?: string;
  readonly roofPitchName?: string;
  readonly roofPitchMethodName?: string;
}

export class FoamArea extends CalculationArea {
  public readonly service: string;
  public readonly depth: number;
  public readonly miscellaneous: number;
  public readonly product?: string;
  public readonly roofPitchName?: string;
  public readonly roofPitchMethodName?: string;
  private roofPitch?: SelectedRoofPitch;
  private readonly sqft: number;

  constructor(data: FoamAreaOptions) {
    super(data);

    this.service = data.service;
    this.sqft = Number(data.sqft);
    this.depth = Number(data.depth);
    this.miscellaneous = Number(data.miscellaneous);
    this.product = data.product;
    this.roofPitchName = data.roofPitchName;
    this.roofPitchMethodName = data.roofPitchMethodName;
  }

  setRoofPitch(pitch: RoofPitch, method: RoofPitchCalculationMethod) {
    this.roofPitch = {
      pitch,
      method
    };
  }

  disassociateRoofPitch() {
    this.roofPitch = undefined;
  }

  getRoofPitch(): SelectedRoofPitch | undefined {
    return this.roofPitch;
  }

  getRawSqft(): number {
    return this.sqft;
  }

  getSqft(): number {
    if (!this.roofPitch) return this.sqft;

    if (this.roofPitch.method.equal(RoofPitchCalculationMethods.PitchFactor)) {
      return this.roofPitch.pitch.calcByRoofPitchFactor(this.sqft);
    }

    return this.roofPitch.pitch.calcByValleyFactor(this.sqft);
  }
}
