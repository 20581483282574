import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";

export interface DynamicFormFieldParams<T extends keyof ControlTypeMap> {
  key: string;
  value?: ControlTypeMap[T];
  required?: boolean;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  options?: string[];
}

export type ControlTypeMap = {
  [ControlTypes.String]: string;
  [ControlTypes.Number]: number;
  [ControlTypes.Dropdown]: string;
};

export abstract class DynamicFormField<T extends keyof ControlTypeMap> {
  public abstract readonly controlType: T;
  public readonly key: string;
  public readonly value?: ControlTypeMap[T];
  public readonly required: boolean;
  public readonly label: string;
  public readonly placeholder: string;
  public readonly errorMessage: string;
  public readonly disabled: boolean;
  public readonly options: string[];

  constructor(params: DynamicFormFieldParams<T>) {
    if (typeof params !== 'object') throw new Error('Invalid params');

    if (!params.label) params.label = params.key;
    if (!params.placeholder) params.placeholder = params.key;
    if (!params.errorMessage) params.errorMessage = `${params.key} is required`;

    this.key = params.key;
    this.value = params.value;
    this.required = params.required || false;
    this.label = params.label;
    this.placeholder = params.placeholder;
    this.errorMessage = params.errorMessage;
    this.disabled = params.disabled || false;
    this.options = params.options || [];
  }

  public setValue(value: ControlTypeMap[T]): DynamicFormField<T> {
    const newParams: DynamicFormFieldParams<T> = {
      ...this,
      value,
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new (this.constructor as any)(newParams);
  }

  public getValue<T extends ControlTypes>(): ControlTypeMap[T] {
    return this.value as unknown as ControlTypeMap[T];
  }

  disable() {
    const newParams: DynamicFormFieldParams<T> = {
      ...this,
      disabled: true,
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new (this.constructor as any)(newParams);
  }

  enable() {
    const newParams: DynamicFormFieldParams<T> = {
      ...this,
      disabled: false,
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new (this.constructor as any)(newParams);
  }
}
