import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {Labor} from "@modules/labor/Domain/labor";
import {RemovalArea} from "./removal-area";
import {RemovalAreaOptions} from "./removal-area-options";

@Injectable({
  providedIn: 'root'
})
export class RemovalAreaFactory extends CalculationAreaFactory<RemovalArea, RemovalAreaOptions> {
  private readonly defaults: RemovalAreaOptions = {
    id: '',
    name: '',
    sqft: 0,
    miscellaneous: 0,
    businessUnitID: new BusinessUnitID(''),
    labor: Labor.default()
  }

  execute(data: Partial<RemovalAreaOptions> = {}): RemovalArea {
    const validData = this.removeInvalidFields(data);
    const mergedData: RemovalAreaOptions = {...this.defaults, ...validData};
    return new RemovalArea(mergedData);
  }
}
