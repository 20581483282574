import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule, ModalController} from "@ionic/angular";
import {ModalValueRole} from "@modules/_shared/Component/modal-value-edit/modal-value-edit/Component/modal-value-role";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  GetProductsQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-query.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {
  ProductsSelectorModalComponent
} from "@modules/product/product/Presentation/Component/products-selector-modal/products-selector-modal.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-products-selector',
  templateUrl: './products-selector.component.html',
  styleUrls: ['./products-selector.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule
  ],
  standalone: true
})
export class ProductsSelectorComponent implements OnInit {
  @Input({required: true}) categoryNames: CategoryName[] = [];
  @Input({required: true}) form: FormGroup = new FormGroup({});
  @Input({required: true}) formKey: string = '';
  @Input({required: false}) label: string = 'Product';
  @Input() productFilterCb?: (product: Product) => boolean;
  @Output() productSelected$ = new EventEmitter<Product | undefined>();

  protected allProducts?: Products;
  protected selectedProduct?: Product;
  protected loading: boolean = false;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly getProductsByCategoryQuery: GetProductsQuery,
    private readonly modalCtrl: ModalController,
    private readonly notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);

    this.loadingService.execute({
      executeCb: () => this.loadProducts(),
    }).then();
  }

  async openProductsSelector(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    const modal = await this.modalCtrl.create({
      component: ProductsSelectorModalComponent,
      componentProps: {
        products: this.allProducts,
        selectedProductInput: this.selectedProduct
      }
    });

    await modal.present();

    const modalResult = await modal.onWillDismiss();
    if (modalResult?.role !== ModalValueRole.Confirm) {
      return;
    }

    const selectedProduct: Product | undefined = modalResult.data;
    this.selectProduct(selectedProduct);
  }

  private async loadProducts() {
    if (!this.categoryNames) throw new Error('Category name is required');


    const products = await Promise.all(
      this.categoryNames.map(categoryName => this.getProductsByCategoryQuery.execute({
        categoryName
      }))
    );
    const allProducts = products.map(products => products.toArray()).flat();
    this.allProducts = new Products(allProducts);

    const productID = this.form.get(this.formKey)?.value;
    if (!productID) return;

    const selectedProduct = this.allProducts.getByID(new ProductID(productID));
    if (!selectedProduct) throw new Error(`Product with ID ${productID} not found`);

    this.selectedProduct = selectedProduct;
  }

  private selectProduct(product: Product | undefined) {
    if (!this.allProducts) throw new Error('Products not loaded');

    this.selectedProduct = product;
    this.productSelected$.emit(product);

    const formControl = this.form.get(this.formKey);
    if (!formControl) throw new Error(`Form control ${this.formKey} not found`);

    if (product) {
      formControl.setValue(product.id.getValue());
      this.notificationService.execute(`Product ${product.name.getValue()} selected`).then();
      return
    }

    formControl.setValue(undefined);
    this.notificationService.execute('Product selection cleared').then();
  }

}
