import {AppointmentID} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/VO/appointment-id";

export interface AppointmentOptions {
  id: AppointmentID;
  appointmentNumber: number;
  start: Date;
  end: Date;
  arrivalWindowStart: Date;
  arrivalWindowEnd: Date;
  status: string;
}

export class Appointment {
  public readonly id: AppointmentID;
  public readonly appointmentNumber: number;
  public readonly start: Date;
  public readonly end: Date;
  public readonly arrivalWindowStart: Date;
  public readonly arrivalWindowEnd: Date;
  public readonly status: string;

  constructor(options: AppointmentOptions) {
    const requiredFields: Array<keyof AppointmentOptions> = [
      'id',
      'appointmentNumber',
      'start',
      'end',
      'arrivalWindowStart',
      'arrivalWindowEnd',
      'status'
    ];

    requiredFields.forEach(field => {
      if (!options[field]) {
        throw new Error(`Missing required field: ${field}`);
      }
    });

    this.id = options.id;
    this.appointmentNumber = options.appointmentNumber;
    this.start = options.start;
    this.end = options.end;
    this.arrivalWindowStart = options.arrivalWindowStart;
    this.arrivalWindowEnd = options.arrivalWindowEnd;
    this.status = options.status;
  }
}
