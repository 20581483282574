import {ConstructionType} from "@modules/calculation-impl/batting/construction-type/construction-type";
import {Facing} from "@modules/calculation-impl/batting/facing/Domain/facing";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {Product} from "@modules/product/product/Domain/Product/product";


export class BattingInsulationProduct extends Product {
  public readonly rValue: RValue;
  public readonly facing: Facing;
  public readonly onCenter: OnCenter;
  public readonly widthInches: number;
  public readonly sqftPerPkg: number;

  constructor(...params: ConstructorParameters<typeof Product>) {
    super(...params);

    this.rValue = new RValue(this.getDropDownField('rValue'));
    this.facing = new Facing(this.getDropDownField('facing'));
    const onCenterVal = this.getDropDownField('onCenter');
    this.onCenter = new OnCenter(onCenterVal.trim() ? +onCenterVal : null);

    this.widthInches = this.getNumberField('widthInches');
    this.sqftPerPkg = this.getNumberField('sqftPerPkg');
  }

  static fromProduct(product: Product): BattingInsulationProduct {
    return new BattingInsulationProduct(
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      product.price,
      product.ownerBusinessUnitID,
      product.category,
      product.getAllFields(),
      product.rootBusinessUnitID,
      product.overagePercentage,
      product.originalProduct
    )
  }

  isApplicableForConstructionType(constructionType: ConstructionType): boolean {
    if (this.rValue.equals(new RValue('38'))) return true;

    const isEven = this.widthInches % 2 === 0;

    if (constructionType === ConstructionType.MetalFrame) {
      return isEven;
    }

    if (constructionType === ConstructionType.WoodFrame) {
      return !isEven;
    }

    return false;
  }
}
