import {Injectable} from '@angular/core';
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  GetDynamicsZeesBusinessUnitFilterService
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/get-dynamics-zees-business-unit-filter.service";
import {DateRangeRequest} from "@modules/reporting/Domain/Fact/Repository/date-range-request";
import {PushedEstimate} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/pushed-estimate";
import {PushedEstimates} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/pushed-estimates";
import {
  PushedEstimateRepository
} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/Repository/pushed-estimate-repository";
import {
  DynamicsPushedEstimateMapper
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/PushedEstimate/Mapper/dynamics-pushed-estimate-mapper.service";
import {
  DynamicsPushedEstimate
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/PushedEstimate/Model/dynamics-pushed-estimate";
import {CreateRequest, RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class DynamicsPushedEstimateRepositoryService extends PushedEstimateRepository {

  private readonly tableName = 'cr9b4_ifoamicalcservicetitanestimates';

  constructor(
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsPushedEstimateMapper: DynamicsPushedEstimateMapper,
    private readonly getDynamicsZeesBusinessUnitFilterService: GetDynamicsZeesBusinessUnitFilterService
  ) {
    super();
  }

  async getPushedEstimates(dateRange: DateRangeRequest): Promise<PushedEstimates> {
    const buFilter = this.getDynamicsZeesBusinessUnitFilterService.execute();
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `createdon ge ${dateRange.dateAfter.toISOString()} and createdon le ${dateRange.dateBefore.toISOString()} and (${buFilter.filter})`,
      queryParams: buFilter.queryParams
    }

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsPushedEstimate>(request);
    return this.dynamicsPushedEstimateMapper.mapToDomain(response.value);
  }

  async addPushedEstimate(pushedEstimate: PushedEstimate): Promise<void> {
    const request: CreateRequest = {
      collection: this.tableName,
      data: this.dynamicsPushedEstimateMapper.mapFromDomain(pushedEstimate),
      returnRepresentation: false
    }

    await this.dynamicsCreateCommand.execute(request);
  }
}
