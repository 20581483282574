import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
  GetAvailableServicesUseCase
} from "@modules/calculation-impl/foam/service/Application/UseCase/get-available-services-use-case";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {
  GetProductsQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-query.service";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {FoamProduct} from "@modules/product/products/foam-product/Domain/Product/foam-product";
import {FoamProducts} from "@modules/product/products/foam-product/Domain/Product/foam-products";

@Component({
  selector: 'app-foam-product-training-selector',
  templateUrl: './foam-product-training-selector.component.html',
  styleUrls: ['./foam-product-training-selector.component.scss'],
})
export class FoamProductTrainingSelectorComponent implements OnInit {
  @Input({required: true}) public form: FormGroup = new FormGroup({});

  protected services: FoamService[] = []
  protected availableProducts?: FoamProducts;
  protected filteredProducts: FoamProduct[] = [];


  constructor(
    private readonly getAvailableServicesUseCase: GetAvailableServicesUseCase,
    private readonly getProductsByCategoryQuery: GetProductsQuery
  ) {
  }

  async ngOnInit() {
    this.services = await this.getAvailableServicesUseCase.execute();

    this.availableProducts = await this.getProductsByCategoryQuery.execute<FoamProducts>({
      categoryName: new FoamCategoryName()
    });

    this.displayAvailableProducts();

    this.form.valueChanges.subscribe(() => {
      this.displayAvailableProducts();
    });
  }


  private displayAvailableProducts(): void {
    if (!this.availableProducts) throw new Error('availableProducts is required');

    const serviceName = this.form.get('service')?.value;
    if (!serviceName) {
      this.filteredProducts = [];
      return;
    }

    const service = new FoamService(serviceName);
    this.filteredProducts = this.availableProducts
      .filterProductsByService(service)
      .sortByName()
      .toArray();
  }
}
