import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Request} from "@modules/user-interactions/Application/UseCase/request";
import {
  UserInteractionEventRepository
} from "@modules/user-interactions/Domain/UserInteractionEvent/Repository/user-interaction-event-repository";
import {UserInteractionEvent} from "@modules/user-interactions/Domain/UserInteractionEvent/user-interaction-event";

@Injectable({
  providedIn: 'root'
})
export class RecordUserInteractionCommand implements UseCase<Request, Promise<void>> {

  constructor(
    private readonly userInteractionEventRepository: UserInteractionEventRepository
  ) {
  }

  execute(request: Request): Promise<void> {
    const event = new UserInteractionEvent(
      request.category,
      request.notificationID,
      request.calculationID
    );

    return this.userInteractionEventRepository.create(event);
  }
}
