import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {
  CrewChipDisplayComponent
} from "@modules/labor/Presentation/Component/crew-chip-display/crew-chip-display.component";
import {CrewSelectorComponent} from "@modules/labor/Presentation/Component/crew-selector/crew-selector.component";
import {
  LaborCrewDisplayComponent
} from "@modules/labor/Presentation/Component/labor-crew-display/labor-crew-display.component";
import {LaborFormComponent} from "@modules/labor/Presentation/Component/labor-form/labor-form.component";
import {LaborModalComponent} from "@modules/labor/Presentation/Component/labor-modal/labor-modal.component";


@NgModule({
  declarations: [
    LaborFormComponent,
    LaborModalComponent,
    LaborCrewDisplayComponent,
    CrewChipDisplayComponent,
    CrewSelectorComponent
  ],
  exports: [
    LaborFormComponent,
    CrewChipDisplayComponent,
    CrewSelectorComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ClearZeroDirective,
    ReactiveFormsModule,
  ]
})
export class LaborModule {
}
