import {EstimateDiscount} from "@modules/estimate/Domain/Estimate/estimate-discount";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {EstimateOptions} from "./estimate-options";

export class Estimate {
  readonly id: EstimateID;
  readonly name: string;
  readonly jobID?: number;
  readonly createdOn: Date;
  readonly createdBy: string;
  private estimateDollar: number | null;
  private discount: EstimateDiscount;

  constructor(options: EstimateOptions) {
    if (!options.id) throw new Error("Estimate ID is required");
    if (!options.name) throw new Error("Estimate Name is required");
    if (!options.createdOn) throw new Error("Created On is required");
    if (!options.createdBy) throw new Error("Created By is required");

    let jobID: number | undefined = options.jobID;
    if (!jobID) {
      jobID = undefined;
    }

    this.id = options.id;
    this.name = options.name;
    this.jobID = jobID;
    this.createdOn = options.createdOn;
    this.createdBy = options.createdBy;
    this.estimateDollar = options.estimateDollar || null;
    this.discount = new EstimateDiscount(
      options.discount ?? 0,
      options.discountEnabled ?? false
    )
  }

  setName(name: string) {
    return new Estimate({
      id: this.id,
      name,
      jobID: this.jobID,
      createdOn: this.createdOn,
      createdBy: this.createdBy,
      estimateDollar: this.estimateDollar,
      discount: this.discount.getValue(),
      discountEnabled: this.discount.getIsEnabled()
    })
  }

  getDiscount(): number {
    return this.discount.getIsEnabled() ? this.discount.getValue() : 0;
  }

  getRawDiscount(): number {
    return this.discount.getValue();
  }

  setDiscount(discount: number) {
    this.discount = this.discount.setValue(discount)
  }

  getEstimateDollar(): number {
    return this.estimateDollar || 0;
  }

  setEstimateDollar(estimateDollar: number) {
    this.estimateDollar = Number(estimateDollar);
  }

  isDiscountEnabled(): boolean {
    return this.discount.getIsEnabled()
  }

  enableDiscount() {
    this.discount = this.discount.enable()
  }

  disableDiscount() {
    this.discount = this.discount.disable()
  }
}
