import {Injectable} from '@angular/core';
import {UserInteractionEvent} from "@modules/user-interactions/Domain/UserInteractionEvent/user-interaction-event";
import {
  DynamicsInteractionEventModel
} from "@modules/user-interactions/Infrastructure/Repository/Model/dynamics-interaction-event-model";

@Injectable({
  providedIn: 'root'
})
export class DomainEventToDynamicsEventService {
  map(event: UserInteractionEvent): DynamicsInteractionEventModel {
    return {
      cr9b4_notification_category: event.category.getValue(),
      cr9b4_notification_id: event.notificationID?.getValue(),
      cr9b4_calculation_id: event.calculationID?.getValue(),
    }
  }
}
