<ion-item [button]="true">
  <ion-label class="ion-no-padding ion-no-margin">
    <ion-label class="ion-no-padding" position="stacked">Labor Crew</ion-label>
    <p>
      <app-crew-chip-display [crews]="crewsSignal()"></app-crew-chip-display>
    </p>
    @if (laborHours > 0) {
      <p>
        <ion-note>
          <small>Task hours: {{ laborHours }}</small>
        </ion-note>
      </p>
    }
  </ion-label>

</ion-item>
