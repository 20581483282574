import {Injectable} from "@angular/core";
import {
  AppInitUserProfileProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-user-profile-provider.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";

@Injectable({providedIn: 'root'})
export class GetCurrentUserProfileQuery {
  constructor(
    private readonly appInitUserProfileProvider: AppInitUserProfileProvider
  ) {
  }

  execute(): Profile {
    return this.appInitUserProfileProvider.getUserProfile();
  }
}
