import {Injectable} from '@angular/core';
import {ObjectHarsherService} from "@modules/_shared/Service/CacheResolver/object-harsher.service";

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root'
})
export class CacheResolverService {
  private readonly cache = new Map<string, { expiresIn: number | null; value: any }>();
  private readonly pendingRequests = new Map<string, Promise<any>>();

  constructor(
    private readonly objectHarsherService: ObjectHarsherService
  ) {
  }

  invalidateCache(): void {
    this.cache.clear();
    this.pendingRequests.clear();
  }

  async getOrFetch<T>(
    key: string,
    fetchFunction: () => Promise<T>,
    timeToLive: number | null = null
  ): Promise<T> {
    const hashedKey = await this.objectHarsherService.hashKey(key);
    const cachedValue = await this.get<T>(hashedKey);

    if (cachedValue !== null) {
      return cachedValue;
    }

    if (this.pendingRequests.has(hashedKey)) {
      return this.pendingRequests.get(hashedKey) as Promise<T>;
    }

    const fetchPromise = fetchFunction().then(value => {
      this.set(hashedKey, value, timeToLive);
      return value;
    }).finally(() => {
      this.pendingRequests.delete(hashedKey);
    });
    this.pendingRequests.set(hashedKey, fetchPromise);
    return fetchPromise;
  }

  /**
   * Set a value in the cache
   *
   * @param key
   * @param value
   * @param timeToLive in seconds
   */
  private set<T>(key: string, value: T, timeToLive: number | null = null): void {
    const expiresIn = timeToLive ? Date.now() + timeToLive * 1000 : null;
    this.cache.set(key, {expiresIn, value});
  }

  private get<T>(key: string): T | null {
    const cacheEntry = this.cache.get(key);
    if (!cacheEntry) return null;

    const {expiresIn, value} = cacheEntry;
    if (expiresIn !== null && expiresIn < Date.now()) {
      this.cache.delete(key);
      return null;
    }

    return value;

  }
}
