import {Injectable} from '@angular/core';
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  ServiceTitanBusinessUnitRepository
} from "@modules/service-titan/service-titan-business-unit/Domain/ServiceTitanBusinessUnit/Repository/service-titan-business-unit-repository";
import {
  ServiceTitanBusinessUnit
} from "@modules/service-titan/service-titan-business-unit/Domain/ServiceTitanBusinessUnit/service-titan-business-unit";
import {franchisees} from "@modules/service-titan/service-titan-business-unit/Infrarstructure/Repository/staticData";

@Injectable({
  providedIn: 'root'
})
export class StaticServiceTitanBusinessUnitRepositoryService extends ServiceTitanBusinessUnitRepository {
  constructor() {
    super();
  }

  async getSalesBusinessUnitFor(id: BusinessUnitID): Promise<ServiceTitanBusinessUnit> {
    const result = franchisees.find(f => id.equalsValue(f.businessUnitID));
    if (result) {
      return Promise.resolve(new ServiceTitanBusinessUnit(result.stSalesID));
    }

    return Promise.resolve(new ServiceTitanBusinessUnit(undefined));
  }
}
