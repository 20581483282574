<ion-header>
  <ion-toolbar [formGroup]="form">
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>

    <ion-segment class="segment-full" formControlName="mode">
      <ion-segment-button [value]="LaborCrewMode.crew().toString()">
        <ion-label>Labor Crew</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="LaborCrewMode.flat().toString()">
        <ion-label>Flat Rate</ion-label>
      </ion-segment-button>
    </ion-segment>


    <ion-buttons slot="end">
      @if (form.valid) {
        <ion-button (click)="confirm()" strong="true">Confirm</ion-button>
      } @else {
        <ion-button disabled strong="true">Confirm</ion-button>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [formGroup]="form">
  <ion-list lines="none">
    <ion-item>
      <ion-input
        appClearZero
        errorText="Task Hours is required"
        formControlName="labourHours"
        helper-text="Task hours for this area of the job"
        inputmode="numeric"
        label="Task Hours"
        label-placement="floating"
        placeholder="Task Hours"
        type="number"
      ></ion-input>
    </ion-item>
    <br/>

    @if (isCrewMode) {
      <app-crew-selector [form]="form" [labor]="labor" [gmInputs]="gmInputs"></app-crew-selector>
    }

    @if (isFlatRateMode) {
      <ion-item>
        <ion-input
          appClearZero
          errorText="Flat Rate is required"
          formControlName="flatRate"
          inputmode="numeric"
          label="Flat Rate"
          label-placement="floating"
          placeholder="Flat Rate"
          type="number"
          value="239"
          helper-text="Flat rate in $ for this area of the job"
        ></ion-input>
      </ion-item>
    }

  </ion-list>
</ion-content>
