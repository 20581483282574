import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {ValidationResult} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/validation-result";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";

export class Category {
  constructor(
    public readonly id: CategoryID,
    public readonly name: CategoryName,
    public readonly title: string,
    public readonly description: string,
    public readonly fieldsConfig: FieldsConfig
  ) {
  }

  validateFields(fields: Fields): ValidationResult {
    return this.fieldsConfig.validateFields(fields)
  }

  isApplicableToSearchTerm(searchTerm: string): boolean {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return this.name.getValue().toLowerCase().includes(searchTermLowerCase)
      || this.description.toLowerCase().includes(searchTermLowerCase)
  }

  isBatchUpdateAvailable() {
    return this.isCategoryProductsEditable() && this.name.equals(new BlowInInsulationCategoryName());
  }

  isCategoryProductsEditable() {
    return !this.name.equals(new BlowInInsulationCategoryName());
  }

  isFieldExists(name: string): boolean {
    return this.fieldsConfig.isFieldExists(name);
  }
}
