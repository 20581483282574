import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  BattingAreaFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-factory";
import {
  BattingAreaTotal
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total";
import {
  BattingAreaTotalFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total-factory";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {
  BattingInsulationProduct
} from "@modules/product/products/batting-insulation-product/Domain/Product/batting-insulation-product";
import {BattingArea} from "../CalculationArea/batting-area";

export class BattingCalculationStrategy extends CalculationStrategy<
  BattingArea,
  BattingAreaTotal,
  BattingAreaFactory,
  BattingAreaTotalFactory
> {
  async calculateArea(input: BattingArea, gmInputs: GmInputs): Promise<BattingAreaTotal> {
    if (!input.battingServiceName) return this.areaTotalFactory.execute({});

    const product = await this.getProduct.execute(input.productID);
    if (!product) return this.areaTotalFactory.execute({});

    const overagedSqft = Math.ceil(product.addOverage(input.sqft));
    const materialCost = Math.ceil(overagedSqft * product.getPrice());
    const bagsUsed = input.battingServiceName === BattingServiceEnum.BattInsulation
      ? Math.ceil(overagedSqft / (product as BattingInsulationProduct).sqftPerPkg)
      : 0;

    const laborExpense = input.getLaborExpense(gmInputs);
    const materialSalesTax = materialCost * gmInputs.getSalesTaxRatePercentage();
    const totalCost = materialCost + laborExpense + input.miscellaneous + materialSalesTax;

    const options: BattingAreaTotal = {
      sqft: input.sqft,
      laborHours: input.getLabourHours(),
      laborCost: laborExpense,
      misc: input.miscellaneous,
      product: product.name.getValue(),
      totalCost: totalCost,
      materialCost: materialCost,
      materialSalesTax: materialSalesTax,
      bags: bagsUsed,
      fields: []
    };

    return this.areaTotalFactory.execute(options as BattingAreaTotal);
  }

  async calculateTotal(input: BattingArea[], gmInputs: GmInputs): Promise<CalculationTotal> {
    let total: CalculationTotalOptions = {
      materialCost: 0,
      materialSalesTax: 0,
      laborHours: 0,
      laborCost: 0,
      misc: 0,
      totalCost: 0
    };

    for (const model of input) {
      const areaTotal = await this.calculateArea(model, gmInputs);
      total = {
        materialCost: total.materialCost + areaTotal.materialCost,
        materialSalesTax: total.materialSalesTax + areaTotal.materialSalesTax,
        laborHours: total.laborHours + areaTotal.laborHours,
        laborCost: total.laborCost + areaTotal.laborCost,
        misc: total.misc + areaTotal.misc,
        totalCost: total.totalCost + areaTotal.totalCost
      };
    }

    return this.totalFactory.create(total);
  }
}

