import {Injectable} from '@angular/core';
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  DynamicsUpdateProduct
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Model/dynamics-update-product";


type ProductPublicFields = Pick<DynamicsUpdateProduct, 'cr9b4_price' | 'cr9b4_overage'>

@Injectable({
  providedIn: 'root'
})
export class DynamicsUpdateProductPriceMapperService {
  execute(product: Product): Promise<ProductPublicFields> {
    const priceFields = {
      cr9b4_price: product.price.getValue(),
      cr9b4_overage: product.overagePercentage.value
    }
    return Promise.resolve(priceFields);
  }


}
