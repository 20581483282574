import {Injectable} from "@angular/core";
import {
  DynamicsUpsertCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/dynamics-upsert-command.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {UserConfigRepository} from "@modules/user-config/Domain/UserConfig/Repository/user-config-repository";
import {UserConfig} from "@modules/user-config/Domain/UserConfig/user-config";
import {
  DynamicsUserConfigMapper
} from "@modules/user-config/Infrastructure/Adapters/Dynamics/UserConfig/Mapper/dynamics-user-config-mapper";
import {
  DynamicsUserConfig
} from "@modules/user-config/Infrastructure/Adapters/Dynamics/UserConfig/Model/dynamics-user-config";
import {RetrieveRequest, UpsertRequest} from "dynamics-web-api";

@Injectable({providedIn: 'root'})
export class DynamicsUserConfigRepository extends UserConfigRepository {

  private readonly tableName = 'cr9b4_userconfigs';

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsUpsertCommand: DynamicsUpsertCommand,
    private readonly dynamicsUserConfigMapper: DynamicsUserConfigMapper
  ) {
    super();
  }

  async getUserConfig(userID: string): Promise<UserConfig> {
    const request: RetrieveRequest = {
      collection: this.tableName,
      key: userID
    }

    try {
      const response = await this.dynamicsRetrieveQuery.execute<DynamicsUserConfig>(request);
      return this.dynamicsUserConfigMapper.mapTo(response);
    } catch (e) {
      console.log(e);
      return this.dynamicsUserConfigMapper.createNewUserConfig(userID);
    }
  }

  async setUserConfig(userConfig: UserConfig): Promise<UserConfig> {
    const request: UpsertRequest = {
      collection: this.tableName,
      key: userConfig.userID.toString(),
      data: this.dynamicsUserConfigMapper.mapFrom(userConfig),
      returnRepresentation: true
    }

    const response = await this.dynamicsUpsertCommand.execute<DynamicsUserConfig>(request);
    return this.dynamicsUserConfigMapper.mapTo(response);
  }
}
