import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  UserNotificationStatusRepository
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/Repository/user-notification-status-repository";
import {
  UserNotificationStatus
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/user-notification-status";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

@Injectable({
  providedIn: 'root'
})
export class OptOutNotificationCommand implements UseCase<NotificationID, Promise<void>> {
  constructor(
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileQuery,
    private readonly userNotificationStatusRepository: UserNotificationStatusRepository
  ) {
  }

  execute(params: NotificationID): Promise<void> {
    const profile = this.getCurrentUserProfileUseCase.execute();
    const status: UserNotificationStatus = new UserNotificationStatus(
      params,
      profile.profileID,
      true
    )
    return this.userNotificationStatusRepository.createStatus(status);
  }
}
