import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";
import {
  CategoryProductCreationManager
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {
  GetProductsByCategoryIDRequest
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryID/get-products-by-category-i-d-request";
import {Products} from "@modules/product/product/Domain/Product/products";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";

@Injectable({
  providedIn: 'root'
})
export class GetProductsByCategoryIDQuery implements UseCase<GetProductsByCategoryIDRequest, Promise<Products>> {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly categoryRepository: CategoryRepository,
    private readonly getProfileUseCase: GetCurrentUserProfileQuery,
    private readonly productFactory: CategoryProductCreationManager
  ) {
  }

  async execute<T extends Products>(request: GetProductsByCategoryIDRequest): Promise<T> {
    if (!request.businessUnitID) {
      const profile = this.getProfileUseCase.execute();
      request.businessUnitID = profile.businessUnitID;
    }

    const [category, products] = await Promise.all([
      this.categoryRepository.getByID(request.categoryID),
      this.productRepository.getAllByCategoryID(
        request.categoryID,
        request.businessUnitID
      )
    ])

    if (!category) {
      throw new Error('Category not found');
    }

    return this.productFactory.createProducts<T>(products, category.name);
  }
}
