@if (netEnabled) {
  <ion-item [formGroup]="form">
    <ion-input
      appClearZero
      errorText="Linear Feet(N&B) is required - enter a value or 0"
      formControlName="linearFeet"
      inputmode="numeric"
      label="Linear Feet(N&B)"
      label-placement="floating"
      placeholder="Linear Feet(N&B)"
      required="true"
      type="number"
    ></ion-input>
  </ion-item>
  <app-products-selector
    [form]="form"
    [categoryNames]="netCategoryNames"
    [formKey]="'netProduct'"
    (productSelected$)="netProductSelected($event)"
    label="Select Net Product"
  ></app-products-selector>
}

@if (isTrainingTemplateMode) {
  <app-blow-in-product [form]="form"></app-blow-in-product>
} @else {
  <app-products-selector
    [form]="form"
    [categoryNames]="blowInInsulationCategoryNames"
    [formKey]="'product'"
    (productSelected$)="blowInProductSelected($event)"
    label="Select Blow In Product"
  ></app-products-selector>
}

