@if (isTrainingTemplateMode) {
  <app-foam-product-training-selector [form]="form"></app-foam-product-training-selector>
} @else {
  <app-foam-depth-selector [form]="form"></app-foam-depth-selector>
  <app-products-selector
    [form]="form"
    [categoryNames]="foamCategoryNames"
    [formKey]="'product'"
    (productSelected$)="foamProductSelected($event)"
  ></app-products-selector>
}
