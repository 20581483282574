import {Component, signal} from '@angular/core';
import {NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from "@angular/router";
import {Platform} from "@ionic/angular";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {addIcons} from "ionicons";
import {
  add,
  albumsOutline,
  calculatorOutline,
  chatbubbleEllipses,
  chevronDownOutline,
  chevronExpandOutline,
  documentOutline,
  downloadOutline,
  ellipsisVerticalOutline,
  helpOutline,
  informationCircleOutline,
  listOutline,
  location,
  lockClosedOutline,
  optionsOutline,
  peopleOutline,
  pricetagsOutline,
  readerOutline,
  refreshCircleOutline,
  settingsOutline,
  trash
} from "ionicons/icons";


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: false
})
export class AppComponent {
  protected readonly enableAnimation: boolean;
  protected readonly isLoadingRouteConfig = signal<boolean>(false);

  constructor(
    private readonly router: Router,
    private readonly getProfile: GetCurrentUserProfileQuery,
    platform: Platform
  ) {
    if (environment.environment === EnvironmentType.production && window.location.hostname.includes('icalc.horsepowerbrands.com')) {
      this.initGtag()
    }

    this.initLoadingEvents();

    this.enableAnimation = !platform.is('desktop');

    addIcons({
      settingsOutline,
      optionsOutline,
      peopleOutline,
      pricetagsOutline,
      chatbubbleEllipses,
      helpOutline,
      chevronDownOutline,
      ellipsisVerticalOutline,
      listOutline,
      calculatorOutline,
      add,
      documentOutline,
      downloadOutline,
      chevronExpandOutline,
      informationCircleOutline,
      readerOutline,
      lockClosedOutline,
      trash,
      albumsOutline,
      refreshCircleOutline
    });
  }

  initGtag(): void {
    const profile = this.getProfile.execute();
    const userID = profile.profileID.toString();
    const gtag = window.gtag as (...args: unknown[]) => void;
    if (!gtag) {
      console.error('Gtag is not defined');
      return;
    }

    gtag('config', 'G-K7CF6HMDSP', {
      user_id: userID
    });

    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('event', 'page_view', {
            page_title: this.router.routerState.snapshot.url,
            page_path: event.urlAfterRedirects,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            page_location: location.origin
          })
        }
      });
  }

  private initLoadingEvents() {
    this.router.events.subscribe((ev) => {
      if (ev instanceof RouteConfigLoadStart) {
        this.isLoadingRouteConfig.set(true);
      } else if (ev instanceof RouteConfigLoadEnd) {
        this.isLoadingRouteConfig.set(false);
      }
    });
  }
}
