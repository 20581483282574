import {Inject, Injectable} from '@angular/core';
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {ProductNotFoundException} from "@modules/product/product/Domain/Product/Exception/product-not-found-exception";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {
  DynamicsCreateProductMapperService
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Mapper/dynamics-create-product-mapper.service";
import {
  DynamicsUpdateProductMapperService
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Mapper/dynamics-update-product-mapper.service";
import {
  DynamicsUpdateProductPriceMapperService
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Mapper/dynamics-update-product-price-mapper.service";
import {
  ProductFactory
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Mapper/product-factory.service";
import {
  DynamicsRetrieveProduct
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Model/dynamics-retrieve-product";
import {
  CreateRequest,
  DeleteRequest,
  DynamicsWebApi,
  RetrieveMultipleRequest,
  RetrieveRequest,
  UpdateRequest
} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class DynamicsProductRepository extends ProductRepository {
  private readonly tableName = 'cr9b4_products';
  private readonly categoryRelationName = 'cr9b4_CategoryID';

  constructor(
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
    private readonly dynamicsDeleteCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsProductFactory: ProductFactory,
    private readonly createProductMapper: DynamicsCreateProductMapperService,
    private readonly updateProductMapper: DynamicsUpdateProductMapperService,
    private readonly updatePriceMapper: DynamicsUpdateProductPriceMapperService,
    private readonly dynamicsWebApi: DynamicsWebApi,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
    super();
  }

  async getAllByCategoryID<T extends Products>(categoryID: CategoryID, businessUnitID: BusinessUnitID): Promise<T> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `
        _cr9b4_categoryid_value eq '${categoryID.getValue()}'
        and
        (_owningbusinessunit_value eq '${businessUnitID}' or _owningbusinessunit_value eq '${this.rootBusinessUnit}')
      `,
      expand: [
        {property: 'cr9b4_OriginalProductID'},
      ]
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsRetrieveProduct>(request);
    const products = await Promise.all(
      response.value.map(product => this.dynamicsProductFactory.mapFrom(product))
    );
    return new Products(products) as T;
  }

  /**
   * Retrieves all products for a given category and
   * given business unit as well as root (corporate one) business unit
   *
   * @param category
   * @param businessUnitID
   */
  async getAllByCategoryName<T extends Products>(category: CategoryName, businessUnitID: BusinessUnitID): Promise<T> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `
        ${this.categoryRelationName}/cr9b4_name eq '${category}'
        and
        (_owningbusinessunit_value eq '${businessUnitID}' or _owningbusinessunit_value eq '${this.rootBusinessUnit}')
      `,
      expand: [
        {property: 'cr9b4_OriginalProductID'},
      ]
    };


    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsRetrieveProduct>(request);
    const products = await Promise.all(
      response.value.map(product => this.dynamicsProductFactory.mapFrom(product))
    );
    return new Products(products) as T;
  }

  async getOne<T extends Product>(productID: ProductID): Promise<T> {
    const request: RetrieveRequest = {
      collection: this.tableName,
      key: productID.getValue(),
      expand: [
        {property: 'cr9b4_OriginalProductID'},
      ]
    };

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsRetrieveProduct>(request);
    const product = await this.dynamicsProductFactory.mapFrom<T>(response);
    return product as T;
  }

  async create<T extends Product>(product: T): Promise<T> {
    const request: CreateRequest = {
      collection: this.tableName,
      data: await this.createProductMapper.execute(product),
      returnRepresentation: true
    };

    const response = await this.dynamicsCreateCommand.execute(request);
    return await this.dynamicsProductFactory.mapFrom<T>(response);
  }

  async update<T extends Product>(product: Product): Promise<T> {
    const request: UpdateRequest = {
      collection: this.tableName,
      key: product.id.getValue(),
      data: this.updateProductMapper.execute(product),
      returnRepresentation: true
    };

    const response = await this.dynamicsUpdateCommand.execute(request);
    return await this.dynamicsProductFactory.mapFrom<T>(response);
  }

  async updatePublicFields<T extends Product>(product: Product): Promise<T> {
    const request: UpdateRequest = {
      collection: this.tableName,
      key: product.id.getValue(),
      data: await this.updatePriceMapper.execute(product),
      returnRepresentation: true
    };

    const response = await this.dynamicsUpdateCommand.execute(request);
    return await this.dynamicsProductFactory.mapFrom<T>(response);
  }

  async findZeeProduct<T extends Product>(corporateProductID: ProductID, businessUnitID: BusinessUnitID): Promise<T> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `
        _cr9b4_originalproductid_value eq '${corporateProductID.getValue()}'
        and
        (_owningbusinessunit_value eq '${businessUnitID}' or _owningbusinessunit_value eq '${this.rootBusinessUnit}')
      `,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsRetrieveProduct>(request);
    if (response.value.length === 0) {
      throw new ProductNotFoundException(corporateProductID);
    }
    const product = await this.dynamicsProductFactory.mapFrom<T>(response.value[0]);
    return product as T;
  }

  async clearBusinessUnitProducts(businessUnitID: BusinessUnitID): Promise<void> {
    const productIDRequest: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `_owningbusinessunit_value eq '${businessUnitID}'`
    }

    const products = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsRetrieveProduct>(productIDRequest);
    const productIDs = new Set<string>(products.value.map(product => product.cr9b4_productid));

    if (productIDs.size === 0) {
      return;
    }

    this.dynamicsWebApi.startBatch();
    productIDs.forEach(productID => {
      const request: DeleteRequest = {
        collection: this.tableName,
        key: productID,
      };
      this.dynamicsDeleteCommand.execute(request);
    });
    await this.dynamicsWebApi.executeBatch();
  }

  delete(productID: ProductID): Promise<void> {
    const request: DeleteRequest = {
      collection: this.tableName,
      key: productID.getValue(),
    };
    return this.dynamicsDeleteCommand.execute(request);
  }
}
