import {Injectable} from '@angular/core';
import {
  RemovalCalculationStrategy
} from "@modules/calculation-impl/removal-calculator/Domain/Calculation/removal-calculation-strategy";
import {
  RemovalAreaFactory
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationArea/removal-area-factory";
import {
  RemovalAreaTotalFactory
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationAreaTotal/removal-area-total-factory";
import {
  RemovalAreaComponent
} from "@modules/calculation-impl/removal-calculator/Presentation/removal-area/removal-area.component";
import {
  RemovalTotalComponent
} from "@modules/calculation-impl/removal-calculator/Presentation/removal-total/removal-total.component";
import {REMOVAL_CALCULATION_ID} from "@modules/calculation-impl/removal-calculator/removal-calculator.module";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GetProductQuery} from "@modules/product/product/Application/UseCase/Query/GetProduct/get-product-query.service";
import {
  GetProductsQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-query.service";

@Injectable({
  providedIn: 'root'
})
export class RemovalCalculationStrategyFactory {
  constructor(
    private readonly areaFactory: RemovalAreaFactory,
    private readonly productsByCategoryQuery: GetProductsQuery,
    private readonly getProductQuery: GetProductQuery,
    private readonly calculationTotalFactory: CalculationTotalFactory,
    private readonly areaTotalFactory: RemovalAreaTotalFactory
  ) {
  }

  execute() {
    return new RemovalCalculationStrategy(
      REMOVAL_CALCULATION_ID,
      'Removal Template',
      this.productsByCategoryQuery,
      this.getProductQuery,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      RemovalAreaComponent,
      RemovalTotalComponent,
      this.calculationTotalFactory,
      this.areaFactory,
      this.areaTotalFactory
    );
  }
}
