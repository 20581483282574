<form [formGroup]="form">
  <ion-list lines="none">

    <app-blow-in-universal-product-selector [form]="form"></app-blow-in-universal-product-selector>
    <app-width-length [form]="form"></app-width-length>
    
    <app-labor-form [form]="form"></app-labor-form>
    <app-area-misc-input [form]="form" miscControlName="misc"></app-area-misc-input>
  </ion-list>
</form>


