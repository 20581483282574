import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputsID} from "@modules/gm-inputs/Domain/GmInputs/VO/gm-inputs-id";
import {OtherRates} from "../Other/other-rates";

export class GmInputs {

  constructor(
    public readonly id: GmInputsID,
    public readonly businessUnitID: BusinessUnitID,
    public readonly pinCode: string,
    private crews: Crews,
    private otherRates: OtherRates
  ) {
  }

  getCrews(): Crews {
    return this.crews;
  }

  getCrewNames(crewIds: CrewID[]): string[] {
    return this.crews.getCrewsUsed(crewIds).map(crew => crew.name);
  }

  addBenefits(count: number): number {
    return count * (1 + this.getBenefitsRatePercentage());
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getBenefitsRatePercentage(): number {
    return this.otherRates.benefitsRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getSalesTaxRatePercentage(): number {
    return this.otherRates.salesTaxRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getRoyaltyRatePercentage(): number {
    return this.otherRates.royaltyRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getCommissionRatePercentage(): number {
    return this.otherRates.commissionRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getCCPercent(): number {
    return this.otherRates.ccPercent.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */

  setOtherRates(otherRates: OtherRates): GmInputs {
    return new GmInputs(this.id, this.businessUnitID, this.pinCode, this.crews, otherRates);
  }

  setCrews(crews: Crews): GmInputs {
    return new GmInputs(this.id, this.businessUnitID, this.pinCode, crews, this.otherRates);
  }

  getCrewFullyLoadedRate(crew: Crew): number {
    return crew.rate * (1 + this.getBenefitsRatePercentage());
  }


  checkPin(pin: string) {
    return this.pinCode === pin;
  }

  getRecommendedMargin(): number {
    return this.otherRates.recommendedMargin.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getAllFees(): number {
    return this.getRoyaltyRatePercentage()
      + this.getCommissionRatePercentage()
      + this.getCCPercent()
  }
}
