import {Injectable} from "@angular/core";
import {MissingRequiredFieldsException} from "@modules/_shared/Service/Validator/missing-required-fields.exception";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyNotFound
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Exception/calculation-strategy-not-found";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CalculationFactory} from "@modules/calculation/Application/Factory/calculation-factory.service";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationOptions} from "@modules/calculation/Domain/Calculation/calculation-options";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {RetrieveMultipleResponse, RetrieveRequest} from "dynamics-web-api";
import {DynamicsEstimateCalculation} from "../Type/dynamics-estimate-calculation";

@Injectable({
  providedIn: 'root'
})
export class DynamicsCalculationFactory {
  private readonly requiredFields: (keyof DynamicsEstimateCalculation)[] = [
    "cr9b4_calculationtype",
    "cr9b4_ifoamestimatecalculationid",
    "_cr9b4_estimateid_value"
  ];

  private readonly estimateRelationName = "cr9b4_EstimateID";
  private readonly estimateTableName = "cr9b4_ifoamestimates";


  constructor(
    private readonly requiredValidator: RequiredFieldsValidator,
    private readonly estimateCalculationFactory: CalculationFactory,
    private readonly dynamicsRetrieveCommand: DynamicsRetrieveQuery,
  ) {
  }

  getSelectFields(): (keyof DynamicsEstimateCalculation)[] {
    return [
      'cr9b4_ifoamestimatecalculationid',
      'cr9b4_calculationtype',
      '_cr9b4_estimateid_value',
      "_owningbusinessunit_value"
    ]
  }

  createCalculation<TStrategy extends CalculationStrategy>(response: DynamicsEstimateCalculation): Promise<Calculation<TStrategy>> {
    this.requiredValidator.validate(response, this.requiredFields);
    const estimateCalculationOption = this.createEstimateCalculationOption(response);
    return this.estimateCalculationFactory.create<TStrategy>(estimateCalculationOption);
  }

  async createCalculations(response: RetrieveMultipleResponse<DynamicsEstimateCalculation>): Promise<Calculation[]> {
    const estimateCalculations: Calculation[] = [];
    for (const estimateCalculationResponse of response.value) {
      try {
        this.requiredValidator.validate(estimateCalculationResponse, this.requiredFields);
        const estimateCalculation = await this.createCalculation(estimateCalculationResponse);
        estimateCalculations.push(estimateCalculation);
      } catch (e: unknown | MissingRequiredFieldsException) {
        if (e instanceof CalculationStrategyNotFound) {
          console.error(e.message);
          continue;
        }

        if (e instanceof MissingRequiredFieldsException) {
          console.error(`Calculation with id ${estimateCalculationResponse._cr9b4_estimateid_value}: ${e.message}`);
        } else {
          throw e;
        }
      }
    }

    response.value.map(c => this.createEstimateCalculationOption(c));
    return estimateCalculations;
  }

  async createDynamicsEstimateCalculation(
    estimateID: string,
    strategyID: string,
  ): Promise<Partial<DynamicsEstimateCalculation>> {
    // Create a relationship between the calculation and the estimate
    const estimateAssociateKey = `${this.estimateRelationName}@odata.bind`;
    const estimateAssociateValue = `/${this.estimateTableName}(${estimateID})`;

    const businessUnitID = await this.getBusinessUnitIDFromEstimate(estimateID);
    // Create a relationship between the calculation and the business unit
    const businessUnitKey = "owningbusinessunit@odata.bind";
    const businessUnitValue = `/businessunits(${businessUnitID})`;

    return {
      cr9b4_calculationtype: strategyID,
      [estimateAssociateKey]: estimateAssociateValue,
      [businessUnitKey]: businessUnitValue,
    }
  }

  async getBusinessUnitIDFromEstimate(estimateID: string): Promise<string> {
    const estimateRetrieveRequest: RetrieveRequest = {
      collection: this.estimateTableName,
      key: estimateID,
      select: ["_owningbusinessunit_value"],
    };

    const estimateResponse = await this.dynamicsRetrieveCommand.execute(estimateRetrieveRequest);
    if (!estimateResponse["_owningbusinessunit_value"]) {
      throw new Error(`Business unit not found for estimate ID ${estimateID}`);
    }

    return estimateResponse["_owningbusinessunit_value"];
  }

  private createEstimateCalculationOption(estimateCalculation: DynamicsEstimateCalculation): CalculationOptions {
    return {
      id: new CalculationID(estimateCalculation.cr9b4_ifoamestimatecalculationid),
      strategyID: new CalculationStrategyID(estimateCalculation.cr9b4_calculationtype),
      estimateID: new EstimateID(estimateCalculation._cr9b4_estimateid_value),
      businessUnitID: new BusinessUnitID(estimateCalculation._owningbusinessunit_value),
    };
  }
}
