import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {GetDepthsUseCase} from "@modules/calculation-impl/foam/depth/Application/UseCase/get-depths-use-case";
import {Depth} from "@modules/calculation-impl/foam/depth/Domain/depth";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {
  EnergyStarRecommendationService
} from "@modules/energy-star-recommendation/Presentation/Service/energy-star-recommendation.service";

@Component({
  selector: 'app-foam-depth-selector',
  templateUrl: './foam-depth-selector.component.html',
  styleUrls: ['./foam-depth-selector.component.scss'],
})
export class FoamDepthSelectorComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  protected availableDepths: Depth[] = [];
  private allDepths: Depth[] = [];

  constructor(
    private readonly getDepthsUseCase: GetDepthsUseCase,
    private readonly energyStarRecommendationService: EnergyStarRecommendationService,
  ) {
  }

  async ngOnInit() {
    this.allDepths = await this.getDepthsUseCase.execute();

    this.displayAvailableDepths(this.form.get('service')?.value);
    this.form.get('service')?.valueChanges.subscribe((service) => {
      this.displayAvailableDepths(service);
    });
  }

  async openEnergyRecommendationModal() {
    await this.energyStarRecommendationService.openModal();
  }

  private displayAvailableDepths(service: string) {
    if (service === FoamService.intumescentCoating) {
      this.availableDepths = [new Depth(1)];
      this.form.get('depth')?.setValue(1, {emitEvent: false});
      this.form.get('depth')?.disable({emitEvent: false});
      return;
    }

    this.form.get('depth')?.enable({emitEvent: false});
    this.availableDepths = this.allDepths;
  }

}
