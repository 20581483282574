import {
  RemovalAreaFactory
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationArea/removal-area-factory";
import {
  RemovalAreaTotal
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationAreaTotal/removal-area-total";
import {
  RemovalAreaTotalFactory
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationAreaTotal/removal-area-total-factory";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {RemovalArea} from "../CalculationArea/removal-area";

export class RemovalCalculationStrategy extends CalculationStrategy<
  RemovalArea,
  RemovalAreaTotal,
  RemovalAreaFactory,
  RemovalAreaTotalFactory
> {
  async calculateTotal(input: RemovalArea[], gmInputs: GmInputs): Promise<CalculationTotal> {
    let total: CalculationTotalOptions = {
      materialCost: 0,
      materialSalesTax: 0,
      laborHours: 0,
      laborCost: 0,
      misc: 0,
      totalCost: 0
    };

    for (const model of input) {
      const areaTotal = await this.calculateArea(model, gmInputs);
      total = {
        laborHours: total.laborHours + model.getLabourHours(),
        laborCost: total.laborCost + areaTotal.laborCost,
        totalCost: total.totalCost + areaTotal.laborCost + model.miscellaneous,
        materialCost: 0,
        materialSalesTax: 0,
        misc: total.misc + model.miscellaneous
      };
    }

    return this.totalFactory.create(total);
  }

  async calculateArea(input: RemovalArea, gmInputs: GmInputs): Promise<RemovalAreaTotal> {
    const laborExpense = input.getLaborExpense(gmInputs);

    return new RemovalAreaTotal(
      input.getLabourHours(),
      input.sqft,
      laborExpense,
      input.miscellaneous
    );
  }
}
