@if (form) {
  <form [formGroup]="form">
    <ion-list lines="none">
      <app-width-length [form]="form"></app-width-length>
      <app-batting-area-universal-product-selector [form]="form"></app-batting-area-universal-product-selector>

      <app-labor-form [form]="form"></app-labor-form>
      <app-area-misc-input [form]="form"></app-area-misc-input>
    </ion-list>
    @if (form.invalid) {
      <ion-list>
        <ion-item>
          <ion-label>
            Please fix the following fields before you are able to see Job Cost:
            <ion-note color="danger">{{ getFormErrors().join(', ') }}</ion-note>
          </ion-label>
        </ion-item>
      </ion-list>
    }
  </form>
}
