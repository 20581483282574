import {Injectable} from "@angular/core";
import {
  UserNotificationStatus
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/user-notification-status";
import {
  DynamicsUserNotificationStatus
} from "@modules/area-context-notifications/Infrasturcutre/Repository/UserNotificationStatus/Model/dynamics-user-notification-status";
import {ProfileID} from "@modules/profile/Domain/Profile/VA/profile-id";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

@Injectable({providedIn: 'root'})
export class DynamicsUserNotificationStatusMapper {
  mapFrom(param: DynamicsUserNotificationStatus): UserNotificationStatus {
    return new UserNotificationStatus(
      new NotificationID(param.cr9b4_name),
      new ProfileID(param._createdby_value),
      Boolean(param.cr9b4_notificationdisabled)
    )
  }

  mapTo(param: UserNotificationStatus): Partial<DynamicsUserNotificationStatus> {
    return {
      cr9b4_name: param.notificationID.getValue(),
      cr9b4_notificationdisabled: param.disabled
    }
  }
}
