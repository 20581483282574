import {Injectable} from '@angular/core';
import {
  MiscCalculationStrategy
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/Calculation/misc-calculation-strategy";
import {
  MiscAreaFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-factory";
import {
  MiscAreaTotalFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total-factory";
import {MISC_CALCULATION_ID} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module";
import {
  MiscCalculationComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/misc-calculation-area/misc-calculation.component";
import {
  MiscTotalComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/misc-total/misc-total.component";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GetProductQuery} from "@modules/product/product/Application/UseCase/Query/GetProduct/get-product-query.service";
import {
  GetProductsQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-query.service";

@Injectable({
  providedIn: 'root'
})
export class MiscCalculationStrategyFactory {
  constructor(
    private readonly areaFactory: MiscAreaFactory,
    private readonly productsByCategoryQuery: GetProductsQuery,
    private readonly getProductQuery: GetProductQuery,
    private readonly calculationTotalFactory: CalculationTotalFactory,
    private readonly areaTotalFactory: MiscAreaTotalFactory
  ) {
  }

  execute(): MiscCalculationStrategy {
    return new MiscCalculationStrategy(
      MISC_CALCULATION_ID,
      'Miscellaneous Template',
      this.productsByCategoryQuery,
      this.getProductQuery,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      MiscCalculationComponent,
      MiscTotalComponent,
      this.calculationTotalFactory,
      this.areaFactory,
      this.areaTotalFactory
    );
  }
}
