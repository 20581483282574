import {Component, Input, OnInit} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule, ModalController} from "@ionic/angular";
import {
  OptOutNotificationCommand
} from "@modules/area-context-notifications/Application/UseCase/Command/OptOutNotification/opt-out-notification-command.service";
import {
  RecordShownContextNotificationCommand
} from "@modules/area-context-notifications/Application/UseCase/Command/record-shown-context-notification-command.service";
import {
  RecordShownCalculationNotificationCommandHandler
} from "@modules/area-context-notifications/Application/UseCase/Command/RecordShownCalculationNotificationCommand/record-shown-calculation-notification-command.service";
import {
  IsOptOutButtonShouldBeShownQuery
} from "@modules/area-context-notifications/Application/UseCase/Query/IsOptOutButtonShouldBeShown/is-opt-out-button-should-be-shown-query.service";
import {NotificationAction} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-edit-estimate',
  templateUrl: './modal-notification-action.component.html',
  styleUrls: ['./modal-notification-action.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ReactiveFormsModule
  ]
})
export class ModalNotificationActionComponent implements OnInit {
  @Input() notificationAction?: NotificationAction
  @Input() notificationID?: NotificationID
  @Input() calculationID?: CalculationID;

  protected message = ''
  protected actionable = false;
  protected showOptOut = false;
  private followUpDisplayed = false;
  private cancelDisplayed = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly optOutNotificationCommand: OptOutNotificationCommand,
    private readonly isOptOutButtonShouldBeShownQuery: IsOptOutButtonShouldBeShownQuery,
    private readonly recordShownContextNotificationCommand: RecordShownContextNotificationCommand,
    private readonly recordShownCalculationNotificationCommand: RecordShownCalculationNotificationCommandHandler,
  ) {
  }

  async ngOnInit() {
    this.setupMessage();
    this.setupOptOutButton();

    if (!this.notificationID) return;
    this.recordShownContextNotificationCommand.execute(this.notificationID).then();


    if (this.calculationID) {
      const request = {
        calculationID: this.calculationID,
        notificationID: this.notificationID
      }
      this.recordShownCalculationNotificationCommand.execute(request).then();
    }
  }

  async confirm(): Promise<void> {
    if (!this.notificationAction) throw new Error('notificationAction is required');

    if (!this.notificationAction.followUpMessage || this.followUpDisplayed) {
      await this.modalCtrl.dismiss(null, 'confirm');
    }

    if (this.notificationAction.followUpMessage) {
      this.message = this.notificationAction.followUpMessage;
      this.followUpDisplayed = true;
      this.actionable = false;
    }

  }

  async cancel(): Promise<void> {
    if (!this.notificationAction) throw new Error('notificationAction is required');

    if (!this.notificationAction.cancelMessage || this.cancelDisplayed) {
      await this.modalCtrl.dismiss(null, 'cancel');
    }

    if (this.notificationAction.cancelMessage) {
      this.message = this.notificationAction.cancelMessage;
      this.cancelDisplayed = true;
      this.actionable = false;
    }
  }

  optOut(): Promise<boolean> {
    if (!this.notificationID) throw new Error('notificationAction is required');

    this.optOutNotificationCommand.execute(this.notificationID).then(r => r);
    return this.modalCtrl.dismiss(null, 'opt-out');
  }

  private setupMessage(): void {
    if (!this.notificationAction) throw new Error('notificationAction is required');

    this.message = this.notificationAction.message;
    if (this.notificationAction.followUpMessage || this.notificationAction.cancelMessage) {
      this.actionable = true;
    }
  }

  private setupOptOutButton(): void {
    if (!this.notificationID) throw new Error('notificationID is required');

    this.isOptOutButtonShouldBeShownQuery.execute(this.notificationID).then(result => {
      this.showOptOut = result;
    })
  }
}
