import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UserConfigProvider} from "@modules/_shared/Service/UserConfig/user-config-provider.service";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {Product} from "@modules/product/product/Domain/Product/product";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {FoamProduct} from "@modules/product/products/foam-product/Domain/Product/foam-product";

@Component({
  selector: 'app-foam-universal-product-selector',
  templateUrl: './foam-universal-product-selector.component.html',
  styleUrls: ['./foam-universal-product-selector.component.scss'],
})
export class FoamUniversalProductSelectorComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  public isTrainingTemplateMode?: boolean;
  foamCategoryNames: CategoryName[] = [new FoamCategoryName()];

  constructor(
    private readonly userConfigProvider: UserConfigProvider
  ) {
  }

  ngOnInit() {
    this.userConfigProvider.userConfig$.subscribe(userConfig => {
      if (!userConfig) return;
      this.isTrainingTemplateMode = userConfig.isTrainingTemplateMode();
    })
  }

  foamProductSelected(product?: Product) {
    if (!product) return;

    const foamProduct = product as FoamProduct;
    this.form.get('service')?.setValue(foamProduct.service.value);
  }
}
