import {Injectable} from '@angular/core';
import {Mapper} from "@modules/_shared/Domain/mapper";
import {
  FieldsConfigSerializer
} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/Factory/fields-config-serializer.service";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {
  CategoryFieldsConfigProvider
} from "@modules/product/product-category/Domain/Category/Provider/category-fields-config-provider";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  DynamicsCategory
} from "@modules/product/product-category/Infrastructure/Repository/DynamicsCategoryRepository/Model/dynamics-category";

@Injectable({
  providedIn: 'root'
})
export class DynamicsCategoryMapper implements Mapper<DynamicsCategory, Category> {
  constructor(
    private readonly categoryFieldsConfigProvider: CategoryFieldsConfigProvider,
    private readonly fieldsConfigJsonFactory: FieldsConfigSerializer
  ) {
  }

  mapFrom(param: DynamicsCategory): Category {
    const categoryName = new CategoryName(param.cr9b4_name);
    const fieldsConfig = this.categoryFieldsConfigProvider.execute(categoryName, param.cr9b4_fieldsconfig);

    return new Category(
      new CategoryID(param.cr9b4_categoryid),
      categoryName,
      param.cr9b4_title,
      param.cr9b4_description,
      fieldsConfig
    );
  }

  mapTo(param: Category): DynamicsCategory {
    return {
      cr9b4_categoryid: param.id.toString(),
      cr9b4_fieldsconfig: this.fieldsConfigJsonFactory.serialize(param.fieldsConfig),
      cr9b4_name: param.name.toString(),
      cr9b4_title: param.title,
      cr9b4_description: param.description
    };
  }
}
