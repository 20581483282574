import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  GetZeeBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-corporate-business-units-use-case.service";

interface Response {
  filter: string,
  queryParams: string[]
}

@Injectable({
  providedIn: 'root'
})
export class GetDynamicsZeesBusinessUnitFilterService implements UseCase<void, Response> {

  constructor(
    private readonly getZeeBusinessUnitsUseCase: GetZeeBusinessUnitsUseCase
  ) {
  }

  execute(): Response {
    const zeeUnits = this.getZeeBusinessUnitsUseCase.execute();
    const zeeIDs = zeeUnits.getIDs().map(id => id.toString());

    const filter = zeeIDs.map((id, index) => `_owningbusinessunit_value eq @p${index}`).join(' or ');
    const queryParams = zeeIDs.map((id, index) => `@p${index}=${id}`);

    return {
      filter,
      queryParams
    }
  }
}
