import {Injectable} from '@angular/core';
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {
  CategoryFieldsConfigProvider
} from "@modules/product/product-category/Domain/Category/Provider/category-fields-config-provider";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";
import {
  BattingInsulationCategoryFieldsConfig
} from "@modules/product/products/batting-insulation-product/Domain/Field/batting-insulation-category-fields-config";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";
import {
  BattingMineralWoolCategoryFieldsConfig
} from "@modules/product/products/batting-mineral-wool-product/Domain/Field/batting-mineral-wool-category-fields-config";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";
import {
  BlowInBlowCategoryFieldsConfig
} from "@modules/product/products/blow-in-blow-product/Domain/Field/blow-in-blow-category-fields-config";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";
import {
  BlowInNetCategoryFieldsConfig
} from "@modules/product/products/blow-in-net-product/Domain/Field/blow-in-net-category-fields-config";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {
  FoamCategoryFieldsConfig
} from "@modules/product/products/foam-product/Domain/Field/foam-category-fields-config";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";
import {
  MiscCategoryFieldsConfig
} from "@modules/product/products/misc-product/Domain/Field/misc-category-fields-config";

@Injectable({
  providedIn: 'root'
})
export class CodeCategoryFieldsConfigProvider extends CategoryFieldsConfigProvider {

  private readonly availableCategories: { name: CategoryName, config: FieldsConfig }[] = [
    {name: new BattingInsulationCategoryName, config: new BattingInsulationCategoryFieldsConfig},
    {name: new BattingMineralWoolCategoryName, config: new BattingMineralWoolCategoryFieldsConfig},
    {name: new BlowInInsulationCategoryName, config: new BlowInBlowCategoryFieldsConfig},
    {name: new BlowInNetCategoryName, config: new BlowInNetCategoryFieldsConfig},
    {name: new FoamCategoryName, config: new FoamCategoryFieldsConfig},
    {name: new MiscCategoryName, config: new MiscCategoryFieldsConfig}
  ];

  constructor() {
    super();
  }

  execute(categoryName: CategoryName): FieldsConfig {
    const category = this.availableCategories.find(
      option => option.name.equals(categoryName)
    );

    if (!category) {
      throw new Error(`Category ${categoryName} not found`);
    }
    return category.config;
  }
}
