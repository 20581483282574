import {Injectable} from "@angular/core";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {
  UpdateCalculatorTabUseCase
} from "@modules/calculation-tabs/Application/UseCase/update-calculator-tab/update-calculator-tab.service";
import {CalculatorTab} from "@modules/calculation-tabs/Domain/CalculatorTab/calculator-tab";

@Injectable({
  providedIn: 'root'
})
export class UpdateCalculationAreaUseCase {
  constructor(
    private readonly updateCalculatorTabUseCase: UpdateCalculatorTabUseCase
  ) {
  }

  async execute(areaID: string, areaState: CalculationArea): Promise<void> {
    if (!areaID) throw new Error('Area ID is required');
    if (!areaState) throw new Error('Area State is required');
    
    const tabUpdate: Partial<CalculatorTab> = {
      id: areaID,
      state: areaState,
    };

    try {
      await this.updateCalculatorTabUseCase.execute(tabUpdate);
    } catch (error) {
      const errorMessage = `Failed to update area state: ${error instanceof Error ? error.message : 'unknown error'}`;
      throw new Error(errorMessage);
    }
  }
}
