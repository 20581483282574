<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        [defaultHref]="this.explicitBackUrl ? this.explicitBackUrl.join('/') : '/'"
        data-cy="header-back-button"
        text="Back"
      ></ion-back-button>
    </ion-buttons>


    @if (title) {
      <ion-title (click)="titleClickTrack()" class="ion-text-center title" data-cy="header-title">
        {{ title }}
        @if (titleIcon) {
          <ion-icon [name]=titleIcon></ion-icon>
        }
        @if (titleInfo) {
          <ion-icon name="information-circle-outline" id="header-info-icon-click-trigger" class="link"></ion-icon>
          <ion-popover trigger="header-info-icon-click-trigger" side="bottom" alignment="center" triggerAction="click">
            <ng-template>
              <ion-content class="ion-padding">{{ titleInfo }}</ion-content>
            </ng-template>
          </ion-popover>
        }
      </ion-title>
    }

    @if (titleSegments) {
      <ion-segment
        (ionChange)="segmentChange($event)"
        [value]="segmentActive"
        data-cy="header-segment"
      >
        @for (segment of titleSegments; track segment; let i = $index) {
          <ion-segment-button [value]="i">
            <ion-label>{{ segment.title }}</ion-label>
          </ion-segment-button>
        }
      </ion-segment>
    }

    <ion-buttons class="right-buttons" slot="end">
      @if (contextMenuItems) {
        <app-tree-dot-menu
          [menuEntries]="contextMenuItems"
          data-cy="header-3dot"
        ></app-tree-dot-menu>
      }

      @if (rightButton) {
        <ion-button (click)="rightButton.callback()" data-cy="header-right-button">
          {{ rightButton.title }}
          @if (rightButton.icon) {
            <ion-icon [name]="rightButton.icon"></ion-icon>
          }
        </ion-button>
      }

      @if (rightToggle) {
        <ion-toggle (click)="rightToggle.callback()" [enableOnOffLabels]="true"></ion-toggle>
      }
    </ion-buttons>
  </ion-toolbar>

  <ng-content></ng-content>
</ion-header>
