import {Injectable} from '@angular/core';
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {Labor, LaborFields} from "@modules/labor/Domain/labor";
import {LaborCrewMode} from "@modules/labor/Domain/labor-crew-mode";

//key value
type State = {
    [key: string]: unknown
}

type StateLabor = {
    mode: unknown,
    labourHours: unknown,
    laborCrew: unknown,
    flatRate: unknown,
}

type StateCrews = {
    laborCrew: unknown | number[];
    projHours: unknown | number;
}

@Injectable({
    providedIn: 'root'
})
export class LaborMapperService {
    createFromOldState(state: object): Labor {
        const stateCrew = state as StateCrews;

        const crewInState = stateCrew.laborCrew;
        const projectHours = Number(stateCrew.projHours || 0);

        const crews: CrewID[] = [];
        if (Array.isArray(crewInState)) {
            crewInState.map(
                (crewID) => crews.push(new CrewID(Number(crewID)))
            );
        }

        return new Labor(LaborCrewMode.crew(), Number(projectHours), crews);
    }

    fromState(state: unknown): Labor {
        if (typeof state !== 'object' || state === null) {
            return Labor.default();
        }

        const typedState = state as State;
        if (typedState['labor'] === undefined) {
            return this.createFromOldState(state);
        }

        const stateLabor = typedState['labor'] as StateLabor;
        return Labor.fromFields(stateLabor);
    }

    toStateField(state: CalculationArea): LaborFields {
        if (!state.labor) {
            return Labor.default().toFields();
        }
        if (!state.labor.toFields) {
            return Labor.default().toFields();
        }
        return state.labor.toFields();
    }
}
