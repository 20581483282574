import {Injectable} from '@angular/core';
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {DynamicFormFieldNumber} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-number";
import {DynamicFormFieldString} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-string";
import {PRODUCT_OVERAGE_FIELD, PRODUCT_PRICE_FIELD} from "@modules/product/product/Domain/Product/product";


/**
 * This service provides product's static fields as Dynamic Form Fields
 *
 * @see Product
 */
@Injectable({
  providedIn: 'root'
})
export class ProductStaticFieldsDFFProvider {

  constructor() {
  }

  execute(): DynamicFormFields {
    return new DynamicFormFields([
      new DynamicFormFieldString({
        key: 'name',
        value: undefined,
        required: true,
        label: 'Name',
        placeholder: 'Enter Name',
        errorMessage: 'Name is required'
      }),
      new DynamicFormFieldString({
        key: 'description',
        value: undefined,
        required: false,
        label: 'Description',
        placeholder: 'Enter Description',
        errorMessage: 'Description is required'
      }),
      new DynamicFormFieldString({
        key: 'manufacturer',
        value: undefined,
        required: true,
        label: 'Manufacturer',
        placeholder: 'Enter Manufacturer',
        errorMessage: 'Manufacturer is required'
      }),
      new DynamicFormFieldNumber({
        key: PRODUCT_PRICE_FIELD,
        value: undefined,
        required: true,
        label: 'Price',
        placeholder: 'Enter Price',
        errorMessage: 'Price is required'
      }),
      new DynamicFormFieldNumber({
        key: PRODUCT_OVERAGE_FIELD,
        value: undefined,
        required: true,
        label: 'Overage Percentage (1-100)',
        placeholder: 'Enter overage percentage',
        errorMessage: 'Overage percentage is required'
      })
    ]);
  }
}
