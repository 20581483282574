import {Injectable} from '@angular/core';
import {ManagerEmailsProvider} from "@modules/_shared/Service/UserIsManager/manager-emails.provider";
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";

@Injectable({
  providedIn: 'root'
})
export class UserIsManager {
  private readonly profileEmail: string;
  private isManager?: boolean;
  private managerEmailsList?: string[];

  constructor(
    private readonly getProfile: GetCurrentUserProfileQuery,
    private readonly managerEmailsProvider: ManagerEmailsProvider
  ) {
    this.profileEmail = this.getProfile.execute().email;
  }

  async execute(): Promise<boolean> {
    if (this.isManager) return this.isManager;

    if (!this.managerEmailsList) {
      this.managerEmailsList = await this.managerEmailsProvider.execute();
    }

    this.isManager = this.managerEmailsList.includes(this.profileEmail.toLowerCase());
    return this.isManager;
  }
}
