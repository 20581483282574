import {Inject, Injectable} from '@angular/core';
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {PercentRate} from "@modules/gm-inputs/Domain/Other/VO/percent-rate";
import {FieldFactory} from "@modules/product/product-category-fields/Domain/Field/Field/Factory/field-factory";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {Product, PRODUCT_OVERAGE_FIELD, PRODUCT_PRICE_FIELD} from "@modules/product/product/Domain/Product/product";
import {ProductBusinessOwnerID} from "@modules/product/product/Domain/Product/VO/product-business-owner-id";
import {ProductDescription} from "@modules/product/product/Domain/Product/VO/product-description";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductManufacturer} from "@modules/product/product/Domain/Product/VO/product-manufacturer";
import {ProductName} from "@modules/product/product/Domain/Product/VO/product-name";
import {ProductPrice} from "@modules/product/product/Domain/Product/VO/product-price";

@Injectable({
  providedIn: 'root'
})
export class ProductFromDffFactory {
  constructor(
    private readonly fieldFactory: FieldFactory,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
  }

  execute(
    productID: ProductID,
    category: Category,
    fields: DynamicFormFields,
    ownerBusinessUnitID: ProductBusinessOwnerID,
    originalProduct?: Product,
  ): Product {
    const params = this.getProductStaticFields(fields, productID);

    return new Product(
      productID,
      new ProductName(params.name),
      new ProductDescription(params.description),
      new ProductManufacturer(params.manufacturer),
      new ProductPrice(params.price),
      ownerBusinessUnitID,
      category,
      this.getCategoryFields(fields, category),
      new BusinessUnitID(this.rootBusinessUnit),
      new PercentRate(params.overagePercentage / 100),
      originalProduct
    );
  }

  private getProductStaticFields(fields: DynamicFormFields, productID: ProductID) {
    return {
      id: productID,
      name: fields.getValue<ControlTypes.String>('name'),
      description: fields.getValue<ControlTypes.String>('description'),
      manufacturer: fields.getValue<ControlTypes.String>('manufacturer'),
      price: fields.getValue<ControlTypes.Number>(PRODUCT_PRICE_FIELD),
      overagePercentage: fields.getValue<ControlTypes.Number>(PRODUCT_OVERAGE_FIELD),
    }
  }

  private getCategoryFields(fields: DynamicFormFields, category: Category): Fields {
    const productFields: Field[] = [];

    for (const categoryField of category.fieldsConfig.getFieldConfigs()) {
      const value = fields.getValue<ControlTypes.String>(categoryField.name.getValue());
      if (!value) throw new Error(`Field ${categoryField.name.getValue()} is required`);

      productFields.push(
        this.fieldFactory.execute(categoryField.type, value, categoryField.name.getValue())
      );
    }

    return new Fields(productFields);
  }
}
