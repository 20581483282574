import {Appointment} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/appointment";
import {Customer} from "@modules/service-titan/service-titan-customer/Domain/Customer/customer";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {Location} from "@modules/service-titan/service-titan-location/Domain/Location/location";

export interface JobOptions {
  id: JobID;
  jobStatus: string;
  summary: string | null;
  customerId: number;
  locationId: number;
  modifiedOn: Date;
  createdOn: Date;
  customer?: Customer;
  location?: Location;
  firstAppointmentId: number;
  firstAppointment?: Appointment;
}

export class Job {
  public readonly id: JobID;
  public readonly jobStatus: string;
  public readonly summary: string | null;
  public readonly customerID: number;
  public readonly locationID: number;
  public readonly createdOn: Date;
  public readonly firstAppointmentId: number;

  public customer?: Customer;
  public location?: Location;
  public firstAppointment?: Appointment;

  constructor(options: JobOptions) {
    if (!options.id) {
      throw new Error('Job ID is required');
    }

    if (!options.jobStatus) {
      throw new Error('Job status is required');
    }

    if (!options.modifiedOn) {
      throw new Error('Modified on is required');
    }

    if (!options.customerId) {
      throw new Error('Customer ID is required');
    }

    if (!options.locationId) {
      throw new Error('Location ID is required');
    }

    this.id = options.id;
    this.jobStatus = options.jobStatus;
    this.summary = options.summary;
    this.createdOn = options.createdOn;
    this.customerID = options.customerId;
    this.locationID = options.locationId;
    this.firstAppointmentId = options.firstAppointmentId;

    if (options.customer) {
      this.assignCustomer(options.customer);
    }

    if (options.location) {
      this.assignLocation(options.location);
    }

    if (options.firstAppointment) {
      this.assignFirstAppointment(options.firstAppointment);
    }
  }

  assignFirstAppointment(appointment: Appointment): void {
    if (!appointment.id.equalsValue(this.firstAppointmentId)) {
      throw new Error(`Appointment with ID ${appointment.id} does not match job's first appointment ID ${this.firstAppointmentId}`);
    }

    this.firstAppointment = appointment;
  }

  assignCustomer(customer: Customer): void {
    if (!customer.id.equalsValue(this.customerID)) {
      throw new Error(`Customer with ID ${customer.id} does not match job's customer ID ${this.customerID}`);
    }

    this.customer = customer;
  }

  assignLocation(location: Location): void {
    if (!location.id.equalsValue(this.locationID)) {
      throw new Error(`Location with ID ${location.id} does not match job's location ID ${this.locationID}`);
    }

    this.location = location;
  }

  getShortName(): string {
    const customerPart = this.customer ? this.customer.name : '';
    const jobPart = this.id;
    const addressPart = this.location ? this.location.address.getFirstLine() : '';

    return `[${jobPart}] ${customerPart} - ${addressPart}`;
  }
}
