import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {LaborCrewMode} from "@modules/labor/Domain/labor-crew-mode";

export type LaborFields = {
  mode: string,
  labourHours?: number,
  laborCrew?: number[],
  flatRate?: number
}

export type LaborUnknownFields = {
  mode?: unknown,
  labourHours?: unknown,
  laborCrew?: unknown,
  flatRate?: unknown
}

export class Labor {
  constructor(
    public readonly mode: LaborCrewMode,
    public readonly hours?: number,
    public readonly laborCrew?: CrewID[],
    public readonly flatRate?: number
  ) {
    if (hours !== undefined && hours <= 0) {
      this.hours = undefined;
    }

    if (mode.equals(LaborCrewMode.flat()) && flatRate === undefined) {
      throw new Error('flatRate is required when mode is flat');
    }

    if (mode.equals(LaborCrewMode.crew())) {
      if (laborCrew === undefined) {
        throw new Error('hours and laborCrew are required when mode is crew');
      }

      if (typeof laborCrew !== 'object' || laborCrew === null) {
        throw new Error('laborCrew must be an array');
      }
    }


    this.hours = Number(hours);
  }

  static fromFields(fields: LaborUnknownFields): Labor {
    if (typeof fields !== 'object' || fields === null) {
      throw new Error('Invalid fields');
    }

    if (fields.mode === undefined || typeof fields.mode !== 'string') {
      throw new Error('Invalid mode');
    }

    const laborHours = fields.labourHours === undefined ? undefined : Number(fields.labourHours);


    if (LaborCrewMode.crew().equalsValue(fields.mode)) {
      if (fields.laborCrew === undefined) {
        throw new Error('laborCrew is required when mode is crew');
      }
      if (!Array.isArray(fields.laborCrew)) {
        throw new Error('Invalid laborCrew: must be an array');
      }
      const crew = fields.laborCrew.map((crewID) => new CrewID(Number(crewID)));
      return new Labor(LaborCrewMode.crew(), laborHours, crew);
    }

    if (fields.flatRate === undefined) {
      throw new Error('Invalid flatRate');
    }

    const flatRate = Number(fields.flatRate);
    return new Labor(LaborCrewMode.flat(), laborHours, undefined, flatRate);
  }

  static default() {
    return new Labor(LaborCrewMode.crew(), undefined, []);
  }

  static getFormFields(): (keyof LaborFields)[] {
    return [
      'mode',
      'labourHours',
      'laborCrew',
      'flatRate'
    ]
  }

  toFields(): LaborFields {
    return {
      mode: this.mode.toString(),
      labourHours: this.hours,
      laborCrew: this.laborCrew?.map((crewID) => crewID.getValue()),
      flatRate: this.flatRate
    }
  }

  getLaborExpense(crews: Crews): number {
    if (this.mode.equals(LaborCrewMode.flat())) {
      return this.flatRate || 0;
    }

    if (this.laborCrew === undefined) {
      throw new Error('laborCrew is required when mode is crew');
    }

    if (this.hours === undefined) {
      throw new Error('hours is required when mode is crew');
    }

    const techsUsed = crews.getCrewsUsed(this.laborCrew);
    const totalHourlyRate = techsUsed.reduce((acc, crew) => {
      return acc + crew.rate;
    }, 0);
    return totalHourlyRate * this.hours;
  }
}
