import {Component, OnInit} from '@angular/core';
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {
  GetCategoriesQuery
} from "@modules/product/product-category/Application/UseCase/Query/GetCategories/get-categories-query.service";
import {Categories} from "@modules/product/product-category/Domain/Category/categories";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {ProductLinkProvider} from "@modules/product/product/Infrastructure/Provider/product-link-provider";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-products-type-selector',
  templateUrl: './product-categories-list.component.html',
  styleUrls: ['./product-categories-list.component.scss'],
  standalone: false
})
export class ProductCategoriesListComponent implements OnInit {
  protected filteredCategories: Category[] = [];
  protected loading = false;
  protected title: string = 'Product Categories';
  protected searchTerm: string = '';
  protected categories?: Categories;

  constructor(
    private readonly getCategories: GetCategoriesQuery,
    private readonly loadingService: LoadingService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.getLoading()
      .pipe(untilDestroyed(this))
      .subscribe(loading => this.loading = loading);

    return this.loadingService.execute({
      executeCb: () => this.loadCategories()
    })
  }

  getCategoryLink(category: Category): string[] {
    return ProductLinkProvider.getCategoryProductListRoute(category.id);
  }

  protected filterCategories(searchTerm: string): void {
    if (!this.categories) {
      this.filteredCategories = [];
      return;
    }

    this.filteredCategories = this.categories
      .filterBySearchTerm(searchTerm)
      .sortByName()
      .toArray();
  }

  private async loadCategories(): Promise<void> {
    this.categories = await this.getCategories.execute();
    this.filterCategories('');
  }
}
