import {Injectable} from '@angular/core';
import {
  JsonFieldsFactory
} from "@modules/product/product-category-fields/Domain/Field/Field/Factory/json-fields-factory";
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  DynamicsUpdateProduct
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Model/dynamics-update-product";

@Injectable({
  providedIn: 'root'
})
export class DynamicsUpdateProductMapperService {
  constructor(
    private readonly jsonFieldsFactory: JsonFieldsFactory
  ) {
  }

  execute(product: Product): Partial<DynamicsUpdateProduct> {
    return {
      cr9b4_name: product.name.getValue(),
      cr9b4_description: product.description.getValue(),
      cr9b4_manufacturer: product.manufacturer.getValue(),
      cr9b4_price: product.price.getValue(),
      cr9b4_overage: product.overagePercentage.value,
      cr9b4_categoryspecificfields: this.jsonFieldsFactory.toJson(product.getAllFields()),
    }
  }
}
