import {NgModule} from "@angular/core";
import {MSAL_INSTANCE} from "@azure/msal-angular";
import {
  AppInitUserProfileProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-user-profile-provider.service";
import {LOADING_DEBOUNCE} from "@modules/_shared/Service/LoadingService/loading-debounce-provider";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {
  BusinessUnitStateProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/SavedBusinessUnit/business-unit-state-provider";
import {
  LocalStorageBusinessUnitStateProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/SavedBusinessUnit/local-storage-business-unit-state-provider.service";
import {ManagerEmailsProvider} from "@modules/_shared/Service/UserIsManager/manager-emails.provider";
import {
  StaticManagerEmailsProvider
} from "@modules/_shared/Service/UserIsManager/static-manager-emails-provider.service";
import {AreaContextNotificationsModule} from "@modules/area-context-notifications/area-context-notification.module";
import {AreaRepository} from "@modules/area/Domain/Area/Repository/area-repository";
import StaticAreaRepository from "@modules/area/Infrastructure/Repository/static-area-repository";
import {BusinessUnitModule} from "@modules/business-unit/business-unit.module";
import {BattingCalculationModule} from "@modules/calculation-impl/batting/_calculator/batting-calculation.module";
import {BlowInCalculatorModule} from "@modules/calculation-impl/blow-in-calculator/blow-in-calculator.module";
import {FoamCalculationModule} from "@modules/calculation-impl/foam/foam-calculation.module";
import {
  MiscellaneousCalculatorModule
} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module";
import {RemovalCalculatorModule} from "@modules/calculation-impl/removal-calculator/removal-calculator.module";
import {CalculationStrategyModule} from "@modules/calculation-strategy/calculation-strategy.module";
import {
  CalculationStrategyRepository
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Repository/calculation-strategy-repository";
import {
  InMemoryCalculationStrategyRepository
} from "@modules/calculation-strategy/Infrastructure/Repository/CalculationStrategyRepository/in-memory-calculation-strategy-repository.service";
import {
  CalculatorTabRepository
} from "@modules/calculation-tabs/Domain/CalculatorTab/Repository/calculator-tab-repository";
import {
  DynamicsCalculatorTabRepository
} from "@modules/calculation-tabs/Infrastructure/Repository/DynamicsCaltulatorTabRepository/dynamics-calculator-tab-repository";
import {CalculationRepository} from "@modules/calculation/Domain/Calculation/Repository/calculation-repository";
import {
  DynamicsEstimateCalculationRepository
} from "@modules/calculation/Infrastructure/Repository/dynamics-estimate-calculation-repository";
import {environment} from "@modules/environments/environment";
import {
  EstimateCalculationSummaryRepository
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/Repository/estimate-calculation-summary-repository";
import {
  DynamicsEstimateCalculationSummaryRepository
} from "@modules/estimate-calculation-summary/Infrastructure/Repository/EstimateCalculationSummary/dynamics-estimate-calculation-summary-repository.service";
import {
  EstimateSummaryRepository
} from "@modules/estimate-summary/Domain/EstimateSummary/Repository/estimate-summary-repository";
import {
  DynamicsEstimateSummaryRepository
} from "@modules/estimate-summary/Infrastructure/Repository/EstimateSummary/dynamics-estimate-summary-repository";
import {EstimateRepository} from "@modules/estimate/Domain/Estimate/Repository/estimate-repository";
import {
  DynamicsEstimateRepository
} from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/dynamics-estimate-repository";
import {EstimateService} from "@modules/estimate/Infrastructure/Service/estimate-service";
import {GmInputsRepository} from "@modules/gm-inputs/Domain/GmInputs/Repository/gm-inputs-repository";
import {
  DynamicsGmInputsRepository
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/dynamics-gm-inputs-repository";
import {MsalInstanceFactory} from "@modules/microsoft/microsoft-auth/Application/Factory/msal-instance-factory";
import {
  GetMsAlTokenQueryService
} from "@modules/microsoft/microsoft-auth/Application/UseCase/Query/get-ms-al-token-query.service";
import {MicrosoftAuthModule} from "@modules/microsoft/microsoft-auth/microsoft-auth.module";
import {MsDynamicsBaseUrlType} from "@modules/microsoft/microsoft-dynamics/Application/Type/ms-dynamics-base-url-type";
import {MicrosoftDynamicsModule} from "@modules/microsoft/microsoft-dynamics/microsoft-dynamics.module";
import {ProductModule} from "@modules/product/product/product.module";
import {GetProfileUseCase} from "@modules/profile/Application/UseCase/get-profile-use-case.service";
import {ProfileModule} from "@modules/profile/profile.module";
import {ServiceTitanProxyBaseUrl} from "@modules/service-titan/core/Application/DI/service-titan-proxy-base-url";
import {
  serviceTitanAuthHeadersProviderFactory
} from "@modules/service-titan/core/Application/Factory/service-titan-auth-headers-provider-factory";
import {
  serviceTitanProxyClientFactory
} from "@modules/service-titan/core/Application/Factory/service-titan-proxy-client-factory";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";
import {
  AdminSelectorBusinessUnitProvider
} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/admin-selector-business-unit-provider.service";
import {
  UserBusinessUnitProvider
} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/user-business-unit-provider";
import {
  AuthHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/auth-headers-provider.service";
import {
  BusinessUnitHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/business-unit-headers-provider.service";
import {
  ServiceTitanProxyClient
} from "@modules/service-titan/core/Infrastructure/ProxyClient/service-titan-proxy-client";
import {HttpService} from "@modules/service-titan/core/Infrastructure/ProxyClient/Service/http.service";
import {
  AppointmentRepository
} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/Repository/appointment-repository";
import {
  ServiceTitanProxyAppointmentRepository
} from "@modules/service-titan/service-titan-appointment/Infrastructure/Repository/Appointment/service-titan-proxy-appointment-repository";
import {
  ServiceTitanBusinessUnitRepository
} from "@modules/service-titan/service-titan-business-unit/Domain/ServiceTitanBusinessUnit/Repository/service-titan-business-unit-repository";
import {
  StaticServiceTitanBusinessUnitRepositoryService
} from "@modules/service-titan/service-titan-business-unit/Infrarstructure/Repository/static-service-titan-business-unit-repository.service";
import {
  CustomerRepository
} from "@modules/service-titan/service-titan-customer/Domain/Customer/Repository/customer-repository";
import {
  ServiceTitanCustomerRepository
} from "@modules/service-titan/service-titan-customer/Infrastructure/Repository/Customer/service-titan-proxy-customer-repository.service";

import {
  EstimateRepository as STEstimateRepository
} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/Repository/estimate-repository";
import {
  EstimateItemRepository
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateItem/Repository/estimate-item-repository";
import {
  EstimateServicesRepository
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/Repository/estimate-services-repository";
import {
  MaterialRepository
} from "@modules/service-titan/service-titan-estimate/Domain/Material/Repository/material-repository";
import {
  PushedEstimateRepository
} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/Repository/pushed-estimate-repository";
import {
  ServiceTitanEstimateRepository
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Estimate/service-titan-proxy-estimate-repository.service";
import {
  ServiceTitanProxyEstimateItemRepository
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateItem/service-titan-proxy-estimate-item-repository.service";
import {
  ServiceTitanEstimateServicesRepositoryService
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateServices/service-titan-estimate-services-repository.service";
import {
  ProxyMaterialRepository
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Material/proxy-material-repository.service";
import {
  DynamicsPushedEstimateRepositoryService
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/PushedEstimate/dynamics-pushed-estimate-repository.service";
import {
  JobAttachmentRepository
} from "@modules/service-titan/service-titan-forms/Domain/JobAttachment/job-attachment-repository";
import {
  ServiceTitanJobAttachmentRepository
} from "@modules/service-titan/service-titan-forms/Infrastructure/JobAttachment/service-titan-job-attachment-repository.service";
import {JobRepository} from "@modules/service-titan/service-titan-job/Domain/Job/Repository/job-repository";
import {
  LocalStorageSavedSearchJobRequestProviderService
} from "@modules/service-titan/service-titan-job/Infrastructure/Filter/local-storage-saved-search-job-request-provider.service";
import {
  ServiceTitanJobRepository
} from "@modules/service-titan/service-titan-job/Infrastructure/Repository/Job/service-titan-job-repository.service";
import {
  SavedSearchJobRequestProvider
} from "@modules/service-titan/service-titan-job/Presentation/Service/JobFilter/saved-search-job-request-provider";
import {
  LocationRepository
} from "@modules/service-titan/service-titan-location/Domain/Location/Repository/location-repository";
import {
  ServiceTitanLocationRepository
} from "@modules/service-titan/service-titan-location/Infrastructure/Repository/Location/service-titan-location-repository.service";
import {UserConfigRepository} from "@modules/user-config/Domain/UserConfig/Repository/user-config-repository";
import {
  DynamicsUserConfigRepository
} from "@modules/user-config/Infrastructure/Adapters/Dynamics/UserConfig/dynamics-user-config-repository";
import {UserInteractionsModule} from "@modules/user-interactions/user-interactions.module";


@NgModule({
  imports: [
    MicrosoftAuthModule,
    MicrosoftDynamicsModule,
    CalculationStrategyModule,
    BusinessUnitModule,
    ProfileModule,
    ProductModule,
    AreaContextNotificationsModule,

    //Calculations
    RemovalCalculatorModule,
    BlowInCalculatorModule,
    MiscellaneousCalculatorModule,
    BattingCalculationModule,
    FoamCalculationModule,
    UserInteractionsModule
  ],
  providers: [
    /**
     * Shared
     */
    {provide: LOADING_DEBOUNCE, useValue: 50},
    LoadingService,
    {provide: ManagerEmailsProvider, useExisting: StaticManagerEmailsProvider},

    /**
     * MSAL
     */
    {provide: MSAL_INSTANCE, useFactory: MsalInstanceFactory},

    /**
     * Shared Repos
     */
    {provide: CalculationRepository, useExisting: DynamicsEstimateCalculationRepository},
    {provide: AreaRepository, useExisting: StaticAreaRepository},
    {provide: GmInputsRepository, useExisting: DynamicsGmInputsRepository},
    {provide: CalculatorTabRepository, useExisting: DynamicsCalculatorTabRepository},
    {provide: EstimateRepository, useExisting: DynamicsEstimateRepository},

    /**BUSINESS UNIT**/
    AppInitUserProfileProvider,
    {
      provide: BusinessUnitStateProvider,
      useExisting: LocalStorageBusinessUnitStateProvider
    },

    /**SERVICE TITAN CORE**/
    {provide: ServiceTitanClient, useExisting: ServiceTitanProxyClient},
    {
      provide: ServiceTitanProxyBaseUrl,
      useValue: environment.serviceTitanProxyConfig.baseUrl
    },
    {
      provide: ServiceTitanProxyClient,
      useFactory: serviceTitanProxyClientFactory,
      deps: [
        ServiceTitanProxyBaseUrl,
        AuthHeadersProvider,
        BusinessUnitHeadersProvider,
        HttpService
      ]
    },
    {
      provide: AuthHeadersProvider,
      useFactory: serviceTitanAuthHeadersProviderFactory,
      deps: [
        GetMsAlTokenQueryService,
        MsDynamicsBaseUrlType
      ]
    },
    {provide: UserBusinessUnitProvider, useExisting: AdminSelectorBusinessUnitProvider},

    /**SERVICE TITAN JOB**/
    {provide: SavedSearchJobRequestProvider, useExisting: LocalStorageSavedSearchJobRequestProviderService},
    {provide: JobRepository, useExisting: ServiceTitanJobRepository},
    {provide: CustomerRepository, useExisting: ServiceTitanCustomerRepository},
    {provide: LocationRepository, useExisting: ServiceTitanLocationRepository},
    {provide: AppointmentRepository, useExisting: ServiceTitanProxyAppointmentRepository},

    /**SERVICE TITAN ESTIMATE**/
    {provide: STEstimateRepository, useExisting: ServiceTitanEstimateRepository},
    {provide: EstimateServicesRepository, useExisting: ServiceTitanEstimateServicesRepositoryService},
    {provide: EstimateItemRepository, useExisting: ServiceTitanProxyEstimateItemRepository},
    {provide: MaterialRepository, useExisting: ProxyMaterialRepository},
    {provide: PushedEstimateRepository, useExisting: DynamicsPushedEstimateRepositoryService},

    /** SERVICE TITAN FORMS **/
    {provide: JobAttachmentRepository, useExisting: ServiceTitanJobAttachmentRepository},

    {provide: EstimateSummaryRepository, useExisting: DynamicsEstimateSummaryRepository},
    {provide: EstimateCalculationSummaryRepository, useClass: DynamicsEstimateCalculationSummaryRepository},
    {provide: CalculationStrategyRepository, useExisting: InMemoryCalculationStrategyRepository},
    InMemoryCalculationStrategyRepository,
    EstimateService,
    DynamicsEstimateRepository,
    GetProfileUseCase,

    /** ServiceTitan Business Unit **/
    {provide: ServiceTitanBusinessUnitRepository, useExisting: StaticServiceTitanBusinessUnitRepositoryService},

    /** UserConfig **/
    {provide: UserConfigRepository, useExisting: DynamicsUserConfigRepository},
  ]
})
export class SharedModule {
}
