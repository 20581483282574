import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {
  NotificationCategoryID
} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-category-i-d";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

export class UserInteractionEvent {
  constructor(
    public readonly category: NotificationCategoryID,
    public readonly notificationID?: NotificationID,
    public readonly calculationID?: CalculationID
  ) {
  }
}
