import {inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {UserIsManager} from "@modules/_shared/Service/UserIsManager/user-is-manager.service";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {PinAuthService} from "@modules/gm-inputs/Presentation/Service/pin-auth.service";

@Injectable({
  providedIn: 'root'
})
export class CanActivateGmInputs implements CanActivate {
  private userIsAuthenticated: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly pinStorageService: PinAuthService,
    private readonly userIsManager: UserIsManager
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (await this.hasAccess()) {
      return true;
    }

    await this.router.navigate(['/gm-inputs', 'pin'], {queryParams: {backUrl: state.url}});
    return false;
  }

  private async hasAccess() {
    if (environment.environment === EnvironmentType.test) return true;
    if (environment.environment === EnvironmentType.local) return true;

    if (this.userIsAuthenticated) {
      return true;
    }

    if (await this.pinStorageService.login()) {
      this.userIsAuthenticated = true;
      return true;
    }

    if (await this.userIsManager.execute()) {
      return true;
    }

    return false;
  }
}

export const GeneralManagerAuthGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return await inject(CanActivateGmInputs).canActivate(route, state);
}
