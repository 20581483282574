import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {FieldString} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-string";
import {
  FieldConfigNotFoundException
} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/Exception/field-config-not-found.exception";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {ValidationResult} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/validation-result";

export class FieldsConfig {
  constructor(private readonly fieldsConfig: FieldConfig[]) {
  }

  getFieldConfigs(): FieldConfig[] {
    return [...this.fieldsConfig];
  }

  getFieldConfig(name: FieldConfigName): FieldConfig {
    const fieldConfig = this.fieldsConfig.find(
      (field) => field.name.equals(name),
    );

    if (!fieldConfig) {
      throw new FieldConfigNotFoundException(name);
    }

    return fieldConfig;
  }

  validateFields(fields: Fields): ValidationResult {
    const validationResults = this.fieldsConfig.map((fieldConfig) => {
      const fieldName = new FieldName(fieldConfig.name.getValue());
      let field: Field;

      switch (fieldConfig.type) {
        case FieldType.Number:
          field = fields.getByName<FieldNumber>(fieldName, FieldType.Number);
          break;
        case FieldType.String:
          field = fields.getByName<FieldString>(fieldName, FieldType.String);
          break;
        case FieldType.Dropdown:
          field = fields.getByName<FieldDropdown>(fieldName, FieldType.Dropdown);
          break;
      }

      return fieldConfig.validate(field);
    });

    return ValidationResult.combine(...validationResults);
  }

  isFieldExists(name: string) {
    return this.fieldsConfig.some(field => field.name.getValue() === name);
  }
}
