import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {PercentRate} from "@modules/gm-inputs/Domain/Other/VO/percent-rate";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {
  ProductCustomFieldsValidationException
} from "@modules/product/product/Domain/Product/Exception/product-custom-fields-validation-exception";
import {
  ProductIsNotCorporateException
} from "@modules/product/product/Domain/Product/Exception/product-is-not-corporate-exception";
import {ProductBusinessOwnerID} from "@modules/product/product/Domain/Product/VO/product-business-owner-id";
import {ProductDescription} from "@modules/product/product/Domain/Product/VO/product-description";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductManufacturer} from "@modules/product/product/Domain/Product/VO/product-manufacturer";
import {ProductName} from "@modules/product/product/Domain/Product/VO/product-name";
import {ProductPrice} from "@modules/product/product/Domain/Product/VO/product-price";

export const PRODUCT_PRICE_FIELD = 'price';
export const PRODUCT_OVERAGE_FIELD = 'overagePercentage';

type ProductPublicFields = {
  [PRODUCT_PRICE_FIELD]: number;
  [PRODUCT_OVERAGE_FIELD]: number;
}

export class Product {
  public readonly productID: string = this.id.getValue();
  public readonly isProductCopy: boolean = !!this.originalProduct;
  public readonly isCorporateProductOrCopy: boolean = this.ownerBusinessUnitID.equals(this.rootBusinessUnitID) || this.isProductCopy;
  public readonly isEditable: boolean = this.category.isCategoryProductsEditable();

  constructor(
    public readonly id: ProductID,
    public readonly name: ProductName,
    public readonly description: ProductDescription,
    public readonly manufacturer: ProductManufacturer,
    public readonly price: ProductPrice,
    public readonly ownerBusinessUnitID: ProductBusinessOwnerID,
    public readonly category: Category,
    private readonly categoryFields: Fields,
    public readonly rootBusinessUnitID: BusinessUnitID,
    public readonly overagePercentage: PercentRate,
    public readonly originalProduct?: Product
  ) {
    const validationResult = this.category.validateFields(this.categoryFields);
    if (!validationResult.isValid) {
      throw new ProductCustomFieldsValidationException(validationResult.errors);
    }
  }

  getNumberField(fieldName: string): number {
    return this.categoryFields.getByName<FieldNumber>(new FieldName(fieldName), FieldType.Number).getValue();
  }

  getDropDownField(fieldName: string): string {
    return this.categoryFields.getByName<FieldDropdown>(new FieldName(fieldName), FieldType.Dropdown).getValue();
  }

  getPrice(): number {
    return this.price.getValue();
  }

  isMatchesSearchTerm(searchTerm: string): boolean {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const words = lowerCaseSearchTerm.split(' ');

    const values = [
      this.name.getValue(),
      this.description.getValue(),
      this.manufacturer.getValue(),
    ].map(value => value?.toLowerCase()).filter(Boolean);

    return words.every(word => values.some(value => value.includes(word)));
  }

  isMatchesName(name: ProductName): boolean {
    return this.name.equals(name);
  }

  setPrice(price: number): Product {
    return new Product(this.id, this.name, this.description, this.manufacturer, new ProductPrice(price), this.ownerBusinessUnitID, this.category, this.categoryFields, this.rootBusinessUnitID, this.overagePercentage, this.originalProduct)
  }

  setPublicFields(fields: ProductPublicFields): Product {
    return new Product(
      this.id,
      this.name,
      this.description,
      this.manufacturer,
      new ProductPrice(fields.price),
      this.ownerBusinessUnitID,
      this.category,
      this.categoryFields,
      this.rootBusinessUnitID,
      new PercentRate(fields.overagePercentage),
      this.originalProduct
    )
  }

  getAllFields(): Fields {
    return new Fields([...this.categoryFields.fields]);
  }

  getValues() {
    const values: Record<string, unknown> = {
      id: this.id.getValue(),
      name: this.name.getValue(),
      description: this.description.getValue(),
      manufacturer: this.manufacturer.getValue(),
      price: this.price.getValue(),
      ownerBusinessUnitID: this.ownerBusinessUnitID.getValue(),
      category: this.category.id.getValue(),
      overagePercentage: Math.round(this.overagePercentage.value * 100),
    };

    if (this.categoryFields) {
      this.categoryFields.fields.forEach(field => {
        values[field.name.getValue()] = field.getValue();
      });
    }

    return values;
  }

  createZeeCopy(zeeBusinessUnitID: BusinessUnitID): Product {
    if (this.originalProduct) throw new ProductIsNotCorporateException(this.id);

    return new Product(ProductID.createBlank(), this.name, this.description, this.manufacturer, this.price, new ProductBusinessOwnerID(zeeBusinessUnitID.getValue()), this.category, this.categoryFields, this.rootBusinessUnitID, this.overagePercentage, this)
  }

  addOverage(n: number) {
    return this.overagePercentage.calculateOverage(n);
  }
}
