import {Injectable} from '@angular/core';
import {GetCurrentUserProfileQuery} from "@modules/profile/Application/UseCase/get-current-user-profile-query.service";
import {SetUserConfigCommand} from "@modules/user-config/Application/UseCase/Command/set-user-config-query.service";
import {GetUserConfigQuery} from "@modules/user-config/Application/UseCase/Query/get-user-config-query.service";
import {UserConfig} from "@modules/user-config/Domain/UserConfig/user-config";
import {ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserConfigProvider {
  public readonly userConfig$: ReplaySubject<UserConfig> = new ReplaySubject<UserConfig>();

  constructor(
    private readonly getCurrentUserProfileQuery: GetCurrentUserProfileQuery,
    private readonly getUserConfigQuery: GetUserConfigQuery,
    private readonly setUserConfigCommand: SetUserConfigCommand,
  ) {
    this.init().then();
  }

  async setUserConfig(userConfig: UserConfig): Promise<void> {
    await this.setUserConfigCommand.execute(userConfig);
    this.userConfig$.next(userConfig);
  }

  private async init() {
    const profile = this.getCurrentUserProfileQuery.execute();
    const userConfig = await this.getUserConfigQuery.execute(profile.profileID);
    this.userConfig$.next(userConfig);
  }
}
