import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {RemovalAreaOptions} from "./removal-area-options";

export class RemovalArea extends CalculationArea {
  public readonly sqft: number;
  public readonly miscellaneous: number;

  constructor(params: RemovalAreaOptions) {
    super(params);

    this.sqft = params.sqft;
    this.miscellaneous = params.miscellaneous;
  }
}
