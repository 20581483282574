<div [formGroup]="form">
  <ion-item>
    <ion-select
      formControlName="service"
      label="Select Service"
      label-placement="floating"
      placeholder="Select Service"
    >
      @for (service of services; track service) {
        <ion-select-option [value]="service.value">
          {{ service.value }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>

  <app-foam-depth-selector [form]="form"></app-foam-depth-selector>

  <ion-item>
    <ion-select
      formControlName="product"
      label="Select Product"
      label-placement="floating"
      placeholder="Select Product"
    >
      @for (product of filteredProducts; track product.id.toString()) {
        <ion-select-option [value]="product.id.toString()">
          {{ product.name }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>
  @if (filteredProducts.length === 0) {
    <ion-item>
      <ion-note color="danger">No product exists in the database for this scenario</ion-note>
    </ion-item>
  }
</div>
