import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {
  Appointment,
  AppointmentOptions
} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/appointment";
import {AppointmentID} from "@modules/service-titan/service-titan-appointment/Domain/Appointment/VO/appointment-id";
import {
  ServiceTitanAppointmentModel
} from "@modules/service-titan/service-titan-appointment/Infrastructure/Repository/Appointment/Model/service-titan-appointment-model";


@Injectable({'providedIn': 'root'})
export class AppointmentFactory implements Mapper<ServiceTitanAppointmentModel, Appointment> {
  mapFrom(model: ServiceTitanAppointmentModel): Appointment {
    const options: AppointmentOptions = {
      id: new AppointmentID(model.id),
      appointmentNumber: model.appointmentNumber,
      start: new Date(model.start),
      end: new Date(model.end),
      arrivalWindowStart: new Date(model.arrivalWindowStart),
      arrivalWindowEnd: new Date(model.arrivalWindowEnd),
      status: model.status
    };

    return new Appointment(options);
  }

  mapTo(): ServiceTitanAppointmentModel {
    throw new Error("Method not implemented.");
  }
}
