import {Injectable} from '@angular/core';
import {NotificationAction} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action";
import {Notification} from "@modules/area-context-notifications/Domain/Notification/notification";
import {Notifications} from "@modules/area-context-notifications/Domain/Notification/notifications";
import {
  NotificationRepository
} from "@modules/area-context-notifications/Domain/Notification/Repository/notification-repository";
import {BAT_CALCULATION_ID} from "@modules/calculation-impl/batting/_calculator/batting-calculation.module";
import {BLOW_IN_CALCULATION_ID} from "@modules/calculation-impl/blow-in-calculator/blow-in-calculator.module";
import {FOAM_CALCULATION_ID} from "@modules/calculation-impl/foam/foam-calculation.module";
import {MISC_CALCULATION_ID} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module";
import {REMOVAL_CALCULATION_ID} from "@modules/calculation-impl/removal-calculator/removal-calculator.module";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

@Injectable({
  providedIn: 'root'
})
export class StaticNotificationRepository extends NotificationRepository {
  private areas = [/attic/i, /walls/i, /crawlspace/i];

  getNotifications(): Promise<Notifications> {
    const evidenceOfVermiculiteOrAsbestosNotification = this.evidenceOfVermiculiteOrAsbestosNotification();
    const areExhaustFansProperlyVented = this.areExhaustFansProperlyVented();
    const isKnobAndTubeWiringPresent = this.isKnobAndTubeWiringPresent();

    return Promise.resolve(
      new Notifications([
        new Notification(
          new NotificationID('attic-roofline-combustion-appliances'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /Attic Roofline/i,
          },
          new NotificationAction(
            'Are there any open combustion appliances?',
            2,
            'Not recommended to spray roofline.',
          ),
        ),
        new Notification(
          new NotificationID('attic-roofline-hot-pipes'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /attic/i
          },
          new NotificationAction(
            'Are there hot pipes or chimneys present?',
            1,
            'Ensure to include mineral wool wrap on Misc. Template.',
          )
        ),
        new Notification(
          new NotificationID('attic-any-vents-present'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /attic/i
          },
          new NotificationAction(
            'Are there any vents present? (Eave, Turtle, Turbine, Gable)',
            3,
            'Consider adding blocking material to Misc. Template.',
          )
        ),
        new Notification(
          new NotificationID('attic-any-recessed-light-fixtures'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /Attic Floor/i
          },
          new NotificationAction(
            'Are there any recessed light fixtures?',
            4,
            'Consider adding Recessed Light Covers to Misc. Template',
          )
        ),
        ...evidenceOfVermiculiteOrAsbestosNotification,
        ...areExhaustFansProperlyVented,

        new Notification(
          new NotificationID('is-moisture-reading-below-19-percent'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /.*/i
          },
          new NotificationAction(
            'Is moisture reading below 19%',
            5,
            undefined,
            'Look for evidence for source of moisture for customer to have corrected professionally before spray foam can be applied',
          )
        ),
        ...isKnobAndTubeWiringPresent,
        new Notification(
          new NotificationID('is-this-blow-in-removal'),
          {
            calculation: REMOVAL_CALCULATION_ID,
            area: /.*/i
          },
          new NotificationAction(
            'Is this blow in removal?',
            1,
            'Ensure there is no batting underneath blow in',
          )
        )
      ])
    );
  }

  evidenceOfVermiculiteOrAsbestosNotification(): Notification[] {
    return [FOAM_CALCULATION_ID,
      BAT_CALCULATION_ID,
      MISC_CALCULATION_ID,
      BLOW_IN_CALCULATION_ID,
      REMOVAL_CALCULATION_ID].map((calculationID) => {
      return new Notification(
        new NotificationID('evidence-of-vermiculite-or-asbestos'),
        {
          calculation: calculationID,
          area: /attic/i
        },
        new NotificationAction(
          'Is there evidence of Vermiculite or Asbestos?',
          6,
          'Leave area immediately and inform customer to have remediated before we can return – safety hazard',
        )
      );
    });
  }

  areExhaustFansProperlyVented(): Notification[] {
    return [FOAM_CALCULATION_ID,
      BAT_CALCULATION_ID,
      MISC_CALCULATION_ID,
      BLOW_IN_CALCULATION_ID,
      REMOVAL_CALCULATION_ID].map((calculationID) => {
      return new Notification(
        new NotificationID('are-exhaust-fans-properly-vented'),
        {
          calculation: calculationID,
          area: /attic/i
        },
        new NotificationAction(
          'Are exhaust fans properly vented to outside of envelope?',
          7,
          undefined,
          'Inform Customer to have them properly vented',
        )
      );
    });
  }

  isKnobAndTubeWiringPresent(): Notification[] {
    const areaPattern = new RegExp(this.areas.map(pattern => pattern.source).join('|'), 'i');

    return [FOAM_CALCULATION_ID,
      BAT_CALCULATION_ID,
      MISC_CALCULATION_ID,
      BLOW_IN_CALCULATION_ID,
      REMOVAL_CALCULATION_ID].map((calculationID) => {
      return new Notification(
        new NotificationID('is–knob-and-tube-wiring-present'),
        {
          calculation: calculationID,
          area: areaPattern
        },
        new NotificationAction(
          'Is Knob and Tube wiring present?',
          8,
          'Inform customer that a licensed electrician must remove all wiring before we can return – safety hazard',
        )
      );
    });
  }
}

