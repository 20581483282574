import {Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {
  NotificationActionProcessor
} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action-processor";
import {Notification} from "@modules/area-context-notifications/Domain/Notification/notification";
import {
  ModalNotificationActionComponent
} from "@modules/area-context-notifications/Presentation/Component/modal-notification-action.component";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class DisplayModalNotificationActionProcessor extends NotificationActionProcessor {

  constructor(
    private readonly modalCtrl: ModalController,
  ) {
    super();
  }

  async showNotifications(action: Notification[], calculationID: CalculationID): Promise<void> {
    action = action.sort((a, b) => a.action.priority - b.action.priority);
    for (const notificationAction of action) {
      await this.displayModalNotification(notificationAction, calculationID);
    }
  }

  private async displayModalNotification(notification: Notification, calculationID: CalculationID): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalNotificationActionComponent,
      componentProps: {
        notificationAction: notification.action,
        notificationID: notification.notificationID,
        calculationID: calculationID
      },
      backdropDismiss: false,
      cssClass: 'sheet-modal',
    });
    await modal.present();
    await modal.onDidDismiss();
  }
}
