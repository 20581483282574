import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {Labor} from "@modules/labor/Domain/labor";
import {LaborCrewMode} from "@modules/labor/Domain/labor-crew-mode";


export abstract class CalculationArea {
  readonly id: string;
  readonly name: string;
  readonly businessUnitID: BusinessUnitID;
  readonly labor: Labor;

  protected constructor(params: CalculationAreaOptions) {
    this.id = params.id;
    this.name = params.name;
    this.businessUnitID = params.businessUnitID;

    this.labor = this.createLabor(params);
  }

  getLaborExpense(gmInputs: GmInputs): number {
    if (this.labor.mode.equals(LaborCrewMode.flat())) {
      return this.labor.flatRate || 0;
    }

    const crews = gmInputs.getCrews();
    return gmInputs.addBenefits(this.labor.getLaborExpense(crews));
  }

  getCrewUsed(): CrewID[] {
    if (this.labor.mode.equals(LaborCrewMode.crew())) {
      return this.labor.laborCrew || [];
    }
    return [];
  }

  public getLabourHours(): number {
    return this.labor.hours || 0;
  }

  private createLabor(params: CalculationAreaOptions) {
    if (params.labor) {
      return params.labor;
    }
    return new Labor(LaborCrewMode.crew(), 0, []);
  }
}
