import {ProfileID} from "@modules/profile/Domain/Profile/VA/profile-id";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

export class UserNotificationStatus {
  constructor(
    public readonly notificationID: NotificationID,
    public readonly profileID: ProfileID,
    public readonly disabled: boolean
  ) {
  }
}
