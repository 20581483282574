import {
  UserNotificationStatus
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/user-notification-status";

export abstract class UserNotificationStatusRepository {
  abstract getStatus(userID: string, notificationID: string): Promise<UserNotificationStatus>;

  abstract getAll(userID: string): Promise<UserNotificationStatus[]>

  abstract createStatus(userNotificationStatus: UserNotificationStatus): Promise<void>;
}
