import {Component, computed, Input, signal} from '@angular/core';
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";

@Component({
  selector: 'app-labor-crew-display',
  templateUrl: './labor-crew-display.component.html',
  styleUrls: ['./labor-crew-display.component.scss'],
})
export class LaborCrewDisplayComponent {
  @Input({required: true}) laborHours = 0;

  protected crewsSignal = signal<Crew[]>([])
  protected crewNames = computed(() => this.crewsSignal().map(crew => crew.name).join(', '));

  @Input({required: true}) set crews(crews: Crew[]) {
    this.crewsSignal.set(crews);
  }
}
