import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {BattingAreaOptions} from "./batting-area-options";

export class BattingArea extends CalculationArea {
  public readonly sqft: number;
  public readonly constructionName: string;
  public readonly constructionType: string;
  public readonly onCenter: number;
  public readonly facingName: string;
  public readonly rValue: string;
  public readonly productID?: string;
  public readonly miscellaneous: number;
  public readonly battingServiceName: BattingServiceEnum;
  public readonly filterType: string;
  public readonly depth: number;

  constructor(params: BattingAreaOptions) {
    super(params);

    this.sqft = params.sqft;
    this.constructionName = params.constructionName;
    this.constructionType = params.constructionType;
    this.onCenter = params.onCenter;
    this.facingName = params.facingName;
    this.rValue = params.rValue;
    this.productID = params.productID;
    this.miscellaneous = params.miscellaneous;
    this.battingServiceName = params.battingServiceName;
    this.filterType = params.filterType;
    this.depth = params.depth;
  }
}
