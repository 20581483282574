import {FoamArea} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area";
import {
  FoamAreaFactory
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area-factory.service";
import {
  FoamAreaTotal
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationAreaTotal/foam-area-total";
import {
  FoamAreaTotalFactory
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationAreaTotal/foam-area-total-factory.service";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {FoamProducts} from "@modules/product/products/foam-product/Domain/Product/foam-products";

export class FoamCalculationStrategy extends CalculationStrategy<
  FoamArea,
  FoamAreaTotal,
  FoamAreaFactory,
  FoamAreaTotalFactory
> {
  async calculateArea(input: FoamArea, gmInputs: GmInputs): Promise<FoamAreaTotal> {
    const laborExpense = input.getLaborExpense(gmInputs);

    const options = {
      //INPUT
      sqft: input.getSqft(),
      depth: input.depth,
      product: input.product?.toString(),
      laborHours: input.getLabourHours(),
      misc: input.miscellaneous,
      //CALCULATED
      laborCost: laborExpense,
      sets: 0,
      materialCost: 0,
      totalCost: 0,
      //SALES TAX
      materialSalesTax: 0
    };

    if (!input.service || !input.product) return this.areaTotalFactory.execute(options);

    const products = await this.getProducts.execute<FoamProducts>({
      categoryName: new FoamCategoryName()
    });
    const product = products.getByID(new ProductID(input.product));
    if (!product) return this.areaTotalFactory.execute(options);

    options.product = product.name.getValue();

    const boardFeet = input.getSqft() * input.depth;
    let setsOrBucketsUsed: number;
    if (input.service === FoamService.intumescentCoating) {
      setsOrBucketsUsed = input.getSqft() / product.productYield;
    } else {
      setsOrBucketsUsed = boardFeet / product.productYield;
    }

    options.sets = product.addOverage(setsOrBucketsUsed)
    options.materialCost = product.getPrice() * options.sets;
    options.materialSalesTax = options.materialCost * gmInputs.getSalesTaxRatePercentage();
    options.totalCost = options.materialCost + options.laborCost + options.misc + options.materialSalesTax;

    return this.areaTotalFactory.execute(options);
  }

  async calculateTotal(input: FoamArea[], gmInputs: GmInputs): Promise<CalculationTotal> {
    const areaTotals = await Promise.all(input.map(model => this.calculateArea(model, gmInputs)));

    const total: CalculationTotalOptions = areaTotals.reduce((acc, areaTotal) => ({
      materialCost: acc.materialCost + areaTotal.materialCost,
      materialSalesTax: acc.materialSalesTax + areaTotal.materialSalesTax,
      laborHours: acc.laborHours + areaTotal.laborHours,
      laborCost: acc.laborCost + areaTotal.laborCost,
      misc: acc.misc + areaTotal.misc,
      totalCost: acc.totalCost + areaTotal.totalCost
    }), {
      materialCost: 0,
      materialSalesTax: 0,
      laborHours: 0,
      laborCost: 0,
      misc: 0,
      totalCost: 0
    });

    return this.totalFactory.create(total);
  }
}

