import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {ProfileID} from "@modules/profile/Domain/Profile/VA/profile-id";
import {UserConfig} from "@modules/user-config/Domain/UserConfig/user-config";
import {IcalcTemplateMode} from "@modules/user-config/Domain/UserConfig/VO/icalc-template-mode";
import {
    DynamicsUserConfig
} from "@modules/user-config/Infrastructure/Adapters/Dynamics/UserConfig/Model/dynamics-user-config";

@Injectable({providedIn: 'root'})
export class DynamicsUserConfigMapper implements Mapper<UserConfig, Partial<DynamicsUserConfig>> {
    mapFrom(userConfig: UserConfig): Partial<DynamicsUserConfig> {
        return {
            cr9b4_templatemode: !userConfig.isTrainingTemplateMode()
        };
    }

    mapTo(userConfig: DynamicsUserConfig): UserConfig {
        return new UserConfig(
            new ProfileID(userConfig._owninguser_value),
            userConfig.cr9b4_templatemode ? IcalcTemplateMode.Pro : IcalcTemplateMode.Training
        );
    }

    createNewUserConfig(userID: string): UserConfig {
        return new UserConfig(
            new ProfileID(userID),
            IcalcTemplateMode.Training
        )
    }
}
