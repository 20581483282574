import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {SavingStatusComponent} from "@modules/_shared/Component/saving-status/saving-status.component";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {
  DynamicFormFieldsRendererComponent
} from "@modules/dynamic-form/Presentation/Component/dynamic-form-fields-renderer/dynamic-form-fields-renderer.component";
import {HeaderModule} from "@modules/header/header.module";
import {ProductCategoryModule} from "@modules/product/product-category/product-category.module";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {
  DynamicsProductRepository
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/dynamics-product-repository.service";
import {
  DeleteTestProductsComponent
} from "@modules/product/product/Presentation/Component/delete-test-products/delete-test-products.component";
import {
  ProductCreatorComponent
} from "@modules/product/product/Presentation/Component/product-creator/product-creator.component";
import {
  ProductEditorComponent
} from "@modules/product/product/Presentation/Component/product-editor/product-editor.component";

import {
  ProductListDisplayComponent
} from "@modules/product/product/Presentation/Component/product-list/product-list-display/product-list-display.component";
import {
  ProductListComponent
} from "@modules/product/product/Presentation/Component/product-list/product-list.component";
import {
  ProductsBatchModeEditorComponent
} from "@modules/product/product/Presentation/Component/products-batch-mode-editor/products-batch-mode-editor.component";
import {ProductRoutingModule} from "@modules/product/product/product-routing.module";

@NgModule({
  declarations: [
    ProductListComponent,
    ProductEditorComponent,
    ProductsBatchModeEditorComponent,
    ProductListDisplayComponent,
    ProductCreatorComponent,
    DeleteTestProductsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterLink,
    HeaderModule,
    SavingStatusComponent,
    ClearZeroDirective,
    ReactiveFormsModule,
    ProductCategoryModule,
    ProductRoutingModule,
    DynamicFormFieldsRendererComponent
  ],
  providers: [
    {provide: ProductRepository, useExisting: DynamicsProductRepository}
  ],
  exports: [
    ProductListComponent
  ]
})
export class ProductModule {
}
