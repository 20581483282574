import {Injectable} from "@angular/core";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {UserConfigRepository} from "@modules/user-config/Domain/UserConfig/Repository/user-config-repository";
import {UserConfig} from "@modules/user-config/Domain/UserConfig/user-config";

@Injectable({providedIn: 'root'})
export class SetUserConfigCommand implements UseCase<UserConfig, Promise<UserConfig>> {
    constructor(
        private readonly userConfigRepository: UserConfigRepository
    ) {
    }

    execute(userConfig: UserConfig): Promise<UserConfig> {
        return this.userConfigRepository.setUserConfig(userConfig);
    }
}
