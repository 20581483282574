import {Injectable} from '@angular/core';
import {IMsAlAccountService} from "@modules/microsoft/microsoft-auth/Domain/AuthService/ims-al-account-service";
import {USER_LOGIN_TRACE_CATEGORY_ID} from "@modules/reporting/Infrastructure/LoginTrace/user-login-trace-category-id";
import {
  RecordUserInteractionCommand
} from "@modules/user-interactions/Application/UseCase/Command/RecordUserInteraction/record-user-interaction-command.service";
import {Request} from "@modules/user-interactions/Application/UseCase/request";
import {take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserInteractionsLogInTracer {
  constructor(
    private readonly iMsAlAccountService: IMsAlAccountService,
    private readonly recordUserInteractionCommand: RecordUserInteractionCommand,
  ) {
  }

  startTracing() {
    this.iMsAlAccountService.getIsLoggedIn$().pipe(take(1)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.userLoggedIn();
      }
    });
  }

  private userLoggedIn() {
    const request: Request = {
      category: USER_LOGIN_TRACE_CATEGORY_ID
    }

    setTimeout(() => {
      this.recordUserInteractionCommand.execute(request).then();
    }, 2000);
  }
}
