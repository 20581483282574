import {Component, Input} from '@angular/core';
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";

@Component({
  selector: 'app-crew-chip-display',
  templateUrl: './crew-chip-display.component.html',
  styleUrls: ['./crew-chip-display.component.scss'],
})
export class CrewChipDisplayComponent {
  @Input({required: true}) crews: Crew[] = [];

}
