import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-service-type-enum";
import {BlowInAreaOptions} from "./blow-in-area-options";

export class BlowInArea extends CalculationArea {
  public readonly blowInType: BlowInServiceTypeEnum;
  public readonly sqft: number;
  public readonly construction: string;
  public readonly areaType: BlowInAreaTypeEnum;
  public readonly toggleNetService: boolean;
  public readonly rValue: number;
  public readonly linearFeet: number;
  public readonly blowInProduct?: string;
  public readonly miscellaneous: number;
  public readonly netProduct?: string;


  constructor(params: BlowInAreaOptions) {
    super(params);

    this.blowInType = params.blowInType;
    this.sqft = params.sqft;
    this.construction = params.construction;
    this.areaType = params.areaType;
    this.toggleNetService = params.toggleNetService;
    this.rValue = params.rValue;
    this.linearFeet = params.linearFeet;
    this.blowInProduct = params.blowInProduct;
    this.miscellaneous = params.miscellaneous;
    this.netProduct = params.netProduct;
  }
}
