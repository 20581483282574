import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {EstimateAreaOptions} from "@modules/estimate-calculation-summary/Domain/EstimateArea/estimate-area-options";

export class EstimateArea {
  public readonly id: string;
  public readonly areaName: string;
  public readonly areaTotal: CalculationAreaTotal;
  public readonly crewNames: string[];

  constructor(data: EstimateAreaOptions) {
    this.validateOptions(data);

    this.id = data.id;
    this.areaName = data.areaName;
    this.areaTotal = data.areaTotal;
    this.crewNames = data.crewNames
  }

  private validateOptions(options: EstimateAreaOptions): void {
    const fields: Array<keyof EstimateAreaOptions> = [
      'id',
      'areaName',
      'areaTotal',
      'crewNames',
    ];

    fields.forEach((field) => {
      if (!options[field]) {
        throw new Error(`Missing required field: ${field}`);
      }
    });
  }
}
