import {Component, computed, Input, OnInit, signal} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ModalController} from "@ionic/angular";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {GetCurrentGmInputs} from "@modules/gm-inputs/Application/UseCase/Query/get-current-gm-inputs.service";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {Labor} from "@modules/labor/Domain/labor";
import {LaborModalComponent} from "@modules/labor/Presentation/Component/labor-modal/labor-modal.component";
import {
  getCrewsFromGmInputs,
  getLaborMode,
  shouldDisplayCrews,
  shouldDisplayFlatRate
} from "@modules/labor/Presentation/Service/crewNames";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-labor-form',
  templateUrl: './labor-form.component.html',
  styleUrls: ['./labor-form.component.scss'],
})
export class LaborFormComponent implements OnInit {
  @Input({required: true}) public form: FormGroup = new FormGroup({});

  protected isLoading = signal(false);
  protected labor = signal<Labor | undefined>(undefined);
  protected gmInputs = signal<GmInputs | undefined>(undefined);
  protected laborMode = computed(() => getLaborMode(this.labor()));
  protected displayCrews = computed(() => shouldDisplayCrews(this.laborMode()));
  protected displayFlatRate = computed(() => shouldDisplayFlatRate(this.laborMode()));
  protected crews = computed(() => getCrewsFromGmInputs(this.gmInputs(), this.labor()?.laborCrew));


  constructor(
    private readonly loadingService: LoadingService,
    private readonly getCurrentUserGmInputs: GetCurrentGmInputs,
    protected readonly modalCtrl: ModalController,
    private readonly notificationService: NotificationService
  ) {
  }

  async openLaborModal($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    const labor = this.labor();
    const gmInputs = this.gmInputs();

    if (!labor || !gmInputs) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: LaborModalComponent,

      componentProps: {
        labor,
        gmInputs
      },
    });

    await modal.present();
    const {data} = await modal.onWillDismiss<Labor | undefined>();
    if (!data) {
      return;
    }

    this.labor.set(data);

    const updatedFields = data.toFields();
    this.form.patchValue(updatedFields, {emitEvent: true});
  }

  async ngOnInit() {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.isLoading.set(loading));

    await this.loadingService.execute({
      executeCb: () => this.loadGmInputs()
    });

    try {
      const labor = Labor.fromFields(this.form.value);
      this.labor.set(labor);
    } catch (e: unknown) {
      this.labor.set(undefined);

      const message = e instanceof Error ? e.message : 'Failed to load labor';
      await this.notificationService.execute(message);
    }
  }


  private async loadGmInputs() {
    const gmInputs = await this.getCurrentUserGmInputs.execute();
    this.gmInputs.set(gmInputs);
  }
}
