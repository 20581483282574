import {BlowInArea} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area";
import {
  BlowInAreaFactory
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-factory";
import {
  BlowInAreaTotal
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationAreaTotal/blow-in-area-total";
import {
  BlowInAreaTotalFactory
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationAreaTotal/blow-in-area-total-factory";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";
import {BlowInBlowProducts} from "@modules/product/products/blow-in-blow-product/Domain/Product/blow-in-blow-products";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";
import {BlowInNetProducts} from "@modules/product/products/blow-in-net-product/Domain/Product/blow-in-net-products";

export class BlowInCalculationStrategy extends CalculationStrategy<
  BlowInArea,
  BlowInAreaTotal,
  BlowInAreaFactory,
  BlowInAreaTotalFactory
> {
  async calculateTotal(areas: BlowInArea[], gmInputs: GmInputs): Promise<CalculationTotal> {
    let total: CalculationTotalOptions = {
      materialCost: 0,
      materialSalesTax: 0,
      laborHours: 0,
      laborCost: 0,
      misc: 0,
      totalCost: 0
    };

    for (const area of areas) {
      const areaTotal = await this.calculateArea(area, gmInputs);

      total = {
        materialCost: total.materialCost + areaTotal.materialCost,
        materialSalesTax: total.materialSalesTax + areaTotal.materialSalesTax,
        laborHours: total.laborHours + areaTotal.laborHours,
        laborCost: total.laborCost + areaTotal.laborCost,
        misc: total.misc + areaTotal.misc,
        totalCost: total.totalCost + areaTotal.totalCost
      };
    }
    return this.totalFactory.create(total);
  }

  async calculateArea(input: BlowInArea, gmInputs: GmInputs): Promise<BlowInAreaTotal> {
    const laborExpense = input.getLaborExpense(gmInputs);

    const netResults = await this.calculateNetCost(input);
    const blowResults = await this.calculateBlowInCost(input);

    const materialCost = netResults.materialCost + blowResults.materialCost;
    const materialSalesTax = materialCost * gmInputs.getSalesTaxRatePercentage();
    const totalCost = materialCost + laborExpense + input.miscellaneous + materialSalesTax;

    return new BlowInAreaTotal(
      input.getLabourHours(),
      laborExpense,
      netResults.product,
      blowResults.product,
      input.sqft,
      blowResults.bagsUsed,
      materialCost,
      materialSalesTax,
      totalCost,
      input.miscellaneous,
      blowResults.stacks,
      netResults.rollsUsed,
      netResults.materialCost,
      blowResults.materialCost
    );
  }

  public async calculateNetCost(input: BlowInArea): Promise<{
    product: string,
    materialCost: number,
    rollsNeeded: number,
    rollCost: number,
    rollsUsed: number
  }> {
    const emptyParams = {
      product: '',
      materialCost: 0,
      rollsNeeded: 0,
      rollCost: 0,
      rollsUsed: 0
    };

    if (input.areaType === BlowInAreaTypeEnum.Attic || (input.areaType === BlowInAreaTypeEnum.SubFloors && !input.toggleNetService) || !input.netProduct) {
      return emptyParams
    }
    const products = await this.getProducts.execute<BlowInNetProducts>({
      categoryName: new BlowInNetCategoryName()
    });
    const product = products.getByID(new ProductID(input.netProduct));
    if (!product) return emptyParams;

    const linearYieldFt = product.linearYieldFt;
    const rollsUsed = input.linearFeet === 0 || linearYieldFt === 0
      ? 0
      : product.addOverage(input.linearFeet / linearYieldFt);

    const rollCost = rollsUsed * product.getPrice();

    const rollsNeeded = Math.ceil(rollsUsed);

    if (!product) throw new Error('Product not found');

    return {
      product: product.name.getValue(),
      materialCost: rollCost,
      rollsNeeded: rollsNeeded,
      rollCost: rollCost,
      rollsUsed: rollsUsed
    }
  }

  public async calculateBlowInCost(input: BlowInArea): Promise<{
    product: string,
    materialCost: number,
    bagsUsed: number,
    stacks: number
  }> {
    const emptyParams = {
      product: '',
      materialCost: 0,
      bagsUsed: 0,
      stacks: 0
    }
    if (!input.blowInProduct) return emptyParams;

    const products = await this.getProducts.execute<BlowInBlowProducts>({
      categoryName: new BlowInInsulationCategoryName()
    });
    const product = products.getByID(new ProductID(input.blowInProduct));
    if (!product) return emptyParams;

    const maxNetCoveragePerBagSqFt = product.maxNetCoveragePerBagSqFt;

    let bagsUsed: number = 0;
    if (maxNetCoveragePerBagSqFt !== 0) {
      bagsUsed = Math.ceil(product.addOverage(input.sqft / maxNetCoveragePerBagSqFt));
    }

    const bagsCost = product.getPrice() * bagsUsed;

    const bagsPerFullStack = product.bagsPerFullStack;

    const fullStackNeeded = Math.ceil(bagsUsed / bagsPerFullStack);
    return {
      product: product.name.getValue(),
      materialCost: bagsCost,
      bagsUsed: bagsUsed,
      stacks: fullStackNeeded
    }
  }
}

