@for (crew of crews; track crew.id.toString()) {
  <ion-chip color="primary" [disabled]="!crew.active">
    <ion-icon name="person-circle-outline"></ion-icon>
    <ion-label>{{ crew.name }}</ion-label>
  </ion-chip>
}

@if (crews.length === 0) {
  <ion-chip color="primary">
    <ion-label>Not Assigned</ion-label>
  </ion-chip>
}
