import {Injectable} from "@angular/core";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {ProfileID} from "@modules/profile/Domain/Profile/VA/profile-id";
import {UserConfigRepository} from "@modules/user-config/Domain/UserConfig/Repository/user-config-repository";
import {UserConfig} from "@modules/user-config/Domain/UserConfig/user-config";

@Injectable({providedIn: 'root'})
export class GetUserConfigQuery implements UseCase<ProfileID, Promise<UserConfig>> {
  constructor(
    private readonly userConfigRepository: UserConfigRepository
  ) {
  }

  execute(profileID: ProfileID): Promise<UserConfig> {
    return this.userConfigRepository.getUserConfig(profileID.toString());
  }
}
