import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UserConfigProvider} from "@modules/_shared/Service/UserConfig/user-config-provider.service";
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  FILTER_R_VALUE
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-area/batting-area.component";
import {ConstructionType} from "@modules/calculation-impl/batting/construction-type/construction-type";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";
import {
  BattingInsulationProduct
} from "@modules/product/products/batting-insulation-product/Domain/Product/batting-insulation-product";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";
import {
  BattingMineralWoolProduct
} from "@modules/product/products/batting-mineral-wool-product/Domain/Product/batting-mineral-wool-product";

@Component({
  selector: 'app-batting-area-universal-product-selector',
  templateUrl: './batting-area-universal-product-selector.component.html',
  styleUrls: ['./batting-area-universal-product-selector.component.scss'],
})
export class BattingAreaUniversalProductSelectorComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  public isTrainingTemplateMode?: boolean;

  private readonly battingInsulationCategoryName = new BattingInsulationCategoryName();
  private readonly battingMineralWoolCategoryName = new BattingMineralWoolCategoryName();
  public readonly categoryNames: CategoryName[] = [this.battingInsulationCategoryName, this.battingMineralWoolCategoryName]

  constructor(
    private readonly userConfigProvider: UserConfigProvider
  ) {
  }

  ngOnInit() {
    this.userConfigProvider.userConfig$.subscribe(userConfig => {
      if (!userConfig) return;
      this.isTrainingTemplateMode = userConfig.isTrainingTemplateMode();
    })
  }

  productSelected(product?: Product) {
    if (!product) return;

    const battingProduct = product as BattingInsulationProduct | BattingMineralWoolProduct;
    this.form.get('onCenter')?.setValue(battingProduct.onCenter.value, {emitEvent: false});
    this.form.get('rValue')?.setValue(battingProduct.rValue.value, {emitEvent: false});

    if (product.category.name.equals(this.battingInsulationCategoryName)) {
      const battingInsulationProduct = product as BattingInsulationProduct;
      this.form.get('battingServiceName')?.setValue(BattingServiceEnum.BattInsulation, {emitEvent: false});

      const constructionType = battingInsulationProduct.widthInches % 2 === 0 ? ConstructionType.MetalFrame : ConstructionType.WoodFrame;
      this.form.get('constructionType')?.setValue(constructionType, {emitEvent: false});

      this.form.get('facingName')?.setValue(battingInsulationProduct.facing.value, {emitEvent: false});
      return;
    }

    const battingMineralWoolProduct = product as BattingMineralWoolProduct;
    this.form.get('battingServiceName')?.setValue(BattingServiceEnum.MineralWool, {emitEvent: false});
    this.form.get('depth')?.setValue(battingMineralWoolProduct.depth, {emitEvent: false});
    this.form.get('filterType')?.setValue(FILTER_R_VALUE, {emitEvent: false});
  }
}
