import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {Labor} from "@modules/labor/Domain/labor";
import {LaborCrewMode} from "@modules/labor/Domain/labor-crew-mode";

export function computeCrewNamesFromGmInputs(gmInputs?: GmInputs, crews?: CrewID[]): string {
  if (!gmInputs || !crews) {
    return ""
  }

  const usedCrews: string[] = gmInputs.getCrewNames(crews);
  return usedCrews.join(', ');
}

export function getCrewsFromGmInputs(gmInputs?: GmInputs, crews?: CrewID[]): Crew[] {
  if (!gmInputs || !crews) {
    return []
  }

  const availableCrews = gmInputs.getCrews();
  const crewsFromGmInputs = crews.map(crewID => availableCrews.getById(crewID));
  return crewsFromGmInputs.filter(crew => !!crew) as Crew[];
}


export function getLaborMode(labor: Labor | undefined): LaborCrewMode {
  return labor?.mode || LaborCrewMode.crew();
}

export function shouldDisplayCrews(mode: LaborCrewMode): boolean {
  return mode.equals(LaborCrewMode.crew());
}

export function shouldDisplayFlatRate(mode: LaborCrewMode): boolean {
  return mode.equals(LaborCrewMode.flat());
}
