export interface Franchisee {
  businessUnitID: string;
  stSalesID: number;
}

export const franchisees: Franchisee[] = [
  {businessUnitID: 'c6280422-ce6f-ee11-9ae7-000d3a8b3ccf', stSalesID: 2807600}, // Admin
  {businessUnitID: '0230ab6a-8dc7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I Corporate
  {businessUnitID: 'a6ca269f-8dc7-ee11-9079-00224828ddaf', stSalesID: 343},     // I-100 - Montgomery Metro, AL
  {businessUnitID: 'c0c15e51-8ec7-ee11-9079-00224828ddaf', stSalesID: 343},     // I-103 - NW Columbus, OH
  {businessUnitID: 'b862e369-8ec7-ee11-9079-00224828ddaf', stSalesID: 2764798}, // I-104 - Boise, ID
  {businessUnitID: '3ee0d7dc-8ec7-ee11-9079-00224828ddaf', stSalesID: 347},     // I-105 - SW Columbus, OH
  {businessUnitID: '60bb731d-8fc7-ee11-9079-00224828ddaf', stSalesID: 2809726}, // I-107 - Layton, UT
  {businessUnitID: 'e4513a43-8fc7-ee11-9079-00224828ddaf', stSalesID: 2764798}, // I-109 - Denton, TX
  {businessUnitID: '297b7ee2-8fc7-ee11-9079-00224828ddaf', stSalesID: 343},     // I-115 - SW Nashville, TN
  {businessUnitID: 'c240b4fd-8fc7-ee11-9079-00224828ddaf', stSalesID: 343},     // I-118 - Eastern Iowa, IA
  {businessUnitID: 'a9d901e1-90c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-120 - Greater Cypress, TX
  {businessUnitID: '79135216-91c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-122 - Southwest Denver, CO
  {businessUnitID: '40b2f2ba-92c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-128 - Hill Country, TX
  {businessUnitID: '74bdfeeb-92c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-130 - NW Charlotte, NC
  {businessUnitID: '5b0d2745-93c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-132 - Greater North Denver, CO
  {businessUnitID: '69115c62-93c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-133 - Huntsville, AL
  {businessUnitID: 'b716c075-93c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-134 - O'Fallon, MO
  {businessUnitID: '55fb5995-93c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-135 - North Central Atlanta, GA
  {businessUnitID: 'b71dc3d8-93c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-137 - Southwest Kansas City, KS
  {businessUnitID: '170e8a17-94c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-139 - Southeast Denver, CO
  {businessUnitID: 'bd66b439-94c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-141 - Little Rock, AR
  {businessUnitID: 'facae0ac-94c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-145 - South Charlotte, NC
  {businessUnitID: '1cd61c32-95c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-148 - North Detroit, MI
  {businessUnitID: '45e0334f-95c7-ee11-9079-00224828ddaf', stSalesID: 2807600}, // I-150 - North Raleigh, NC
  {businessUnitID: '27f26b35-46f7-ee11-a1fd-7c1e520d1f77', stSalesID: 2807600}, // I-151 - Scottsdale, AZ
  {businessUnitID: '98b0cd19-47f7-ee11-a1fd-7c1e520d1f77', stSalesID: 2807600}  // I-152 - Tampa, FL
];
