import {Injectable} from "@angular/core";
import {DynamicsWebApi, UpsertRequest} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsUpsertCommand {
  constructor(private readonly dynamicsWebApi: DynamicsWebApi) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute<T = any>(request: UpsertRequest<T>): Promise<T> {
    return this.dynamicsWebApi.upsert<T>(request);
  }
}
