import {Injectable} from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root'
})
export class ObjectHarsherService {

  public async hashKey(key: any): Promise<string> {
    const stableString = this.stableJSONStringify(key);
    const encoder = new TextEncoder();
    const data = encoder.encode(stableString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  }

  private stableJSONStringify(value: any): string {
    if (value === null || typeof value !== 'object') {
      return JSON.stringify(value);
    }

    if (Array.isArray(value)) {
      const arrayValues = value.map(item => this.stableJSONStringify(item));
      return '[' + arrayValues.join(',') + ']';
    }

    const keys = Object.keys(value).sort();
    const keyValues = keys.map(key => {
      const keyString = JSON.stringify(key);
      const valString = this.stableJSONStringify(value[key]);
      return keyString + ':' + valString;
    });
    return '{' + keyValues.join(',') + '}';
  }
}
