import {Injectable} from "@angular/core";
import {
  BattingAreaTotal
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";

@Injectable({
  providedIn: 'root'
})
export class BattingAreaTotalFactory implements CalculationAreaTotalFactory<BattingAreaTotal> {
  private readonly defaults: BattingAreaTotal = {
    laborHours: 0,
    product: '',
    sqft: 0,
    materialCost: 0,
    materialSalesTax: 0,
    laborCost: 0,
    misc: 0,
    totalCost: 0,
    bags: 0,
    fields: [],
  };

  execute(data: Partial<BattingAreaTotal> = {}): BattingAreaTotal {
    const mergedData = {...this.defaults, ...data};
    return new BattingAreaTotal(
      mergedData.laborHours,
      mergedData.product,
      mergedData.sqft,
      mergedData.materialCost,
      mergedData.materialSalesTax,
      mergedData.laborCost,
      mergedData.misc,
      mergedData.totalCost,
      mergedData.bags,
    );
  }
}
