<form [formGroup]="form">

  <ion-item>
    <ion-input
      [helperText]="form.get('sqft')?.disabled? 'Please disable roof pitch mode to enter sqft':''"
      appClearZero
      errorText="Sqft is required"
      formControlName="sqft"
      inputmode="decimal"
      label="Area Square footage"
      label-placement="floating"
      placeholder="Area Square footage"
      step="any"
      type="number"
    >
    </ion-input>
  </ion-item>
</form>

@if (!this.form.get('sqft')?.disabled && isTrainingTemplateMode) {
  <div>
    @if (showWidthLength) {
      <form [formGroup]="widthLengthForm">
        <ion-item>
          <ion-input
            errorText="Length is required"
            formControlName="length"
            inputmode="decimal"
            label="Length (Ft)"
            label-placement="floating"
            placeholder="Length (Ft)"
            step="any"
            type="number"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            errorText="Width is required"
            formControlName="width"
            inputmode="decimal"
            label="Width (Ft)"
            label-placement="floating"
            placeholder="Width (Ft)"
            step="any"
            type="number"
          ></ion-input>
        </ion-item>
      </form>
    }
    <ion-item>
      <ion-toggle (ionChange)="toggleWidthLength()">
        I want to enter width and length
      </ion-toggle>
    </ion-item>
  </div>
}
