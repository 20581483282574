import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";
import {
  CategoryProductCreationManager
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {
  GetProductsRequest
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-request";
import {Products} from "@modules/product/product/Domain/Product/products";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";

interface GetProductsByCategoryNameRequestWithBusinessUnit extends GetProductsRequest {
  businessUnitID: BusinessUnitID;
}

@Injectable({
  providedIn: 'root'
})
export class GetProductsQuery implements UseCase<GetProductsRequest, Promise<Products>> {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly categoryRepository: CategoryRepository,
    private readonly productFactory: CategoryProductCreationManager,
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit
  ) {
  }

  async execute<T extends Products>(request: GetProductsRequest): Promise<T> {
    if (!request.businessUnitID) {
      const userSelectedBusinessUnit = await this.userSelectedBusinessUnit.getSelectedBusinessUnitPromise();
      request.businessUnitID = userSelectedBusinessUnit.id;
    }

    return this.getProductsByCategoryName<T>(request as GetProductsByCategoryNameRequestWithBusinessUnit);
  }

  private async getProductsByCategoryName<T extends Products>(request: GetProductsByCategoryNameRequestWithBusinessUnit): Promise<T> {
    const [category, products] = await Promise.all([
      this.categoryRepository.getByName(request.categoryName),
      this.productRepository.getAllByCategoryName(
        request.categoryName,
        request.businessUnitID
      )
    ])

    if (!category) {
      throw new Error('Category not found');
    }

    return this.productFactory.createProducts<T>(products, category.name);
  }
}
